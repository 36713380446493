import Swal, {SweetAlertIcon} from 'sweetalert2';

// Components
import NotificationBell from './NotificationBell';
import useWindowDimensions from './WindowDismensions';

// Context
import { updateUser, useAuthStore } from '../../../../context/Auth';
import { useTranslationStore } from '../../../../context/Translation';

// Enums
import { Styles } from '../../../../enums/App';
import { Languages } from '../../../../enums/Languages';
import { SwalIcons, SwalTitle } from '../../../../enums/Swal';

// Svgs
import { ReactComponent as MenuIcon } from '../../../../assets/svg/mobile_menu.svg';

//Utils
import JSUtil from '../../../../utils/JSUtil';
import LanguageUtil from '../../../../utils/LanguageUtil';

interface PropsInterface {
  goBack?: () => void,
  title?: string,
  setShowMenu: (arg0: boolean) => void,
  showMenu: boolean,
}

const Header = (props: PropsInterface) => {
  const [authState, authDispatch] = useAuthStore(),
    [t] = useTranslationStore(),
    {height, width} = useWindowDimensions();
  
  const changeLanguage = (language: string) => {
    LanguageUtil.setLanguage(language, true);
    if (authState.loggedIn) {
      updateUser(
        authDispatch,
        {
          token: authState.token,
          body: {
            _id: authState.id,
            language
          }
        },
        (err: any) => {
          if (err) {
            Swal.fire({
              icon: SwalIcons.Error as SweetAlertIcon,
              title: t(SwalTitle.Error),
              text: err,
            });
          }
        }
      );
    }
  }
  return (
    <div className="title-wrapper row-spread-wrapper-center">
      <div className='row-wrapper'>
        {
          width < Styles.MobileWidth
          ?
          props.showMenu
          ?
          <a onClick={() => props.setShowMenu(!props.showMenu)} className="menu-button-wrapper close-button-wrapper">
            &#215;
          </a>
          :
          <a onClick={() => props.setShowMenu(!props.showMenu)} className="menu-button-wrapper">
            <MenuIcon fill='#fff'/>
          </a>
          : null
        }
        {
          !JSUtil.isEmpty(props.goBack)
          ?
          <a onClick={() => props.goBack ? props.goBack() : null} className="secondary-arrow-button-wrapper">
            &#8592;
          </a>
          : null
        }
        {
          authState.loggedIn
          ?
          <NotificationBell />
          : null
        }
      </div>
      <p className='text-color-white text-weight-bold text-size-large'>{props.title ? props.title : ''}</p>
      <div className="row-wrapper">
        <p className='text-color-white text-size-small'>
          <span onClick={() => changeLanguage(Languages.NL)}>NL </span> 
          <span onClick={() => changeLanguage(Languages.FR)}>| FR |</span> 
          <span onClick={() => changeLanguage(Languages.EN)}> EN</span>
        </p>
      </div>
    </div>
  );
}

export default Header;