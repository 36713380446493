
// Context
import { useTranslationStore } from '../../../context/Translation';

// Enums
import { LocationTypes } from "../../../enums/Jobs";
import { LanguageResource } from "../../../enums/Languages";

// Interfaces
import { JobInterface } from "../../../interfaces/Job";

// Svgs
import { ReactComponent as Pin } from '../../../assets/svg/pin.svg';

// Utils
import LanguageUtil from '../../../utils/LanguageUtil';

interface PropsInterface {
  job: JobInterface;
  showFullAddress: boolean;
};

const JobInfoCity = (props: PropsInterface) => {
  const [t] = useTranslationStore();

  const getCity = () => {
    return (
      props.job.location.city && props.job.location.city.name
      // @ts-ignore
      ? props.job.location.city.name[LanguageUtil.getLanguage()]
      : ''
    );
  }

  return (
    <div className='row-wrapper'>
      <div className='svg-wrapper'>
        <Pin fill='#FF462F' stroke='#FF462F' />
      </div>
      <p>{
        props.job.location.type === LocationTypes.Belgium
        ? t(LanguageResource.Common + ':location_belgium')
        : props.showFullAddress
        ? props.job.location.name +
          ' ' +
          (props.job.location.postalCode ? props.job.location.postalCode : '') +
          ' ' + getCity()
        : (props.job.location.postalCode ? props.job.location.postalCode : '') +
          ' ' + getCity()
      }</p>
    </div>
  );
};

export default JobInfoCity;