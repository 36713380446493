import moment from 'moment';

export default class DateUtil {
  static now() {
    return new Date();
  }

  static nowWeeksFurther(numberOfWeeks: number) {
    let now = new Date();
    now.setDate(now.getDate() + numberOfWeeks * 7);
    return now;
  }

  static nowMonthsFurther(numberOfMonths: number) {
    let now = new Date();
    now.setMonth(now.getMonth() + numberOfMonths);
    return now;
  }

  static nowYearsFurther(numberOfYears: number) {
    let now = new Date();
    now.setFullYear(now.getFullYear() + numberOfYears);
    return now;
  }

  static nowYearsBack(numberOfYears: number) {
    let now = new Date();
    now.setFullYear(now.getFullYear() - numberOfYears);
    return now;
  }

  static formatInputDate(value: Date) {
    return moment(value).format('YYYY-MM-DD');
  }

  static displayDate(value: Date) {
    return moment(value).format('DD/MM/YYYY');
  }

  static displayShortDate(value: Date) {
    return moment(value).format('D MMM, ddd');
  }

  static dateFromNow(value: Date) {
    return moment(value).fromNow();
  }

  static isTodayBeforeDate(value: Date) {
    const today = DateUtil.now();
    return moment(today).isBefore(value);
  }

  static isTodayAfterDate(value: Date) {
    const today = DateUtil.now();
    return moment(today).isAfter(value);
  }
}