import {logoutUser} from '../context/Auth';

//Utils
import JSUtil from './JSUtil';

export default class ApiUtil {
  static handleReponse(
    err:
      | string
      | {
          message: string;
        },
    res: {success: boolean; error: any},
    callback: (arg0: any, arg1: any) => void,
    dispatch: ((arg0: {type: string}) => void) | undefined,
  ) {
    if (!JSUtil.isEmpty(res) && res.success === false) {
      err = res.error || 'api_went_wrong';
    }
    if (typeof err === 'object' && !JSUtil.isEmpty(err)) {
      if (err?.message) {
        err = err.message;
      } else {
        err = 'api_went_wrong';
      }
    }
    if (
      dispatch &&
      [
        'expired_token',
        'invalid_token',
        'Token is invalid',
        'Token is vervallen',
        'Invalid token',
        'Ongeldig token',
        "Code d'accès invalide",
        "Le code d'accès est expiré",
      ].includes(err as string)
    ) {
      logoutUser(dispatch);
    }
    if (callback) {
      callback(err, res);
    }
  }
}
