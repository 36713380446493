// Context
import { useTranslationStore } from '../../../../../context/Translation';

// Enums
import { LanguageResource } from '../../../../../enums/Languages';

// Interfaces
import { EducationInterface, RelevantExperienceInterface, WorkExperienceInterface } from '../../../../../interfaces/Job';

// Utils
import CVOverview from '../../../../Profile/CV/Overview';

interface PropsInterface {
  setNextStepAddType: (arg0: string, arg1: number | null) => void;
  educations: EducationInterface[];
  setEducations: (arg0: EducationInterface[]) => void;
  workExperiences: WorkExperienceInterface[];
  setWorkExperiences: (arg0: WorkExperienceInterface[]) => void;
  relevantExperiences: RelevantExperienceInterface[];
  setRelevantExperiences: (arg0: RelevantExperienceInterface[]) => void;
}

const Step2and2 = (props: PropsInterface) => {
  const [t] = useTranslationStore();

  return (
    <div className="claim-create-form-wrapper">
      <p className="text-size-default text-weight-bold">{t(LanguageResource.Jobs + ':claim_title_step_2.2')}</p>
      <CVOverview
        onPress={props.setNextStepAddType}
        educations={props.educations}
        setEducations={props.setEducations}
        workExperiences={props.workExperiences}
        setWorkExperiences={props.setWorkExperiences}
        relevantExperiences={props.relevantExperiences}
        setRelevantExperiences={props.setRelevantExperiences}
      />
    </div>
  );
}

export default Step2and2;