import { NavigateFunction } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

// Components
import DealInfoCategories from '../../Common/Info/Categories';
import DealInfoCity from '../Info/City';
import DealInfoType from '../Info/Type';

// Config
import ENV from '../../../config/env.json';

// Enums
import { OriginTracking, RoutesLinks } from '../../../enums/Routes';

// Interfaces
import { DealInterface } from '../../../interfaces/Deal';

// Styles
import './Preview.scss';

// Utils
import JSUtil from '../../../utils/JSUtil';

interface PropsInterface {
  deal: DealInterface,
  navigate: NavigateFunction,
}

const DealsPreview = (props: PropsInterface) => {
  const currentPath = useLocation().pathname;

  return (
    <div 
      className='deal-preview-wrapper column-wrapper' 
      onClick={() => props.navigate(
        [RoutesLinks.ProfileDeals.Link].includes(currentPath) 
        ? RoutesLinks.ProfileDealsDetail.Link.replace(':' + RoutesLinks.DealsDetail.Params.DealKey, props.deal.key)
        : RoutesLinks.DealsDetail.Link.replace(':' + RoutesLinks.DealsDetail.Params.DealKey, props.deal.key),
        {
          state: {
            origin: OriginTracking.List
          }
        }
      )}>
      <img 
        src={props.deal.image?.includes('http') ? props.deal.image : ENV.BASE_IMAGE_URL + props.deal.image}
        alt='header event' 
      />
      <div className="column-wrapper deal-preview-info-wrapper">
        <div className='row-spread-wrapper'>
          <div className='column-wrapper first-column-info-wrapper'>
            <p className='text-weight-bold text-size-large'>{props.deal.title as string}</p>          
            <p className='text-color-gray-dark'>{
              !JSUtil.isEmpty(props.deal.company.for)
              ? props.deal.company.for
              : props.deal.company.name}
            </p>
          </div>
          <div className='column-wrapper'>
            <DealInfoCity deal={props.deal} showFullAddress={false}/>
            <DealInfoCategories post={props.deal} />
          </div>
        </div>
        <DealInfoType deal={props.deal} />
      </div>
    </div>
  );
}

export default DealsPreview;