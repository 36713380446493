import { useState, useEffect } from 'react';
import { NavigateFunction, useParams, useLocation } from 'react-router-dom';
import Swal, {SweetAlertIcon} from 'sweetalert2';
import { browserName, browserVersion, osName } from "react-device-detect";

// Apis
import { consultationApi } from '../../../api/consultations';
import { getEventsApi } from '../../../api/events';

// Components
import DetailTabs from '../../Common/Detail/Tabs/';
import UserLayout from '../../Common/Layout/User';
import EventInfoLogo from '../../Common/Info/Logo';
import EventInfoCity from '../../Common/Info/City';
import EventInfoSave from '../Info/Save';
import ShareButton from '../../Common/Buttons/Share';

// Config
import ENV from '../../../config/env.json';

// Context
import { useAuthStore } from '../../../context/Auth';
import { useTranslationStore } from '../../../context/Translation';

// Enums
import { CategoryTypes } from '../../../enums/Categories';
import { LanguageResource } from '../../../enums/Languages';
import { RoutesLinks } from '../../../enums/Routes';
import { SwalIcons, SwalTitle } from '../../../enums/Swal';
import { StorageParams } from '../../../enums/Storages';

// Interfaces
import { EventInterface } from '../../../interfaces/Event';

// Styles
import './index.scss';

// Utils
import JSUtil from '../../../utils/JSUtil';

interface PropsInterface {
  navigate: NavigateFunction,
}

const EventsDetailPage = (props: PropsInterface) => {
  const [authState, authDispatch] = useAuthStore(),
    navigationParams = useParams(),
    eventKey = navigationParams[RoutesLinks.EventsDetail.Params.EventKey],
    [event, setEvent] = useState<EventInterface>(),
    [isLoading, setIsLoading] = useState<boolean>(true),
    [t] = useTranslationStore(),
    location = useLocation();

  useEffect(() => {
    loadEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.loggedIn]);

  const loadEvent = () => {
    getEventsApi(
      {
        token: authState.token,
        key: eventKey
      },
      (err: any, res: {amount: number; result: EventInterface[]}) => {
        setIsLoading(false);
        if (err) {
          Swal.fire({
            icon: SwalIcons.Error as SweetAlertIcon,
            title: t(SwalTitle.Error),
            text: err,
          }).then(() => {
            props.navigate(RoutesLinks.Events.Link);
          });
        } else if (!JSUtil.isEmpty(res.result[0])) {
          setEvent(res.result[0]);
          addConsultation(res.result[0]);
        } else {
          Swal.fire({
            icon: SwalIcons.Error as SweetAlertIcon,
            title: t(SwalTitle.Error),
            text: t(LanguageResource.Common + ':information_not_available'),
          }).then(() => {
            props.navigate(RoutesLinks.Events.Link);
          });
        }
      },
      authDispatch
    );
  };

  const addConsultation = (event: EventInterface) => {
    consultationApi(
      {
        token: authState.token,
        body: {
          type: CategoryTypes.Events,
          id: event._id,
          companyId: event.company.by,
          userId: authState.id || localStorage.getItem(StorageParams.UserId),
          track: {
            //@ts-ignore
            origin: location?.state?.origin || '',
            //@ts-ignore
            info: location?.state?.info || '',
            platform: 'web',
            model: browserName,
            brand: osName,
            systemVersion: browserVersion,
            appVersion: ENV.WEB_VERSION
          },
        },
      },
      (err: any) => {
        if (err) {
          Swal.fire({
            icon: SwalIcons.Error as SweetAlertIcon,
            title: t(SwalTitle.Error),
            text: err,
          });
        }
      },
    );
  }
  return (
    <UserLayout 
      navigate={props.navigate} 
      isLoading={isLoading}
      goBack={
        () => {
          props.navigate(-1);
        }
      }
    >
      {
        !JSUtil.isEmpty(event) && event && !isLoading
        ?
        <>
          <div className='detail-header-wrapper row-wrapper'>
            <div className='image-header-wrapper'>
              <img 
                src={event.image?.includes('http') ? event.image : ENV.BASE_IMAGE_URL + event.image}
                alt='header event' 
              />
            </div>
            <div className='event-info-wrapper'>
              <div className='row-wrapper'>
                {
                  !JSUtil.isEmpty(event.company?.image)
                  ?
                  <EventInfoLogo company={event.company} />
                  : null
                }
                <div className='column-wrapper'>
                <div className='row-wrapper'>
                  <div className='column-wrapper'>
                    <div className='row-wrapper event-title-wrapper'>
                      <p className='text-weight-bold text-size-large text-title'>{event.title as string}</p>
                      <p className='text-color-gray-dark text-at'> {t(LanguageResource.Common + ':posted_by')} </p>
                      <p className='text-weight-bold text-size-large'>
                        {
                          !JSUtil.isEmpty(event.company.for)
                          ? event.company.for
                          : !JSUtil.isEmpty(event.company.name)
                          ? event.company.name
                          : event.user.name
                        }
                      </p>
                    </div>
                  </div>
                </div>
                  <div className='row-wrapper event-sub-title-wrapper'>
                    <EventInfoCity post={event} showFullAddress={true}/>
                  </div>
                </div>
              </div>
              <div className='detail-button-wrapper'>
                <EventInfoSave event={event} navigate={props.navigate}/>
                <ShareButton shareUrl={event.shareUrl} />
              </div>
            </div>
          </div>
          <div className='detail-content-wrapper'>
            <DetailTabs
              data={event as EventInterface}
              useRequirements={false}
              type={CategoryTypes.Events}
            />
          </div>
        </>
        : null
      }
    </UserLayout>
  );
}

export default EventsDetailPage