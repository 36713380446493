// Styles
import './Title.scss';

interface PropsInterface {
  title: string;
  show?: boolean;
  onPressShow?: () => void;
}

const SearchFilterTitle = (props: PropsInterface) => {
  return <p className="text-weight-bold text-size-large filter-title-text" onClick={() =>  props.onPressShow ? props.onPressShow() : null}>
    {props.title}
    {
      props.onPressShow
      ?
      <span>
        {
          props.show 
          ? String.fromCharCode(9650)
          : String.fromCharCode(9660)
        }
      </span>
      : null
    }
  </p>;
};

export default SearchFilterTitle;
