import { NavigateFunction } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

// Components
import JobInfoCategories from '../Info/Categories';
import JobInfoCity from '../Info/City';
import JobInfoLogo from '../Info/Logo';
import JobInfoSave from '../../Common/Info/Save';
import JobInfoType from '../Info/Type';

// Enums
import { CategoryTypes } from '../../../enums/Categories';
import { OriginTracking, RoutesLinks } from '../../../enums/Routes';

// Interfaces
import { JobInterface } from '../../../interfaces/Job';

// Styles
import './Preview.scss';

// Utils
import JSUtil from '../../../utils/JSUtil';

interface PropsInterface {
  job: JobInterface,
  navigate: NavigateFunction,
}

const JobsPreview = (props: PropsInterface) => {
  const currentPath = useLocation().pathname;
  return (
    <div 
      className='job-preview-wrapper column-wrapper' 
      onClick={() => props.navigate(
        [RoutesLinks.ProfileJobs.Link].includes(currentPath) 
        ? RoutesLinks.ProfileJobsDetail.Link.replace(':' + RoutesLinks.JobsDetail.Params.JobKey, props.job.key)
        : RoutesLinks.JobsDetail.Link.replace(':' + RoutesLinks.JobsDetail.Params.JobKey, props.job.key),
        {
          state: {
            origin: OriginTracking.List
          }
        }
      )}>
    <div className="column-wrapper job-preview-info-wrapper">
      <div className='row-spread-wrapper'>
        <div className='column-wrapper'>
          <p className='text-weight-bold text-size-large'>{props.job.title as string}</p>
          <p className='text-color-gray-dark'>{
            !JSUtil.isEmpty(props.job.company.for)
            ? props.job.company.for
            : props.job.company.name}
          </p>
        </div>
        <JobInfoLogo company={props.job.company} />
      </div>
      <div className='row-wrapper'>
        <div className='column-wrapper'>
          <JobInfoCity job={props.job} showFullAddress={false}/>
          <JobInfoType job={props.job} />
          <JobInfoCategories job={props.job} />
        </div>
        <div className='column-wrapper save-column-wrapper'>
          <JobInfoSave post={props.job} type={CategoryTypes.Jobs} navigate={props.navigate}/>
        </div>
      </div>
    </div>
    </div>
  );
}

export default JobsPreview;