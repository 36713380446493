// Context
import { useTranslationStore } from '../../../../context/Translation';

// Enums
import { LanguageResource } from '../../../../enums/Languages';

// Svgs
import { ReactComponent as Mail } from '../../../../assets/svg/mail.svg';

interface PropsInterface {
  closePopup: () => void;
}

const SuccessUrl = (props: PropsInterface) => {
  const onPressGoBack = () => {
    props.closePopup();
  },
  [t] = useTranslationStore();

  return (
    <div className="overflow-popup-wrapper">
      <div className="popup-wrapper">
        <Mail />
        <p className='text-weight-bold'>{t(LanguageResource.Jobs + ':claim_url_success_title')}</p>
        <p>{t(LanguageResource.Jobs + ':claim_url_success_subTitle')}</p>
        <div className='primary-button-wrapper' onClick={() => onPressGoBack()}>
          <p className='primary-button-text'>{t(LanguageResource.Jobs + ':claim_url_got_it')}</p>
        </div>
      </div>
    </div>
  );
};

export default SuccessUrl;
