import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

// Enums
import { LocationTypes } from '../../../../enums/Jobs';
import { LanguageResource } from '../../../../enums/Languages';

// Context
import { useTranslationStore } from '../../../../context/Translation';

// Interfaces
import { DealInterface } from '../../../../interfaces/Deal';
import { EventInterface } from '../../../../interfaces/Event';
import { JobInterface } from '../../../../interfaces/Job';

// Utils
import LanguageUtil from '../../../../utils/LanguageUtil';

interface PropsInterface {
  data: JobInterface | DealInterface | EventInterface;
}

const DetailTabsDescription = (props: PropsInterface) => {

  const containerStyle = {
    height: '500px'
  },
  [t] = useTranslationStore();

  return (
    <div className="description-wrapper">
      {
        props.data.location?.latitude && props.data.location?.longitude 
        ? 
        <div className="location-wrapper">
          <p>
            {props.data.location.name} {props.data.location.postalCode}{' '}
            {props.data.location.city && props.data.location.city.name
              ? // @ts-ignore
                props.data.location.city.name[LanguageUtil.getLanguage()]
              : null}
          </p>
          <LoadScript
            googleMapsApiKey="AIzaSyArzKLR74sd61Mn05RE6r6YgAj7Uupk6zU"
          >
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={{ lat: parseFloat(props.data.location.latitude as string), lng: parseFloat(props.data.location.longitude as string) }}
              zoom={15}
            >
              <Marker
                position={{ lat: parseFloat(props.data.location.latitude as string), lng: parseFloat(props.data.location.longitude as string) }} 
              />
            </GoogleMap>
          </LoadScript>
        </div>
        : props.data.location.type === LocationTypes.Belgium 
        ? <p>{t(LanguageResource.Common + ':location_belgium')}</p>
        : <a href={props.data.location.name as string} target="_blank">{props.data.location.name as string}</a>
      }
    </div>
  );
};

export default DetailTabsDescription;