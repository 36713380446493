import ApiUtil from '../utils/ApiUtil';
import {getRequest} from './api';

export const getSchoolsApi = callback => {
  const endpoint = '/v1/schools/get',
    headers = {};

  getRequest({endpoint}, headers, (err, res) => {
    ApiUtil.handleReponse(err, res, callback);
  });
};

export const getStudiesTypesApi = callback => {
  const endpoint = '/v1/studiesTypes/get',
    headers = {};

  getRequest({endpoint}, headers, (err, res) => {
    ApiUtil.handleReponse(err, res, callback);
  });
};
