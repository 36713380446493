// Components
import Input from '../Form/Inputs/Input';

// Context
import { useTranslationStore } from '../../../context/Translation';

// Enums
import { InputTypes } from '../../../enums/Inputs';
import { LanguageResource } from '../../../enums/Languages';

// Styles
import './Bar.scss';

// Utils
import JSUtil from '../../../utils/JSUtil';

interface PropsInterface {
  search: string;
  setSearch: (arg0: string) => void;
}

const SearchBar = (props: PropsInterface) => {
  const [t] = useTranslationStore();

  const onPressCancel = () => {
    props.setSearch('');
  };

  return (
    <div className='search-bar-wrapper'>
      <div className='search-filter-header-wrapper row-spread-wrapper'>
        <Input
          input={{
            name: 'search',
            type: InputTypes.Text,
            value: props.search,
            placeholder: t(LanguageResource.Common + ':search') as string,
            onChange: (val: string) => props.setSearch(val),
          }}
        />
        {
          !JSUtil.isEmpty(props.search)
          ? <p className='delete-search-text' onClick={() => onPressCancel()}>X</p>
          : null
        }
      </div>
    </div>
  );
};

export default SearchBar;
