import {useEffect, useState} from 'react';
import { NavigateFunction, useParams } from 'react-router-dom';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import moment from 'moment';
import { browserName, browserVersion, osName } from "react-device-detect";

// Apis
import { claimApi  } from '../../../../../api/claims';
import { getCitiesApi } from '../../../../../api/cities';
import { getJobsApi } from '../../../../../api/jobs';

// Components
import Form from '../../../../Common/Form/';
import Step2 from './Step2';
import Step2and2 from './Step2.2';
import Step4 from './Step4';
import { validateInputs } from '../../../../Common/Form/Inputs/';
import StepsHeader from '../../../../Common/Form/Steps/Header';
import StepsSubHeader from '../../../../Common/Form/Steps/SubHeader';
import UserLayout from '../../../../Common/Layout/User';
import { formatEducationData, getEducationInputs } from '../../../../Profile/CV/Education';
import { formatWorkExperienceData, getWorkExperienceInputs } from '../../../../Profile/CV/WorkExperience';
import { formatRelevantExperienceData, getRelevantExperienceInputs } from '../../../../Profile/CV/RelevantExperience';

// Config
import ENV from '../../../../../config/env.json';

// Context
import { useAuthStore, updateUser } from '../../../../../context/Auth';
import { useTranslationStore } from '../../../../../context/Translation';

// Enums
import { CategoryTypes } from '../../../../../enums/Categories';
import { DropdownOptions, InputTypes } from '../../../../../enums/Inputs';
import { ExperienceTypes } from '../../../../../enums/Jobs';
import { LanguageResource } from '../../../../../enums/Languages';
import { RoutesLinks } from '../../../../../enums/Routes';
import { SwalIcons, SwalTitle } from '../../../../../enums/Swal';
import { YesNo } from '../../../../../enums/YesNo';

// Interfaces
import { InputInterface, StepsInterface } from '../../../../../interfaces/Input';
import { EducationInterface, JobInterface, RelevantExperienceInterface, WorkExperienceInterface } from '../../../../../interfaces/Job';
import { LocationInterface } from '../../../../../interfaces/Location';

// Utils
import DateUtil from '../../../../../utils/DateUtil';
import JSUtil from '../../../../../utils/JSUtil';

// Styles
import './index.scss';

interface PropsInterface {
  navigate: NavigateFunction,
  title: string
}

const JobsDetailClaimsCreatePage = (props: PropsInterface) => {
  const navigationParams = useParams(),
    [authState, authDispatch] = useAuthStore(),
    [t] = useTranslationStore(),
    jobKey = navigationParams[RoutesLinks.Resume.Params.JobKey],
    [job, setJob] = useState<JobInterface>(),
    [isLoading, setIsLoading] = useState(false),
    [step, setStep] = useState(0),
    [loadingCities, setLoadingCities] = useState(true),
    [cities, setCities] = useState<LocationInterface[]>([]),
    [searchedCities, setSearchedCities] = useState<LocationInterface[]>([]),
    [firstname, setFirstname] = useState(!JSUtil.isEmpty(authState.profile?.firstname) ? authState.profile.firstname : ''),
    [lastname, setLastname] = useState(!JSUtil.isEmpty(authState.profile?.lastname) ? authState.profile.lastname : ''),
    [birthday, setBirthday] = useState(!JSUtil.isEmpty(authState.profile?.birthday) ? DateUtil.formatInputDate(authState.profile.birthday) : ''),
    [phone, setPhone] = useState(!JSUtil.isEmpty(authState.profile?.phone) ? authState.profile.phone : ''),
    [street, setStreet] = useState(!JSUtil.isEmpty(authState.profile?.street) ? authState.profile.street : ''),
    [housenumber, setHousenumber] = useState(!JSUtil.isEmpty(authState.profile?.housenumber) ? authState.profile.housenumber : ''),
    [box, setBox] = useState(!JSUtil.isEmpty(authState.profile?.box) ? authState.profile.box : ''),
    [postalCode, setPostalCode] = useState(!JSUtil.isEmpty(authState.profile?.postalCode) ? authState.profile.postalCode : ''),
    [cityId, setCityId] = useState(!JSUtil.isEmpty(authState.profile?.city) ? authState.profile.city : ''),
    [resume, setResume] = useState<File | null>(null),
    [addType, setAddType] = useState(ExperienceTypes.Education),
    [typeIndex, setTypeIndex] = useState<number | null>(null),
    [educations, setEducations] = useState<EducationInterface[]>(authState.cv?.educations || []),
    [levelEducation, setLevelEducation] = useState(''),
    [fieldOfStudy, setFieldOfStudy] = useState(''),
    [collegeOrUniversity, setCollegeOrUniversity] = useState(''),
    [schoolLocation, setSchoolLocation] = useState(''),
    [timePeriod, setTimePeriod] = useState(false),
    [title, setTitle] = useState(''),
    [company, setCompany] = useState(''),
    [city, setCity] = useState(''),
    [location, setLocation] = useState(''),
    [description, setDescription] = useState(''),
    [fromDate, setFromDate] = useState<string>(DateUtil.formatInputDate(DateUtil.nowYearsBack(3))),
    [toDate, setToDate] = useState<string>(DateUtil.formatInputDate(DateUtil.now())),
    [workExperiences, setWorkExperiences] = useState<WorkExperienceInterface[]>(authState.cv?.workExperiences || []),
    [relevantExperiences, setRelevantExperiences] = useState<RelevantExperienceInterface[]>(authState.cv?.relevantExperiences || []),
    [motivation, setMotivation] = useState(''),
    steps: StepsInterface[] = [
      {
        title: LanguageResource.Jobs + ':claim_title_step_0',
        inputs: [
          [
            {
              name: "firstname",
              type: InputTypes.Text,
              placeholder: true,
              onChange: (val: string) => setFirstname(val),
              value: firstname,
              label: t(LanguageResource.Input + ':firstname'),
              required: true
            },
            {
              name: "lastname",
              type: InputTypes.Text,
              placeholder: true,
              onChange: (val: string) => setLastname(val),
              value: lastname,
              label: t(LanguageResource.Input + ':lastname'),
              required: true
            }
          ],
          [
            {
              name: "birthday",
              type: InputTypes.Date,
              placeholder: true,
              onChange: (val: string) => setBirthday(val),
              value: birthday,
              label: t(LanguageResource.Input + ':birthday'),
              required: true
            },
            {
              name: "phone",
              type: InputTypes.Phone,
              placeholder: true,
              onChange: (val: string) => setPhone(val),
              value: phone,
              label: t(LanguageResource.Input + ':phone'),
              required: false
            }
          ]
        ]
      }, {
        title: LanguageResource.Jobs + ':claim_title_step_1',
        inputs: [
          [
            {
              name: "street",
              type: InputTypes.Text,
              placeholder: true,
              onChange: (val: string) => setStreet(val),
              value: street,
              label: t(LanguageResource.Input + ':street'),
              required: true
            },
            {
              name: "housenumber",
              type: InputTypes.Text,
              placeholder: true,
              onChange: (val: string) => setHousenumber(val),
              value: housenumber,
              label: t(LanguageResource.Input + ':housenumber'),
              required: true
            },
            {
              name: "box",
              type: InputTypes.Text,
              placeholder: true,
              onChange: (val: string) => setBox(val),
              value: box,
              label: t(LanguageResource.Input + ':box'),
              required: false
            }
          ],
          [
            {
              name: "postalCode",
              type: InputTypes.Text,
              placeholder: true,
              onChange: (val: string) => searchCities(val, cities),
              value: postalCode,
              label: t(LanguageResource.Input + ':postalCode'),
              required: false
            },
            {
              name: "cityId",
              type: InputTypes.Dropdown,
              options: {
                type: DropdownOptions.Options,
                dataGetter: searchedCities
              },
              placeholder: true,
              onChange: (val: string) => setCityId(val),
              value: cityId,
              label: t(LanguageResource.Input + ':city'),
              required: true,
              condition: !JSUtil.isEmpty(postalCode)
            }
          ]
        ]
      },
      {
        title: LanguageResource.Jobs + ':claim_title_step_2',
        inputs: [
          {
            name: "resume",
            type: InputTypes.File,
            onChange: (val: File | null) => setResume(val),
            value: resume,
            required: true,
          }
        ]
      },
      {
        title: LanguageResource.Jobs + ':claim_title_step_2.2',
        inputs: getEducationInputs(
          t,
          levelEducation,
          setLevelEducation,
          fieldOfStudy,
          setFieldOfStudy,
          collegeOrUniversity,
          setCollegeOrUniversity,
          schoolLocation,
          setSchoolLocation,
          timePeriod,
          setTimePeriod,
          fromDate,
          setFromDate,
          toDate,
          setToDate
        )
      },
      {
        title: LanguageResource.Jobs + ':claim_title_step_2.2',
        inputs: getWorkExperienceInputs(
          t,
          title,
          setTitle,
          company,
          setCompany,
          city,
          setCity,
          timePeriod,
          setTimePeriod,
          fromDate,
          setFromDate,
          toDate,
          setToDate,
          description,
          setDescription
        )
      },
      {
        title: LanguageResource.Jobs + ':claim_title_step_2.2',
        inputs: getRelevantExperienceInputs(
          t,
          title,
          setTitle,
          location,
          setLocation,
          description,
          setDescription
        )
      },
      {
        title: LanguageResource.Jobs + ':claim_title_step_3',
        inputs: [
          {
            name: "motivation",
            type: InputTypes.TextArea,
            onChange: (val: string) => setMotivation(val),
            value: motivation,
            label: t(LanguageResource.Jobs + ':claim_motivation'),
            required: true,
          },
        ]
      },
      {
        title: LanguageResource.Jobs + ':claim_title_step_4',
        inputs: []
      }
    ];
  let isSaving = false;

  useEffect(() => {
    loadJob();
    loadingCitiesInfo();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadJob = () => {
    getJobsApi(
      {
        token: authState.token,
        key: jobKey
      },
      (err: any, res: {amount: number; result: JobInterface[]}) => {
        setIsLoading(false);
        if (err) {
          Swal.fire({
            icon: SwalIcons.Error as SweetAlertIcon,
            title: t(SwalTitle.Error),
            text: err,
          });
        } else {
          setJob(res.result[0]);
        }
      },
      authDispatch
    );
  };

  const loadingCitiesInfo = () => {
    getCitiesApi(
      {
        token: authState.token,
        filter: YesNo.No,
      },
      (err: any, res: LocationInterface[]) => {
        if (err) {
          Swal.fire({
            icon: SwalIcons.Error as SweetAlertIcon,
            title: t(SwalTitle.Error),
            text: err,
          });
        } else {
          setCities(res);
          searchCities(postalCode, res);
          setLoadingCities(false);
        }
      }
    );
  }

  const searchCities = (value: string, options: LocationInterface[]) => {
    setPostalCode(value);
    const filteredCities = options.filter(f => f.postalCode === value);
    setSearchedCities(filteredCities);
    setCityId(filteredCities[0] ? filteredCities[0]._id : '');
  };

  const setNextStepAddType = (type: string, index: number | null) => {
    setAddType(type);
    setTypeIndex(index);
    switch(type) {
      case ExperienceTypes.Education:
        setEducationData(educations[index as number]);
      break;
      case ExperienceTypes.RelevantExperience:
        setRelevantExperienceData(relevantExperiences[index as number]);
      break;
      case ExperienceTypes.WorkExperience:
        setWorkExperienceData(workExperiences[index as number]);
      break;
    }
    setStep(2.3);
  };

  const addEducation = () => {
    const newEducation = {
      levelEducation,
      fieldOfStudy,
      collegeOrUniversity,
      schoolLocation,
      timePeriod,
      fromDate,
      toDate,
    };
    if (typeIndex !== null) {
      educations[typeIndex] = newEducation;
    } else {
      educations.push(newEducation);
    }
    setEducations(educations);
    setEducationData();
    setAddType('');
  }

  const addRelevantExperience = () => {
    const newRelevantExperience = {
      title,
      location,
      description
    };
    if (typeIndex !== null) {
      relevantExperiences[typeIndex] = newRelevantExperience;
    } else {
      relevantExperiences.push(newRelevantExperience);
    }
    setRelevantExperiences(relevantExperiences);
    setRelevantExperienceData();
    setAddType('');
  }

  const addWorkExperience = () => {
    const newWorkExperience = {
      title,
      company,
      city,
      timePeriod,
      fromDate,
      toDate,
      description
    };
    if (typeIndex !== null) {
      workExperiences[typeIndex] = newWorkExperience;
    } else {
      workExperiences.push(newWorkExperience);
    }
    setWorkExperiences(workExperiences);
    setWorkExperienceData();
    setAddType('');
  }

  const setEducationData = (education: EducationInterface | null = null) => {
    formatEducationData(
      education,
      setLevelEducation,
      setFieldOfStudy,
      setCollegeOrUniversity,
      setSchoolLocation,
      setTimePeriod,
      setFromDate,
      setToDate
    );
  } 

  const setRelevantExperienceData = (relevantExperience: RelevantExperienceInterface | null = null) => {
    formatRelevantExperienceData(
      relevantExperience,
      setTitle,
      setLocation,
      setDescription
    );
  }

  const setWorkExperienceData = (workExperience: WorkExperienceInterface | null = null) => {
    formatWorkExperienceData(
      workExperience,
      setTitle,
      setCompany,
      setCity,
      setTimePeriod,
      setFromDate,
      setToDate,
      setDescription
    );
  }

  const getRenderStep = (onlyForm = true) => {
    let inputIndex:number = step;
    if (step === 2.1) inputIndex = 2;
    if (step === 2.2 && !onlyForm) inputIndex = 2;
    if (step === 2.3) {
      if (addType === ExperienceTypes.Education) {
        inputIndex = 3;
      } else if (addType === ExperienceTypes.WorkExperience) {
        inputIndex = 4;
      } else if (addType === ExperienceTypes.RelevantExperience) {
        inputIndex = 5;
      }
    }
    if (step === 3 || step === 4 || step === 5) inputIndex = step + 3;
    return inputIndex;
  }

  const renderStep = () => {
    switch (step) {
      case 0:
      case 1:
      case 2.1:
      case 2.3:
      case 3:
        let inputIndex = getRenderStep();
        return <Form 
          inputs={steps[inputIndex].inputs}
          onSubmit={() => handleSubmit(steps[inputIndex].inputs)}
        />
      case 2:
        return <Step2 
          setStep={setStep}
        />
      case 2.2:
        return (
          <Step2and2
            educations={educations}
            setEducations={setEducations}
            workExperiences={workExperiences}
            setWorkExperiences={setWorkExperiences}
            relevantExperiences={relevantExperiences}
            setRelevantExperiences={setRelevantExperiences}
            setNextStepAddType={setNextStepAddType}
          />
        );
      case 4:
        return <Step4
          firstname={firstname}
          lastname={lastname}
          birthday={birthday}
          phone={phone}
          street={street}
          housenumber={housenumber}
          box={box}
          postalCode={postalCode}
          cityId={cityId}
          resume={resume as File}
          educations={educations}
          workExperiences={workExperiences}
          relevantExperiences={relevantExperiences}
          motivation={motivation}
          setStep={setStep}
        />
      
    }
  };

  const navigateToJobDetail = () => {
    props.navigate(
      RoutesLinks.JobsDetail.Link.replace(':' + RoutesLinks.JobsDetail.Params.JobKey, jobKey as string), 
      {state: job}
    );
  }

  const goStepBack = () => {
    switch (step) {
      case 0:
        navigateToJobDetail();
      break;
      case 2.1:
      case 2.2:
        setStep(2)
      break;
      case 2.3:
        setStep(2.2);
      break;
      case 3:
        if (!resume) {
          setStep(2.2);
        } else {
          setStep(2.1);
        }
      break;
      default:
        setStep(step - 1);
      break;
    }
  }

  const handleSubmit = async (inputs: (InputInterface|InputInterface[])[]) => {
    if (validateInputs(inputs, t, [2, 2.2].includes(step))) {
      switch (step) {
        case 2.1:
        case 2.2:
          if (
            step === 2.2 && 
            JSUtil.isEmpty(educations) &&
            JSUtil.isEmpty(workExperiences) &&
            JSUtil.isEmpty(relevantExperiences)
          ) {
            Swal.fire({
              icon: SwalIcons.Error as SweetAlertIcon,
              title: t(SwalTitle.Error),
              text: t(LanguageResource.Validation + ':empty_experiences'),
            });
          } else {
            setStep(3);
          }
        break;
        case 2.3:
          switch(addType) {
            case ExperienceTypes.Education:
              addEducation();
              break;
            case ExperienceTypes.RelevantExperience:
              addRelevantExperience();
            break;
            case ExperienceTypes.WorkExperience:
              addWorkExperience();
            break;
          }
          setStep(2.2);
        break;
        case 4:
          await saveUser();
        break;
        case 5:
        break;
        default:
          setStep(step + 1);
        break;
      }
    }
  }

  const saveUser = async () => {
    if (!isSaving) {
      isSaving = true;

      let resumeObject: {name?: string; base64?: string;} = {};
      if (resume) {
        const base64String = await JSUtil.getBase64(resume) as string;
        resumeObject = {
          name: resume.name,
          base64: base64String
        };
      }
      updateUser(
        authDispatch,
        {
          token: authState.token,
          body: {
            _id: authState.id,
            firstname,
            lastname,
            phone,
            birthday: moment(birthday),
            street,
            housenumber,
            box,
            postalCode,
            city: cityId ? parseInt(cityId) : '',
            cv: {
              educations,
              workExperiences,
              relevantExperiences,
            },
          },
        }, (err: any, res: any) => {
          if (err) {
            isSaving = false;
            Swal.fire({
              icon: SwalIcons.Error as SweetAlertIcon,
              title: t(SwalTitle.Error),
              text: err,
            });
          } else {
            claimApi(
              {
                token: authState.token,
                body: {
                  type: CategoryTypes.Jobs,
                  content: {
                    _id: job?._id,
                    title: job?.title,
                    company: job?.company,
                    claim: job?.claim,
                    key: job?.key,
                  },
                  info: {
                    motivation,
                    educations,
                    workExperiences,
                    relevantExperiences,
                    resume: resumeObject
                  },
                  track: {
                    //@ts-ignore
                    origin: location?.state?.origin || '',
                    //@ts-ignore
                    info: location?.state?.info || '',
                    platform: 'web',
                    model: browserName,
                    brand: osName,
                    systemVersion: browserVersion,
                    appVersion: ENV.WEB_VERSION
                  },
                },
            }, (err: any, res: any) => {
              if (err) {
                isSaving = false;
                Swal.fire({
                  icon: SwalIcons.Error as SweetAlertIcon,
                  title: t(SwalTitle.Error),
                  text: err,
                });
              } else {
                isSaving = false;
                if (!JSUtil.isEmpty(job) && job) {
                  job.claim.claimed = true;
                  setJob(job);
                  Swal.fire({
                    icon: SwalIcons.Success as SweetAlertIcon,
                    title: t(SwalTitle.Success),
                    text: t(LanguageResource.Jobs + ':claim_success'),
                  }).then(() => navigateToJobDetail());
                }
              }
            });
          }
        });
    }
  }

  let inputIndex = getRenderStep(false);
  
  return(
    <UserLayout 
      navigate={props.navigate} 
      title={t(LanguageResource.Common + ':' + props.title)}
      isLoading={isLoading || loadingCities}
    >
      <div className='create-wrapper'>
        <StepsHeader 
          title={job?.title as string}
          step={Math.round(step)}
          amountOfSteps={5}
        />
        <StepsSubHeader
          title={steps[inputIndex].title}
          goBack={goStepBack}
        />
        {renderStep()}
        {
          step !== 2
          ?
          <div className="primary-button-wrapper" onClick={() => handleSubmit(steps[inputIndex].inputs)}>
            <p className="primary-button-text">
              {t(LanguageResource.Common + ':proceed_next_step')}
            </p>
          </div>
          : null
        }
      </div>
    </UserLayout>
  );
}

export default JobsDetailClaimsCreatePage;