import ApiUtil from '../utils/ApiUtil';
import {postRequest} from './api';

export const loginUserApi = (body, callback) => {
  const endpoint = '/v1/auth/login',
    headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  postRequest({endpoint}, headers, body, (err, res) => {
    ApiUtil.handleReponse(err, res, callback);
  });
};

export const registerUserApi = (body, callback) => {
  const endpoint = '/v1/users/add',
    headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  postRequest({endpoint}, headers, body, (err, res) => {
    ApiUtil.handleReponse(err, res, callback);
  });
};

export const forgotPasswordApi = (body, callback) => {
  const endpoint = '/v1/users/forgotPassword',
    headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  postRequest({endpoint}, headers, body, (err, res) => {
    ApiUtil.handleReponse(err, res, callback);
  });
};

export const validateResetTokenApi = (body, callback) => {
  const endpoint = '/v1/users/resetToken',
    headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  postRequest({endpoint}, headers, body, (err, res) => {
    ApiUtil.handleReponse(err, res, callback);
  });
};

export const resetPasswordApi = (body, callback) => {
  const endpoint = '/v1/users/resetPassword',
    headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  postRequest({endpoint}, headers, body, (err, res) => {
    ApiUtil.handleReponse(err, res, callback);
  });
};