import { useEffect, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import Swal, { SweetAlertIcon } from 'sweetalert2';

// Apis
import { getNotificationsApi } from '../../api/notifications';

// Components
import NotificationsList from './List';
import UserLayout from '../Common/Layout/User';

// Context
import { updateUser, useAuthStore } from '../../context/Auth';
import { useTranslationStore } from '../../context/Translation';

// Enums
import { LanguageResource } from '../../enums/Languages';
import { SwalIcons, SwalTitle } from '../../enums/Swal';
import { YesNo } from '../../enums/YesNo';

// Interfaces
import { NotificationInterface } from '../../interfaces/Notification';

// Styles
import './index.scss';

// Utils
import JSUtil from '../../utils/JSUtil';

interface PropsInterface {
  navigate: NavigateFunction,
  title: string
}

const NotificationsPage = (props: PropsInterface) => {
  const [notifications, setNotifications] = useState<NotificationInterface[]>([]),
    [isLoading, setIsLoading] = useState<boolean>(true),
    [authState, authDispatch] = useAuthStore(),
    [t] = useTranslationStore();

  useEffect(() => {
    loadNotifications();
    setUserHasSeenNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.loggedIn, authState.token]);

  const loadNotifications = () => {
    if (!JSUtil.isEmpty(authState.token)) {
      getNotificationsApi(
        {
          token: authState.token,
        },
        (err: any, res: NotificationInterface[]) => {
          if (err) {
            Swal.fire({
              icon: SwalIcons.Error as SweetAlertIcon,
              title: t(SwalTitle.Error),
              text: err,
            });
          } else {
            setNotifications(res);
          }
          setIsLoading(false);
        },
        authDispatch,
      );
    }
  };

  const setUserHasSeenNotifications = () => {
    updateUser(
      authDispatch,
      {
        token: authState.token,
        body: {
          _id: authState.id,
          seenNotifications: YesNo.Yes,
        },
      },
      (err: any) => {
        if (err) {
          Swal.fire({
            icon: SwalIcons.Error as SweetAlertIcon,
            title: t(SwalTitle.Error),
            text: err,
          });
        }
      },
    );
  };

  return (
    <UserLayout 
      navigate={props.navigate}
      title={t(LanguageResource.Common + ':' + props.title)}
      isLoading={isLoading}
      goBack={
        () => {
          props.navigate(-1);
        }
      }
    >
      <NotificationsList
        notifications={notifications}
        navigate={props.navigate}
      />
    </UserLayout>
  );
};

export default NotificationsPage;
