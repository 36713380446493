
import { useState } from 'react';
// @ts-ignore
import LoadingOverlay from 'react-loading-overlay';
import { NavigateFunction } from 'react-router-dom';

// Components
import Navigation from './Navigation';
import Header from './Header';
import useWindowDimensions from './WindowDismensions';

// Css
import './index.scss';

// Enums
import { Styles } from '../../../../enums/App';

interface PropsInterface {
  isLoading: boolean,
  children: any,
  navigate: NavigateFunction,
  title?: string,
  sideBarComponent?: any,
  goBack?: () => void;
}

const UserLayout = (props: PropsInterface) => {
  const {height, width} = useWindowDimensions(),
    [showMenu, setShowMenu] = useState(width > Styles.MobileWidth);
  return(
    <div className="wrapper">
      <LoadingOverlay
        active={props.isLoading || false}
        spinner
        styles={{
          wrapper: (base: any) => ({
          ...base,
          flex: 1,
          display: 'flex'
          })
        }}
      >     
        <div className="user-wrapper row-wrapper">
          {
            showMenu
            ?
            <div className="menu-wrapper column-wrapper">
              <Navigation 
                navigate={props.navigate}
              />
              {
                props.sideBarComponent
                ?
                props.sideBarComponent
                : null
              }
            </div>
            : null
          }
          <div className="user-content-wrapper column-wrapper">
            <Header 
              goBack={props.goBack}
              title={props.title}
              showMenu={showMenu}
              setShowMenu={setShowMenu}
            />
            <div className="content-wrapper">
              {props.children}
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </div>
  );
}

export default UserLayout;