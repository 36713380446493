// Interfaces
import { InputInterface } from '../../../../../interfaces/Input';

const RadioInput = (props: InputInterface) => {
  return (
    <div className={`radio-button-wrapper ${props.value === props.name ? "radio-button-active-wrapper" : "" }`}>
      <input 
        type="radio"
        className="radio-button"
        name={props.name}
        onChange={() => props.onChange(props.value === props.name ? '' : props.name)}
        value={props.name}
        checked={props.value === props.name} 
      />
      <p className="radio-button-text">
        {props.label}
      </p>
    </div>
  );
};

export default RadioInput;
