// Context
import { useTranslationStore } from '../../../../context/Translation';

interface PropsInterface {
  title: string,
  goBack: () => void,
}

const SubHeader = (props: PropsInterface) => {
  const [t] = useTranslationStore();

  return (
    <div className="row-spread-wrapper">
      <a onClick={() => props.goBack()} className="secondary-arrow-button-wrapper">
        &#8592;
      </a>
      <p className="text-weight-bold text-size-default">{t(props.title)}</p>
    </div>
  )
}

export default SubHeader;
