export default class JSUtil {
  static isEmpty(value: any) {
    if (value === undefined || value === null) {
      return true;
    }

    if (Array.isArray(value) && value.length === 0) {
      return true;
    }

    if (
      typeof value === 'object' &&
      Object.keys(value).length === 0 &&
      typeof value.getMonth !== 'function'
    ) {
      return true;
    }
    return value === '';
  }

  static isValidEmail(value: string) {
    if (value === '') {
      return false;
    }

    const re = /\S+@\S+\.\S+/;
    return re.test(value);
  }

  static isValidPhone(value: string) {
    value = value.replace(/\s/g, '');
    if (value === '') {
      return false;
    }
    
    const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}$/im;
    return re.test(value);
  }

  static showShortNumber = (number: number) => {
    if (JSUtil.isEmpty(number)) {
      return 0;
    }

    return number;
  };

  static getBase64 = (file: Blob) => new Promise((resolve, reject) => {
    if (typeof file === 'string') {
      resolve(file);
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let result = reader.result as string;
        result = result.split('base64,')[1];
        resolve(result);
      };
      reader.onerror = error => reject(console.error(error));
    }
  });
}
