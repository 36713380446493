// Context
import { useTranslationStore } from '../../../../../context/Translation';

// Enums
import { ImageSources } from '../../../../../enums/Images';
import { EducationInterface, RelevantExperienceInterface, WorkExperienceInterface } from '../../../../../interfaces/Job';
import { LanguageResource } from '../../../../../enums/Languages';

// Utils
import DateUtil from '../../../../../utils/DateUtil';

interface PropsInterface {
  firstname: string;
  lastname: string;
  birthday: string;
  phone: string;
  street: string;
  housenumber: string;
  box: string;
  postalCode: string;
  cityId: string;
  resume: File;
  educations: EducationInterface[],
  workExperiences: WorkExperienceInterface[],
  relevantExperiences: RelevantExperienceInterface[],
  motivation: string;
  setStep: (arg0: number) => void;
}

const Step4 = (props: PropsInterface) => {
  const [t] = useTranslationStore();

  const renderEducations = () => {
    return props.educations.map((education: EducationInterface, index: number) => {
      return (
        <div className="claim-create-experience-wrapper" key={index}>
          <div className="row-wrapper">
            <img src={require('../../../../../' + ImageSources.JobsEducation.url)} alt={ImageSources.JobsEducation.name}/>
            <div className="column-wrapper">
              <p className="text-weight-bold text-size-default">{education.fieldOfStudy}</p>
              <p className="text-size-small">{education.collegeOrUniversity}</p>
            </div>
          </div>
          <div className="claim-create-experience-content-wrapper">
            <p className="text-weight-bold text-size-default">{education.levelEducation}</p>
            <p className="text-size-small">             
              {education.timePeriod
                ? t(LanguageResource.Jobs + ':claim_create_education_currentlyEnrolled')
                : DateUtil.displayDate(education.fromDate as Date) +
                  ' - ' +
                  DateUtil.displayDate(education.toDate as Date)}
            </p>
          </div>
        </div>
      );
    });
  };

  const renderWorkExperiences = () => {
    return props.workExperiences.map((workExperience: WorkExperienceInterface, index: number) => {
      return (
        <div className="claim-create-experience-wrapper" key={index}>
          <div className="row-wrapper">
            <img src={require('../../../../../' + ImageSources.JobsWork.url)} alt={ImageSources.JobsWork.name}/>
            <div className="column-wrapper">
              <p className="text-weight-bold text-size-default">{workExperience.title}</p>
              <p className="text-size-small">{workExperience.company}</p>
            </div>
          </div>
          <div className="claim-create-experience-content-wrapper">
            <p className="text-weight-bold text-size-default">{workExperience.city}</p>
            <p className="text-size-small">
              {workExperience.timePeriod
                ? t(LanguageResource.Jobs + ':claim_create_work_experience_currentlyEnrolled')
                : DateUtil.displayDate(workExperience.fromDate as Date) +
                  ' - ' +
                  DateUtil.displayDate(workExperience.toDate as Date)}
            </p>
            <p className="text-size-small">
              {workExperience.description}
            </p>
          </div>
        </div>
      );
    });
  };

  const renderRelevantExperiences = () => {
    return props.relevantExperiences.map((relevantExperience: RelevantExperienceInterface, index: number) => {
      return (
        <div className="claim-create-experience-wrapper" key={index}>
          <div className="row-wrapper">
            <img src={require('../../../../../' + ImageSources.JobsRelevant.url)} alt={ImageSources.JobsRelevant.name}/>
            <div className="column-wrapper">
              <p className="text-weight-bold text-size-default">{relevantExperience.title}</p>
              <p className="text-size-small">{relevantExperience.location}</p>
            </div>
          </div>
          <div className="claim-create-experience-content-wrapper">
            <p className="text-size-small">
              {relevantExperience.description}
            </p>
          </div>
        </div>
      );
    });
  };

  const goToStep = (event: any, step: number) => {
    event.preventDefault();
    props.setStep(step);
  }
  
  return (
    <div className="claim-create-form-wrapper">
      <div className="row-wrapper create-form-overview-wrapper">
        <div className="column-wrapper reviews-wrapper">
          <div className="row-spread-wrapper">
            <p className="text-size-default text-weight-bold">{t(LanguageResource.Common + ':personal_information')}</p>
            <a href="javascript:void(0)" onClick={event => goToStep(event, 0)}>{t(LanguageResource.Common + ':edit')}</a>
          </div>
          <div className="review-wrapper">
            <p className="text-size-default text-color-gray-dark">{t(LanguageResource.Input + ':firstname')}</p>
            <p>{props.firstname}</p>
            <p className="text-size-default text-color-gray-dark">{t(LanguageResource.Input + ':lastname')}</p>
            <p>{props.lastname}</p>
            <p className="text-size-default text-color-gray-dark">{t(LanguageResource.Input + ':phone')}</p>
            <p>{props.phone}</p>
            <p className="text-size-default text-color-gray-dark">{t(LanguageResource.Input + ':birthday')}</p>
            <p>{props.birthday}</p>
          </div>
          <div className="row-spread-wrapper">
            <p className="text-size-default text-weight-bold">{t(LanguageResource.Common + ':contact_information')}</p>
            <a href="javascript:void(0)" onClick={event => goToStep(event, 1)}>{t(LanguageResource.Common + ':edit')}</a>
          </div>
          <div className="review-wrapper">
            <p className="text-size-default text-color-gray-dark">{t(LanguageResource.Input + ':street')}</p>
            <p>{props.street}</p>
            <p className="text-size-default text-color-gray-dark">{t(LanguageResource.Input + ':housenumber')}</p>
            <p>{props.housenumber}</p>
            <p className="text-size-default text-color-gray-dark">{t(LanguageResource.Input + ':box')}</p>
            <p>{props.box}</p>
            <p className="text-size-default text-color-gray-dark">{t(LanguageResource.Input + ':postalCode')}</p>
            <p>{props.postalCode}</p>
          </div>
        </div>
        <div className="column-wrapper reviews-wrapper">
          <div className="row-spread-wrapper">
            <p className="text-size-default text-weight-bold">{t(LanguageResource.Jobs + ':resume')}</p>
            <a href="javascript:void(0)" onClick={event => goToStep(event, 2)}>{t(LanguageResource.Common + ':edit')}</a>
          </div>
          <div className="review-wrapper">
          {props.resume ? (
            <p className="text-size-default text-color-grayDark">{props.resume.name}</p>
          ) : (
            <div>
              {renderEducations()}
              {renderWorkExperiences()}
              {renderRelevantExperiences()}
            </div>
          )}
          </div>
          <div className="row-spread-wrapper">
            <p className="text-size-default text-weight-bold">{t(LanguageResource.Jobs + ':motivation')}</p>
            <a href="javascript:void(0)" onClick={event => goToStep(event, 3)}>{t(LanguageResource.Common + ':edit')}</a>
          </div>
          <div className="review-wrapper">
            <p className="text-size-lage text-color-grayDark">{props.motivation}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step4;