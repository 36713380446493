// Context
import { useTranslationStore } from '../../../context/Translation';

// Enums
import { LanguageResource } from '../../../enums/Languages';

// Styles
import './ShowMore.scss'

// Utils
import JSUtil from '../../../utils/JSUtil';

interface PropsInterface {
  limit: number;
  amount: number;
  plusLimit?: number;
  setLimit?: (arg0: number) => void;
}

const ShowMore = (props: PropsInterface) => {
  const [t] = useTranslationStore();

  if (!props.plusLimit) props.plusLimit = 5;
  return (
    <div className='show-more-wrapper'>
      {
        !JSUtil.isEmpty(props.limit) &&
        !JSUtil.isEmpty(props.setLimit) &&
        props.limit < props.amount
        ? <div
            className='show-more-button-wrapper'
            onClick={() => props.setLimit && props.limit && props.plusLimit
              ? props.setLimit(props.limit + props.plusLimit)
              : null}
          >
            <p className='text-align-center text-weight-bold'>{t(LanguageResource.Common + ':show_more')}</p>
          </div>
        : null
      }
            {
        !JSUtil.isEmpty(props.limit) && props.limit > 20
        ? <div
            className='show-less-button-wrapper'
            onClick={() => props.setLimit && props.limit && props.plusLimit
              ? props.setLimit(props.limit - props.plusLimit)
              : null}
          >
            <p className='text-align-center'>{t(LanguageResource.Common + ':show_less')}</p>
          </div>
        : null
      }
    </div>
  );
};

export default ShowMore;
