
import Swal, {SweetAlertIcon} from 'sweetalert2';

// Apis
import {trackClickUrlApi} from '../../../../api/tracking';

// Context
import { useAuthStore } from '../../../../context/Auth';
import { useTranslationStore } from '../../../../context/Translation';

// Enums
import { SwalIcons, SwalTitle } from '../../../../enums/Swal';

// Interfaces
import { DealInterface } from '../../../../interfaces/Deal';
import { EventInterface } from '../../../../interfaces/Event';
import { JobInterface } from '../../../../interfaces/Job';
import { NewsInterface } from '../../../../interfaces/News';

// Utils
import JSUtil from '../../../../utils/JSUtil';

interface PropsInterface {
  data: JobInterface | DealInterface | EventInterface | NewsInterface;
  type: string | undefined;
}

const DetailTabsDescription = (props: PropsInterface) => {
  const [authState] = useAuthStore(),
    [t] = useTranslationStore();

  const trackClickUrl = (url: string) => {
    trackClickUrlApi(
      {
        token: authState.token,
        body: {
          type: props.type,
          id: props.data._id,
          url,
        },
      },
      (err: any) => {
        if (err) {
          Swal.fire({
            icon: SwalIcons.Error as SweetAlertIcon,
            title: t(SwalTitle.Error),
            text: err,
          });
        } else {
          window.open(url, "_blank");
        }
      },
    );
  };

  return (
    <div 
      className="description-wrapper"
      dangerouslySetInnerHTML={{__html: props.data.content as string}}
      onClick={e => {
        e.preventDefault();
        const url = (e.target as HTMLLinkElement).href;
        if (!JSUtil.isEmpty(url)) {
          trackClickUrl(url);
        }
      }}
    />
  );
};

export default DetailTabsDescription;
