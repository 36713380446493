import { useState, useEffect } from 'react';
import { NavigateFunction, useParams, useLocation } from 'react-router-dom';
import Swal, {SweetAlertIcon} from 'sweetalert2';
import { browserName, browserVersion, osName } from "react-device-detect";

// Apis
import { claimApi } from '../../../api/claims';
import { consultationApi } from '../../../api/consultations';
import { getJobsApi } from '../../../api/jobs';
import { saveApi } from '../../../api/saves';

// Components
import ProfilePopupCompleteClaim from '../../Profile/Popup/Complete/Claim';
import ClaimsSuccessUrl from './Claims/SuccessUrl';
import DetailTabs from '../../Common/Detail/Tabs/';
import UserLayout from '../../Common/Layout/User';
import JobInfoCategories from '../Info/Categories';
import JobInfoCity from '../Info/City';
import JobInfoLogo from '../Info/Logo';
import JobInfoType from '../Info/Type';
import ShareButton from '../../Common/Buttons/Share';

// Config
import ENV from '../../../config/env.json';

// Context
import { useAuthStore } from '../../../context/Auth';
import { useTranslationStore } from '../../../context/Translation';

// Enums
import { CategoryTypes } from '../../../enums/Categories';
import { ClaimStates, ClaimTypes } from '../../../enums/Jobs';
import { LanguageResource } from '../../../enums/Languages';
import { RoutesLinks } from '../../../enums/Routes';
import { StorageParams } from '../../../enums/Storages';
import { SwalIcons, SwalTitle } from '../../../enums/Swal';

// Interfaces
import { JobInterface } from '../../../interfaces/Job';

// Styles
import './index.scss';

// Svgs
import { ReactComponent as SaveActive } from '../../../assets/svg/save_active.svg';
import { ReactComponent as SaveInActive } from '../../../assets/svg/save_inactive.svg';

// Utils
import JSUtil from '../../../utils/JSUtil';
import DateUtil from '../../../utils/DateUtil';

interface PropsInterface {
  navigate: NavigateFunction,
}

const JobsDetailPage = (props: PropsInterface) => {
  const [authState, authDispatch] = useAuthStore(),
    navigationParams = useParams(),
    jobKey = navigationParams[RoutesLinks.JobsDetail.Params.JobKey],
    [job, setJob] = useState<JobInterface>(),
    [isLoading, setIsLoading] = useState<boolean>(true),
    [saved, setSaved] = useState<boolean>(false),
    [claimed, setClaimed] = useState<boolean>(false),
    [popup, setPopup] = useState<string>(''),
    [t] = useTranslationStore(),
    location = useLocation();
  let isSaving = false,
    isClaiming = false;

  useEffect(() => {
    loadJob();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.loggedIn]);

  const loadJob = () => {
    getJobsApi(
      {
        token: authState.token,
        key: jobKey
      },
      (err: any, res: {amount: number; result: JobInterface[]}) => {
        setIsLoading(false);
        if (err) {
          Swal.fire({
            icon: SwalIcons.Error as SweetAlertIcon,
            title: t(SwalTitle.Error),
            text: err,
          }).then(() => {
            props.navigate(RoutesLinks.Jobs.Link);
          });
        } else if (!JSUtil.isEmpty(res.result[0])) {
          setJob(res.result[0]);
          setClaimed(res.result[0]?.claim.claimed)
          setSaved(res.result[0]?.saved)
          addConsultation(res.result[0]);
        } else {
          Swal.fire({
            icon: SwalIcons.Error as SweetAlertIcon,
            title: t(SwalTitle.Error),
            text: t(LanguageResource.Common + ':information_not_available'),
          }).then(() => {
            props.navigate(RoutesLinks.Jobs.Link);
          });
        }
      },
      authDispatch
    );
  };

  const addConsultation = (job: JobInterface) => {
    consultationApi(
      {
        token: authState.token,
        body: {
          type: CategoryTypes.Jobs,
          id: job._id,
          companyId: job.company.by,
          userId: authState.id || localStorage.getItem(StorageParams.UserId),
          track: {
            //@ts-ignore
            origin: location?.state?.origin || '',
            //@ts-ignore
            info: location?.state?.info || '',
            platform: 'web',
            model: browserName,
            brand: osName,
            systemVersion: browserVersion,
            appVersion: ENV.WEB_VERSION
          },
        },
      },
      (err: any) => {
        if (err) {
          Swal.fire({
            icon: SwalIcons.Error as SweetAlertIcon,
            title: t(SwalTitle.Error),
            text: err,
          });
        }
      },
    );
  }

  const saveJobs = (saved: boolean) => {
    if (authState.loggedIn) {
      if (!JSUtil.isEmpty(job) && job) {
        if (!isSaving) {
          isSaving = true;
          saveApi(
            {
              token: authState.token,
              body: {
                type: CategoryTypes.Jobs,
                id: job._id,
                companyId: job.company.by,
                saved,
                track: {
                  //@ts-ignore
                  origin: location?.state?.origin || '',
                  //@ts-ignore
                  info: location?.state?.info || '',
                  platform: 'web',
                  model: browserName,
                  brand: osName,
                  systemVersion: browserVersion,
                  appVersion: ENV.WEB_VERSION
                },
              },
            },
            (err: any) => {
              if (err) {
                isSaving = false;
                Swal.fire({
                  icon: SwalIcons.Error as SweetAlertIcon,
                  title: t(SwalTitle.Error),
                  text: err,
                });
              } else {
                isSaving = false;
                setSaved(saved);
              }
            },
          );
        }
      }
    } else {
      fireSwalWithLoginMessage();
    }
  };

  const onPressClaimButton = (skipProfile: boolean) => {
    if (authState.loggedIn) {
      if (!JSUtil.isEmpty(job) && job) {
        if (
          (JSUtil.isEmpty(authState.education) ||
            JSUtil.isEmpty(authState.education.typeOfStudy) ||
            (authState.education.typeOfStudy._id !== 1 &&
              authState.education.typeOfStudy._id !== 3 &&
              (JSUtil.isEmpty(authState.education.faculty) ||
                JSUtil.isEmpty(authState.education.faculty._id) ||
                JSUtil.isEmpty(authState.education.school) ||
                JSUtil.isEmpty(authState.education.school._id)))) &&
          !skipProfile
        ) {
          setPopup(ClaimStates.CompleteProfile);
        } else if (job.claim?.type === ClaimTypes.Url) {
          if (!isClaiming) {
            isClaiming = true;
            claimApi(
              {
                token: authState.token,
                body: {
                  type: CategoryTypes.Jobs,
                  content: {
                    _id: job._id,
                    title: job.title,
                    company: job.company,
                    claim: job.claim,
                    key: job.key,
                  },
                  track: {
                    //@ts-ignore
                    origin: location?.state?.origin || '',
                    //@ts-ignore
                    info: location?.state?.info || '',
                    platform: 'web',
                    model: browserName,
                    brand: osName,
                    systemVersion: browserVersion,
                    appVersion: ENV.WEB_VERSION
                  },
                },
              },
              (err: any) => {
                if (err) {
                  isClaiming = false;
                  Swal.fire({
                    icon: SwalIcons.Error as SweetAlertIcon,
                    title: t(SwalTitle.Error),
                    text: err,
                  });
                } else {
                  isClaiming = false;
                  setClaimed(true);
                  setPopup(ClaimStates.SuccessUrl);
                }
              },
            );
          }
        } else {
          props.navigate(
            RoutesLinks.Resume.Link.replace(
              ':' + RoutesLinks.Resume.Params.JobKey, job.key
            ).replace(
              ':' + RoutesLinks.Resume.Params.UserId, authState.uuid
            )
          );
        }
      }
    } else {
      fireSwalWithLoginMessage();
    }
  };

  const renderClaimButton = () => {
    if (claimed && job) {
      switch (job.claim.type) {
        case ClaimTypes.Url:
          return (
            <div className='button-wrapper primary-button-wrapper' onClick={() => openUrl()}>
              <p className='primary-button-text'>{t(LanguageResource.Jobs + ':claimed_url')}</p>
            </div>
          );
        default:
          return (
            <div className='button-wrapper disabled-button-wrapper'>
              <p className='disabled-button-text'>{t(LanguageResource.Jobs + ':claimed')}</p>
            </div>
          );
      }
    } else if (
      (job?.endDate && DateUtil.isTodayBeforeDate(job?.endDate)) ||
      JSUtil.isEmpty(job?.endDate)
    ) {
      return (
          <div className='button-wrapper primary-button-wrapper' onClick={() => onPressClaimButton(false)}>
            <p className='primary-button-text'>{t(LanguageResource.Jobs + ':claim')}</p>
          </div>
      );
    }
  };

  const renderSaveButton = () => {
    return (
      <div className='button-wrapper save-button-wrapper' onClick={() => saveJobs(!saved)}>
        {
          saved
          ? <SaveActive  />
          : <SaveInActive />
        }
        <p>{saved ? t(LanguageResource.Common + ':saved') : t(LanguageResource.Common + ':save')} ({JSUtil.showShortNumber(job?.consultationAmount as number)})</p>
      </div>
    )
  }

  const fireSwalWithLoginMessage = () => {
    Swal.fire({
      title: t(LanguageResource.Common + ':login_needed_title'),
      text: t(LanguageResource.Common + ':login_needed_text'),
      showCancelButton: true
    }).then(result => {
      if (result.isConfirmed) {
        props.navigate(RoutesLinks.Login.Link);
      }
    });
  }

  const closePopup = () => {
    setPopup('');
    if (job && job.claim.type === ClaimTypes.Url && job.claim?.url) {
      window.open(job.claim.url, "_blank");
    }
  };

  const openUrl = () => {
    if (job && job.claim.type === ClaimTypes.Url && job.claim?.url) {
      window.open(job.claim.url, "_blank");
    }
  }

  const renderPopup = () => {
    switch (popup) {
      case ClaimStates.CompleteProfile:
        return (
          <ProfilePopupCompleteClaim
            onPressClaimButton={() => onPressClaimButton(true)}
            closePopup={() => closePopup()}
            label={t(LanguageResource.Jobs + ':claim_confirm')}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        );
      case ClaimStates.SuccessUrl:
        return <ClaimsSuccessUrl 
          closePopup={() => closePopup()}
        />;
      default:
        return null;
    }
  };
  
  return (
    <UserLayout 
      navigate={props.navigate} 
      isLoading={isLoading}
      goBack={
        () => {
          props.navigate(-1);
        }
      }
    >
      {
        !JSUtil.isEmpty(job) && job && !isLoading
        ?
        <>
          <div className='detail-header-wrapper row-wrapper'>
            <div className='job-info-wrapper'>
              <div className='row-wrapper'>
                <JobInfoLogo company={job.company} />
                <div className='column-wrapper'>
                <div className='row-wrapper'>
                  <div className='column-wrapper'>
                    <div className='row-wrapper job-title-wrapper'>
                      <p className='text-weight-bold text-size-large'>{job.title as string}</p>
                      <p className='text-color-gray-dark text-at'> {t(LanguageResource.Common + ':at')} </p>
                      <p className='text-weight-bold text-size-large'>
                        {
                          !JSUtil.isEmpty(job.company.for)
                          ? job.company.for
                          : job.company.name
                        }
                      </p>
                    </div>
                  </div>
                </div>
                  <div className='row-wrapper job-sub-title-wrapper'>
                    <JobInfoCity job={job} showFullAddress={true}/>
                    <JobInfoType job={job} />
                    <JobInfoCategories job={job} />
                  </div>
                </div>
              </div>
            </div>
            <div className='detail-button-wrapper row-wrapper'>
              {renderClaimButton()}
              {renderSaveButton()}
              <ShareButton shareUrl={job.shareUrl} />
            </div>
          </div>
          <div className='detail-content-wrapper'>
            <DetailTabs
              data={job as JobInterface}
              useRequirements={true}
              type={CategoryTypes.Jobs}
            />
            {renderPopup()}
          </div>
        </>
        : null
      }
    </UserLayout>
  );
}

export default JobsDetailPage