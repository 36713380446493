// Context
import { useTranslationStore } from '../../../context/Translation';

// Enums
import { LanguageResource } from "../../../enums/Languages";

// Interfaces
import { JobInterface } from "../../../interfaces/Job";

// Svgs
import { ReactComponent as BriefCase } from '../../../assets/svg/briefcase.svg';

interface PropsInterface {
  job: JobInterface;
};

const JobInfoType = (props: PropsInterface) => {
  const [t] = useTranslationStore();

  return (
    <div className='row-wrapper'>
      <div className='svg-wrapper'>
        <BriefCase fill='#FF462F' stroke='#FF462F' />
      </div>
      <p>
        {t(LanguageResource.Jobs + ':type_' + props.job.type)}
      </p>
    </div>
  );
};

export default JobInfoType;