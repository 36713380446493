// Context
import { useTranslationStore } from '../../../context/Translation';

// Enums
import { ExperienceTypes } from '../../../enums/Jobs';
import { ImageSources } from '../../../enums/Images';
import { LanguageResource } from '../../../enums/Languages';

// Interfaces
import { EducationInterface, RelevantExperienceInterface, WorkExperienceInterface } from '../../../interfaces/Job';

// Styles
import './index.scss';
import DateUtil from '../../../utils/DateUtil';
import JSUtil from '../../../utils/JSUtil';

interface PropsInterface {
  educations: EducationInterface[];
  setEducations: (arg0: EducationInterface[]) => void;
  workExperiences: WorkExperienceInterface[];
  setWorkExperiences: (arg0: WorkExperienceInterface[]) => void;
  relevantExperiences: RelevantExperienceInterface[];
  setRelevantExperiences: (arg0: RelevantExperienceInterface[]) => void;
  onPress: (arg0: string, arg1: number | null) => void;
}

const CVOverview = (props: PropsInterface) => {
  const [t] = useTranslationStore();

  const removeEducation = (event: any, index: number) => {
    event.preventDefault();
    const newEducations = [...props.educations];
    newEducations.splice(index, 1);
    props.setEducations(newEducations);
  };

  const editEducation = (event: any, index: number) => {
    event.preventDefault();
    props.onPress(ExperienceTypes.Education, index);
  };

  const removeWorkExperience = (event: any, index: number) => {
    event.preventDefault();
    const newWorkExperiences = [...props.workExperiences];
    newWorkExperiences.splice(index, 1);
    props.setWorkExperiences(newWorkExperiences);
  };

  const editWorkExperience = (event: any, index: number) => {
    event.preventDefault();
    props.onPress(ExperienceTypes.WorkExperience, index);
  };

  const removeRelevantExperience = (event: any, index: number) => {
    event.preventDefault();
    const newRelevantExperiences = [...props.relevantExperiences];
    newRelevantExperiences.splice(index, 1);
    props.setRelevantExperiences(newRelevantExperiences);
  };

  const editRelevantExperience = (event: any, index: number) => {
    event.preventDefault();
    props.onPress(ExperienceTypes.RelevantExperience, index);
  };

  const renderEducations = () => {
    return props.educations.map((education, index) => {
      return (
        <div className="claim-create-experience-wrapper" key={index}>
          <div className="row-wrapper">
            <img src={require('../../../' + ImageSources.JobsEducation.url)} alt={ImageSources.JobsEducation.name}/>
            <div className="column-wrapper">
              <p className="text-weight-bold text-size-default">{education.fieldOfStudy}</p>
              <p className="text-size-small">{education.collegeOrUniversity}</p>
            </div>
            <a href="javascript:void(0)" onClick={event => removeEducation(event, index)}><img src={require('../../../' + ImageSources.Trash.url)} alt={ImageSources.Trash.name}/></a>
            <a href="javascript:void(0)" onClick={event => editEducation(event, index)}><img src={require('../../../' + ImageSources.Edit.url)} alt={ImageSources.Edit.name}/></a>
          </div>
          <div className="claim-create-experience-content-wrapper">
            <p className="text-weight-bold text-size-default">{education.levelEducation}</p>
            <p className="text-size-small">             
              {education.timePeriod
                ? t(LanguageResource.Jobs + ':claim_create_education_currentlyEnrolled')
                : DateUtil.displayDate(education.fromDate as Date) +
                  ' - ' +
                  DateUtil.displayDate(education.toDate as Date)}
            </p>
          </div>
        </div>
      );
    });
  };
  const renderWorkExperiences = () => {
    return props.workExperiences.map((workExperience, index) => {
      return (
        <div className="claim-create-experience-wrapper" key={index}>
          <div className="row-wrapper">
            <img src={require('../../../' + ImageSources.JobsWork.url)} alt={ImageSources.JobsWork.name}/>
            <div className="column-wrapper">
              <p className="text-weight-bold text-size-default">{workExperience.title}</p>
              <p className="text-size-small">{workExperience.company}</p>
            </div>
            <a href="javascript:void(0)" onClick={event => removeWorkExperience(event, index)}><img src={require('../../../' + ImageSources.Trash.url)} alt={ImageSources.Trash.name}/></a>
            <a href="javascript:void(0)" onClick={event => editWorkExperience(event, index)}><img src={require('../../../' + ImageSources.Edit.url)} alt={ImageSources.Edit.name}/></a>
          </div>
          <div className="claim-create-experience-content-wrapper">
            <p className="text-weight-bold text-size-default">{workExperience.city}</p>
            <p className="text-size-small">
              {workExperience.timePeriod
                ? t(LanguageResource.Jobs + ':claim_create_education_currentlyEnrolled')
                : DateUtil.displayDate(workExperience.fromDate as Date) +
                  ' - ' +
                  DateUtil.displayDate(workExperience.toDate as Date)}
            </p>
            <p className="text-size-small">
              {workExperience.description}
            </p>
          </div>
        </div>
      );
    });
  };
  const renderRelevantExperiences = () => {
    return props.relevantExperiences.map((relevantExperience, index) => {
      return (
        <div className="claim-create-experience-wrapper" key={index}>
          <div className="row-wrapper">
            <img src={require('../../../' + ImageSources.JobsRelevant.url)} alt={ImageSources.JobsRelevant.name}/>
            <div className="column-wrapper">
              <p className="text-weight-bold text-size-default">{relevantExperience.title}</p>
              <p className="text-size-small">{relevantExperience.location}</p>
            </div>
            <a href="javascript:void(0)" onClick={event => removeRelevantExperience(event, index)}><img src={require('../../../' + ImageSources.Trash.url)} alt={ImageSources.Trash.name}/></a>
            <a href="javascript:void(0)" onClick={event => editRelevantExperience(event, index)}><img src={require('../../../' + ImageSources.Edit.url)} alt={ImageSources.Edit.name}/></a>
          </div>
          <div className="claim-create-experience-content-wrapper">
            <p className="text-size-small">
              {relevantExperience.description}
            </p>
          </div>
        </div>
      );
    });
  };

  const onPress = (event: any, type: string, index: number | null) => {
    event.preventDefault();
    props.onPress(type, index)
  };

  return (
    <div className="claim-create-experiences-wrapper">
      {!JSUtil.isEmpty(props.educations) ? (
        <div>
          {renderEducations()}
          <a href="javascript:void(0)" onClick={event => onPress(event, ExperienceTypes.Education, null)}>{t(LanguageResource.Jobs + ':claim_create_education_add')}</a>
        </div>
      ) : (
        <div className="claim-create-add-experience-wrapper" onClick={() => props.onPress(ExperienceTypes.Education, null)}>
          <img src={require('../../../' + ImageSources.JobsEducation.url)} alt={ImageSources.JobsEducation.name}/>
          <p className="text-weight-bold text-size-default">{t(LanguageResource.Jobs + ':claim_create_education_add')}</p>
        </div>
      )}
      {!JSUtil.isEmpty(props.workExperiences) ? (
        <div>
          {renderWorkExperiences()}
          <a href="javascript:void(0)" onClick={event => onPress(event, ExperienceTypes.WorkExperience, null)}>{t(LanguageResource.Jobs + ':claim_create_work_experience_add')}</a>
        </div>
      ) : (
        <div className="claim-create-add-experience-wrapper" onClick={() => props.onPress(ExperienceTypes.WorkExperience, null)}>
          <img src={require('../../../' + ImageSources.JobsWork.url)} alt={ImageSources.JobsWork.name}/>
          <p className="text-weight-bold text-size-default">{t(LanguageResource.Jobs + ':claim_create_work_experience_add')}</p>
        </div>
      )}
      {!JSUtil.isEmpty(props.relevantExperiences) ? (
        <div>
          {renderRelevantExperiences()}
          <a href="javascript:void(0)" onClick={event => onPress(event, ExperienceTypes.RelevantExperience, null)}>{t(LanguageResource.Jobs + ':claim_create_relevant_experience_add')}</a>
        </div>
      ) : (
        <div className="claim-create-add-experience-wrapper" onClick={() => props.onPress(ExperienceTypes.RelevantExperience, null)}>
          <img src={require('../../../' + ImageSources.JobsRelevant.url)} alt={ImageSources.JobsRelevant.name}/>
          <p className="text-weight-bold text-size-default">{t(LanguageResource.Jobs + ':claim_create_relevant_experience_add')}</p>
        </div>
      )}
    </div>
  );
};

export default CVOverview;
