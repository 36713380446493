// Enums
import moment from "moment";
import { InputTypes } from "../../../enums/Inputs";
import { LanguageResource } from "../../../enums/Languages";
import { WorkExperienceInterface } from "../../../interfaces/Job";
import DateUtil from "../../../utils/DateUtil";

export const getWorkExperienceInputs = (
  t: any,
  title: string,
  setTitle: (arg0: string) => void,
  company: string,
  setCompany:  (arg0: string) => void,
  city: string,
  setCity: (arg0: string) => void,
  timePeriod: boolean,
  setTimePeriod: (arg0: boolean) => void,
  fromDate: string,
  setFromDate: (arg0: string) => void,
  toDate: string,
  setToDate: (arg0: string) => void,
  description: string,
  setDescription: (arg0: string) => void,
) => {
  return [
    {
      name: "title",
      type: InputTypes.Text,
      onChange: (val: string) => setTitle(val),
      value: title,
      label: t(LanguageResource.Jobs + ':claim_work_experience_title'),
      required: true,
    },
    {
      name: "company",
      type: InputTypes.Text,
      onChange: (val: string) => setCompany(val),
      value: company,
      label: t(LanguageResource.Jobs + ':claim_work_experience_company'),
      required: true,
    },
    {
      name: "city",
      type: InputTypes.Text,
      onChange: (val: string) => setCity(val),
      value: city,
      label: t(LanguageResource.Jobs + ':claim_work_experience_city'),
      required: true,
    },
    {
      name: "timePeriod",
      type: InputTypes.Checkbox,
      onChange: (val: boolean) => setTimePeriod(val),
      value: timePeriod,
      label: t(LanguageResource.Jobs + ':claim_create_work_experience_currentlyEnrolled'),
    },
    {
      name: "fromDate",
      type: InputTypes.Date,
      onChange: (val: string) => setFromDate(val),
      value: fromDate,
      label: t(LanguageResource.Common + ':fromDate'),
      condition: !timePeriod,
      required: true,
    },
    {
      name: "toDate",
      type: InputTypes.Date,
      onChange: (val: string) => setToDate(val),
      value: toDate,
      label: t(LanguageResource.Common + ':toDate'),
      condition: !timePeriod,
      required: true,
    },
    {
      name: "description",
      type: InputTypes.Text,
      onChange: (val: string) => setDescription(val),
      value: description,
      label: t(LanguageResource.Jobs + ':claim_work_experience_description'),
      required: true,
    },
  ];
}

export const formatWorkExperienceData = (
  workExperience: WorkExperienceInterface | null = null,
  setTitle: (arg0: string) => void,
  setCompany: (arg0: string) => void,
  setCity: (arg0: string) => void,
  setTimePeriod: (arg0: boolean) => void,
  setFromDate: (arg0: string) => void,
  setToDate: (arg0: string) => void,
  setDescription: (arg0: string) => void,
) => {
  setTitle(workExperience?.title || '');
  setCompany(workExperience?.company || '');
  setCity(workExperience?.city || '');
  setTimePeriod(workExperience?.timePeriod || false);
  setFromDate(workExperience?.fromDate ? moment(workExperience?.fromDate).format('YYYY-MM-DD') : DateUtil.formatInputDate(DateUtil.nowYearsBack(3)));
  setToDate(workExperience?.toDate ? moment(workExperience?.toDate).format('YYYY-MM-DD') : DateUtil.formatInputDate(DateUtil.now()));
  setDescription(workExperience?.description || '');
}