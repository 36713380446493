export const ImageSources = {
  Edit: {
    url: 'assets/images/edit.png',
    name: 'edit'
  },
  DealsActive: {
    url: 'assets/images/deals_active.png',
    name: 'deals active'
  },
  DealsInactive: {
    url: 'assets/images/deals_inactive.png',
    name: 'deals inactive'
  },
  EventsActive: {
    url: 'assets/images/events_active.png',
    name: 'events active'
  },
  EventsInactive: {
    url: 'assets/images/events_inactive.png',
    name: 'events inactive'
  },
  JobsActive: {
    url: 'assets/images/jobs_active.png',
    name: 'jobs active'
  },
  JobsEducation: {
    url: 'assets/images/jobs_education.png',
    name: 'education'
  },
  JobsInactive: {
    url: 'assets/images/jobs_inactive.png',
    name: 'jobs inactive'
  },
  JobsRelevant: {
    url: 'assets/images/jobs_relevant.png',
    name: 'relevant'
  },
  JobsWork: {
    url: 'assets/images/jobs_work.png',
    name: 'work'
  },
  LogoBlue: {
    url: 'assets/images/logo_blue.png',
    name: 'logo'
  },
  LogoNameWhite: {
    url: 'assets/images/logo_name_white.png',
    name: 'logo'
  },
  NameBlue: {
    url: 'assets/images/name_blue.png',
    name: 'logo name'
  },
  NewsActive: {
    url: 'assets/images/news_active.png',
    name: 'news active'
  },
  NewsInactive: {
    url: 'assets/images/news_inactive.png',
    name: 'news inactive'
  },
  ProfileActive: {
    url: 'assets/images/profile_active.png',
    name: 'profile active'
  },
  ProfileInactive: {
    url: 'assets/images/profile_inactive.png',
    name: 'profile inactive'
  },
  Trash: {
    url: 'assets/images/trash.png',
    name: 'trash'
  },
};