import {useState, useRef} from 'react';
// Styles
import './Swipe.scss';

interface PropsInterface {
  swipeText: string;
  swipedText: string;
  onSwiped: () => void;
}

const SwipeButton = (props: PropsInterface) => {
  const container = useRef<HTMLDivElement>(null),
    slider= useRef<HTMLDivElement>(null),
    [swiped, setSwiped] = useState(false);

  let swiping = false,
    startX = 0,
    sliderLeft = 0;

  const startDrag = (e: any, isTouchDevice: boolean) => {
    if (!swiped) {
      swiping = true;
      if (isTouchDevice) {
        startX = e.touches[0].clientX;
      } else {
        startX = e.clientX;
      }
    }
  };

  const onDrag = (e: any, isTouchDevice: boolean) => {
    if (!swiped) {
      const containerWidth = container.current?.clientWidth ? container.current.clientWidth : 100 - 50;
      if (swiping) {
        if(isTouchDevice) {
          sliderLeft = Math.min(Math.max(0, e.touches[0].clientX - startX), containerWidth);
        } else {
          sliderLeft = Math.min(Math.max(0, e.clientX - startX),containerWidth);
        }
        if(sliderLeft > containerWidth * 0.9) {
          sliderLeft = containerWidth;
          if (container.current) container.current.style.width = container.current?.clientWidth + 'px';
          setSwiped(true);
          props.onSwiped();
        }
        updateSliderStyle();
      }
    }
  }

  const stopDrag = () => {
    if (!swiped) {
      if (swiping) {
        swiping = false;
        sliderLeft = 0;
        updateSliderStyle();
      }
    }
  }

  const updateSliderStyle = () => {
    if (!swiped && slider.current) slider.current.style.left = (sliderLeft + 50)+'px';
  }

  return (
    <div className='swipe-button-wrapper'>
      <div className={'swipe-button-background-locked ' + (swiped ? 'swipe-button-background-unlocked' : '')} ref={container}>
        <div className='swipe-slider-wrapper' 
          ref={slider} 
          onMouseDown={(e) => startDrag(e, false)} 
          onTouchStart={(e) => startDrag(e, true)}
          onMouseMove={(e) => onDrag(e, false)}
          onTouchMoveCapture={(e) => onDrag(e, true)}
          onMouseUp={() => stopDrag()}
          onTouchEnd={() => stopDrag()}
          >
          <span className='swipe-slider-swipe-text'>{swiped ? props.swipedText : props.swipeText}</span>
          <span className='swipe-slider-arrow'></span>
          <span className='swipe-slider-circle'></span>
        </div>
        <div className='swipe-slider-text'>{swiped ? props.swipedText : props.swipeText}</div>
      </div>
    </div>
  );
};

export default SwipeButton;