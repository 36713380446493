import {useRef, useState} from 'react';
// @ts-ignore
import LoadingOverlay from 'react-loading-overlay';
import Swal, {SweetAlertIcon} from 'sweetalert2';
import { browserName, browserVersion, osName } from "react-device-detect";

// Components
import EducationForm from './Form';
import { validateInputs } from '../../Common/Form/Inputs';

// Config
import ENV from '../../../config/env.json';

// Context
import { updateUser, useAuthStore } from '../../../context/Auth';
import { useTranslationStore } from '../../../context/Translation';

// Enums
import { LanguageResource } from '../../../enums/Languages';
import { SwalIcons, SwalTitle } from '../../../enums/Swal';

// Interfaces
import { InputInterface } from '../../../interfaces/Input';

interface PropsInterface {
  disableSchool?: boolean;
  disableFaculty?: boolean;
  disableFieldOfStudy?: boolean;
  disableCity?: boolean;
  disableYearOfStudy?: boolean;
  label: string;
  proceedAction?: () => void;
  isLoading: boolean;
  setIsLoading: (arg0: boolean) => void;
}

const Education = (props: PropsInterface) => {
  const [authState, authDispatch] = useAuthStore(),
    [t] = useTranslationStore(),
    educationFormRef = useRef<HTMLFormElement>(null),
    [loadingSchools, setLoadingSchools] = useState<boolean>(true);
  let isSaving = false;
  
  const handleSubmit = (inputs: (InputInterface|InputInterface[])[], data: any) => {
    if (!isSaving) {
      isSaving = true;

      if (validateInputs(inputs, t)) {
        updateUser(
          authDispatch,
          {
            token: authState.token,
            body: {
              _id: authState.id,
              education: {
                school: parseInt(data.school, 10),
                faculty: parseInt(data.faculty, 10),
                fieldOfStudy: parseInt(data.fieldOfStudy, 10),
                city: parseInt(data.city, 10),
                yearOfStudy: parseInt(data.yearOfStudy, 10),
                typeOfStudy: parseInt(data.typeOfStudy, 10),
              },
              tracking: {
                platform: 'web',
                model: browserName,
                brand: osName,
                systemVersion: browserVersion,
                appVersion: ENV.WEB_VERSION
              },
            },
          },
          (err: any) => {
            if (err) {
              isSaving = false;
              Swal.fire({
                icon: SwalIcons.Error as SweetAlertIcon,
                title: t(SwalTitle.Error),
                text: err,
              });
            } else {
              isSaving = false;
              Swal.fire({
                icon: SwalIcons.Success as SweetAlertIcon,
                title: t(SwalTitle.Success),
                text: t(LanguageResource.Common + ':profile_updated'),
              });
              if (props.proceedAction) {
                props.proceedAction();
              }
            }
          },
        );
      } else {
        isSaving = false;
      }
    }
  };

  return (
    <LoadingOverlay
      active={loadingSchools}
      spinner
    >   
      <EducationForm
        forwardedRef={educationFormRef}
        setIsLoading={props.setIsLoading}
        loadingSchools={loadingSchools}
        setLoadingSchools={setLoadingSchools}
        disableSchool={props.disableSchool}
        disableFaculty={props.disableFaculty}
        disableFieldOfStudy={props.disableFieldOfStudy}
        disableCity={props.disableCity}
        disableYearOfStudy={props.disableYearOfStudy}
        onSubmit={(inputs, data) => handleSubmit(inputs, data)}
        showLabels={false}
      />
        <div className='primary-button-wrapper' onClick={() => {
          if (educationFormRef.current) {
            const form = educationFormRef.current;
            if (form) {
              if (typeof form.requestSubmit === 'function') {
                form.requestSubmit();
              } else {
                const event = new Event('submit', {
                  'bubbles'    : true,
                  'cancelable' : true 
                });
                form.dispatchEvent(event);
              }
            }
          }
        }}>
        <p className='primary-button-text'>{props.label}</p>
      </div>
    </LoadingOverlay>
  );
};

export default Education;
