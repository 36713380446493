import { useState, useEffect } from 'react';
import { Styles } from '../../../../enums/App';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    const handleResize = () => {
      const newWindowDimensions = getWindowDimensions();
      if (
        (windowDimensions.width > Styles.MobileWidth && newWindowDimensions.width < Styles.MobileWidth) ||
        (windowDimensions.width < Styles.MobileWidth && newWindowDimensions.width > Styles.MobileWidth) ||
        (windowDimensions.width > Styles.TabletWidth && newWindowDimensions.width < Styles.TabletWidth) ||
        (windowDimensions.width < Styles.TabletWidth && newWindowDimensions.width > Styles.TabletWidth)
      ) {
        setWindowDimensions(newWindowDimensions);
      }
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [windowDimensions.width]);

  return windowDimensions;
}


export default useWindowDimensions