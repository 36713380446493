import {useState} from 'react';

// Components
import Form from '../index';
import {validateInputs} from '../Inputs/';
import StepsHeader from './Header';
import StepsSubHeader from './SubHeader';

// Context
import { useTranslationStore } from '../../../../context/Translation';

// Interfaces
import { StepsInterface } from '../../../../interfaces/Input';
import { LanguageResource } from '../../../../enums/Languages';

interface PropsInterface {
  title: string,
  steps: StepsInterface[],
  goBack: () => void,
  handleSubmit: () => void,
}

const StepsForm = (props: PropsInterface) => {
  const [step, setStep] = useState(0),
    [t] = useTranslationStore();

  let steps: StepsInterface[] = [];
  for (let index = 0; index < Object.keys(props.steps).length; index++) {
    const step = props.steps[index];
    if (step.condition || typeof step.condition === 'undefined') {
      steps.push(step);
    }
  }

  const goBack = () => {
    if (step === 0) {
      props.goBack();
    } else {
      setStep(step - 1);
    }
  }

  const handleSubmit = () => {
    const valid = validateInputs(steps[step].inputs, t);
    if (valid) {
      if (step === (props.steps.length - 1)) {
        props.handleSubmit();
      } else {
        setStep(step + 1);
      }
    }
  }

  return (
    <div className='create-wrapper'>
      <StepsHeader 
        title={props.title}
        step={step}
        amountOfSteps={steps.length}
      />
      <StepsSubHeader
        title={steps[step].title}
        goBack={goBack}
      />
      <Form 
        inputs={steps[step].inputs}
        onSubmit={handleSubmit}
      />
      <div className="primary-button-wrapper" onClick={() => handleSubmit()}>
        <p className="primary-button-text">
          {
            step === (props.steps.length - 1) ? t(LanguageResource.Common + ':save') : t(LanguageResource.Common + ':proceed_next_step')
          }
        </p>
      </div>
    </div>
  );
};

export default StepsForm;
