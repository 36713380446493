import Swal, {SweetAlertIcon} from 'sweetalert2';
import _ from 'lodash';

// Components
import Input from './Input/';

// Enums
import { InputTypes } from '../../../../enums/Inputs';
import { LanguageResource } from '../../../../enums/Languages';
import { SwalIcons, SwalTitle } from '../../../../enums/Swal';

// Interfaces
import { InputInterface } from '../../../../interfaces/Input';

// Styles
import './index.scss';

// Utils
import JSUtil from '../../../../utils/JSUtil';

export const validateInputs = (inputs: (InputInterface|InputInterface[])[], t: any, ignoreValidate: boolean = false) => {
  let valid = true;
  if (ignoreValidate) return valid;
  inputs.forEach(input => {
    if (Array.isArray(input)) {
      valid = valid && validateInputs(input, t, ignoreValidate);
    } else {
      if (!JSUtil.isEmpty(input.condition) && !input.condition) return;
      switch (input.type) {
        case InputTypes.Checkbox:
          if (input.required && !input.value) {
            valid = false;
            Swal.fire({
              icon: SwalIcons.Error as SweetAlertIcon,
              title: t(SwalTitle.Error),
              text: t(LanguageResource.Validation + ':select_' + input.name),
            });
          }
        break;
        case InputTypes.File:
          if (input.required && !input.value) {
            valid = false;
            Swal.fire({
              icon: SwalIcons.Error as SweetAlertIcon,
              title: t(SwalTitle.Error),
              text: t(LanguageResource.Validation + ':empty_' + input.name),
            });
          }
        break;
        case InputTypes.Email:
          if (!JSUtil.isValidEmail(input.value)) {
            valid = false;
            Swal.fire({
              icon: SwalIcons.Error as SweetAlertIcon,
              title: t(SwalTitle.Error),
              text: t(LanguageResource.Validation + ':invalid_' + input.name),
            });
          }
        break;
        case InputTypes.PasswordConfirm:
          if (input.type === InputTypes.PasswordConfirm) {
            const password = _.find(inputs, {type: InputTypes.Password}) as InputInterface;
            if (password?.value !== input.value) {
              valid = false;
              Swal.fire({
                icon: SwalIcons.Error as SweetAlertIcon,
                title: t(SwalTitle.Error),
                text: t(LanguageResource.Validation + ':not_equal_' + password?.name + '_' + input.name),
              });
            }
          }
        break;
        case InputTypes.Phone:
          if (!JSUtil.isEmpty(input.value) && !JSUtil.isValidPhone(input.value)) {
            valid = false;
            Swal.fire({
              icon: SwalIcons.Error as SweetAlertIcon,
              title: t(SwalTitle.Error),
              text: t(LanguageResource.Validation + ':invalid_' + input.name),
            });
          }
        break;
        default:
          if (input.required && JSUtil.isEmpty(input.value)) {
            valid = false;
            Swal.fire({
              icon: SwalIcons.Error as SweetAlertIcon,
              title: t(SwalTitle.Error),
              text: t(LanguageResource.Validation + ':empty_' + input.name),
            });
          }
        break;
      } 
    }
  });
  return valid;
}

export const renderInputs = (inputs: (InputInterface|InputInterface[])[]) => {
  return inputs.map((input, index) => {
    if (!Array.isArray(input) && !JSUtil.isEmpty(input.condition)) {
      if (input.condition) return <Input key={index} input={input} />
    } else if (Array.isArray(input)) {
      return <div className="row-input-wrapper" key={index}>
        {renderInputs(input)}
      </div>
    } else {
      return <Input key={index} input={input} />
    }

    return null;
  });
}