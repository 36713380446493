// Components
import Input from '../../Form/Inputs/Input';

// Enums
import { InputTypes } from '../../../../enums/Inputs';

// Styles
import './Distance.scss';

interface PropsInterface {
  distance: number;
  setDistance: (arg0: number) => void;
}

const SearchFilterDistance = (props: PropsInterface) => {
  return (
    <div className='search-filter-slide-wrapper'>
      <Input
        input={{
          name: 'distance',
          type: InputTypes.Range,
          value: props.distance,
          onChange: (val: number) => props.setDistance(val),
          label: 'km'
        }}
      />
    </div>
  );
};

export default SearchFilterDistance;
