import {RefObject, useEffect, useState} from 'react';
import Swal, {SweetAlertIcon} from 'sweetalert2';

// Apis
import {getSchoolsApi, getStudiesTypesApi} from '../../../api/schools';

// Components
import Form from '../../Common/Form/index';

// Context
import { useAuthStore } from '../../../context/Auth';
import { useTranslationStore } from '../../../context/Translation';

// Enums
import { DropdownOptions, InputTypes } from '../../../enums/Inputs';
import { StudiesTypes } from '../../../enums/Education';
import { LanguageResource } from '../../../enums/Languages';
import { SwalIcons, SwalTitle } from '../../../enums/Swal';

// Interfaces
import {
  FacultyInterface,
  SchoolInterface,
  StudiesYearInterface,
} from '../../../interfaces/Education';
import { InputInterface } from '../../../interfaces/Input';

// Utils
import JSUtil from '../../../utils/JSUtil';

interface PropsInterface {
  disableSchool?: boolean;
  disableFaculty?: boolean;
  disableFieldOfStudy?: boolean;
  disableCity?: boolean;
  disableYearOfStudy?: boolean;
  forwardedRef: RefObject<HTMLFormElement>;
  showLabels: boolean;
  onSubmit: (arg0: (InputInterface|InputInterface[])[], arg1: any) => void;
  setIsLoading: (arg0: boolean) => void;
  loadingSchools: boolean;
  setLoadingSchools: (arg0: boolean) => void;
}

const EducationForm = (props: PropsInterface) => {
  const [authState] = useAuthStore(),
    [t] = useTranslationStore(),
    [city, setCity] = useState<string>(
      authState.education.city ? authState.education.city._id : null,
    ),
    [school, setSchool] = useState<string>(
      authState.education.school ? authState.education.school._id : null,
    ),
    [schools, setSchools] = useState<SchoolInterface[]>([]),
    [faculty, setFaculty] = useState<string>(
      authState.education.faculty ? authState.education.faculty._id : null,
    ),
    [fieldOfStudy, setFieldOfStudy] = useState<string>(
      authState.education.fieldOfStudy
        ? authState.education.fieldOfStudy._id
        : null,
    ),
    [yearOfStudy, setYearOfStudy] = useState<string>(
      authState.education.yearOfStudy
        ? authState.education.yearOfStudy._id
        : null,
    ),
    [typeOfStudy, setTypeOfStudy] = useState<string>(
      authState.education.typeOfStudy
        ? authState.education.typeOfStudy._id
        : null,
    ),
    [studiesYears, setStudiesYears] = useState<StudiesYearInterface[]>([]);

    useEffect(() => {
      loadSchoolsInfo();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
  const loadSchoolsInfo = () => {
    getSchoolsApi(
      (
        err: any,
        res: {schools: SchoolInterface[]; studiesYears: StudiesYearInterface[]},
      ) => {
        if (err) {
          Swal.fire({
            icon: SwalIcons.Error as SweetAlertIcon,
            title: t(SwalTitle.Error),
            text: err,
          });
        } else {
          setSchools(res.schools);
          setStudiesYears(res.studiesYears);
        }
        props.setLoadingSchools(false);
      },
    );
  };

  const renderFaculties = () => {
    if (!JSUtil.isEmpty(school)) {
      const filteredSchool = schools.find(s => s._id == school as string);
      if (!JSUtil.isEmpty(filteredSchool) && filteredSchool) {
        return filteredSchool.faculties.map((faculty: FacultyInterface) => {
          return {
            name: faculty.name,
            _id: faculty._id,
          };
        });
      }
    }

    return [];
  };

  const renderCities = () => {
    if (!JSUtil.isEmpty(school) && !JSUtil.isEmpty(faculty)) {
      const filteredSchool = schools.find(s => s._id == school);
      if (!JSUtil.isEmpty(filteredSchool) && filteredSchool) {
        const filteredFaculty = filteredSchool.faculties.find(f => f._id == faculty);
        if (!JSUtil.isEmpty(filteredFaculty) && filteredFaculty) {
          return filteredFaculty.cities.map(city => {
            return {
              name: city.name,
              _id: city._id,
            };
          });
        }
      }
    }

    return [];
  };
  const renderFieldOfStudies = () => {
    if (!JSUtil.isEmpty(school) && !JSUtil.isEmpty(faculty)) {
      const filteredSchool = schools.find(s => s._id == school);
      if (!JSUtil.isEmpty(filteredSchool) && filteredSchool) {
        const filteredFaculty = filteredSchool.faculties.find(f => f._id == faculty);
        if (!JSUtil.isEmpty(filteredFaculty) && filteredFaculty) {
          return filteredFaculty.studies.map(study => {
            return {
              name: study.name,
              _id: study._id,
            };
          });
        }
      }
    }

    return [];
  };
  
  const inputs: InputInterface[] = [
    {
      name: "typeOfStudy",
      type: InputTypes.Dropdown,
      options: {
        type: DropdownOptions.Api,
        dataGetter: getStudiesTypesApi
      },
      placeholder: true,
      onChange: (val: string) => setTypeOfStudy(val),
      value: typeOfStudy,
      label: props.showLabels ? t(LanguageResource.Input + ':typeOfStudy') : '',
      required: true,
      setLoading: () => props.setIsLoading
    },
    {
      name: "school",
      type: InputTypes.Dropdown,
      options: {
        type: DropdownOptions.Options,
        dataGetter: schools,
      },
      placeholder: true,
      onChange: (val: string) => setSchool(val),
      value: school,
      label: props.showLabels ? t(LanguageResource.Input + ':school') : '',
      required: true,
      condition: typeOfStudy == StudiesTypes.Default && !props.disableSchool,
      setLoading: () => props.setIsLoading
    },
    {
      name: "faculty",
      type: InputTypes.Dropdown,
      options: {
        type: DropdownOptions.Options,
        dataGetter: renderFaculties(),
      },
      onChange: (val: string) => setFaculty(val),
      value: faculty,
      label: props.showLabels ? t(LanguageResource.Input + ':faculty') : '',
      required: true,
      condition: typeOfStudy == StudiesTypes.Default && !props.disableFaculty,
      setLoading: () => props.setIsLoading
    },
    {
      name: "fieldOfStudy",
      type: InputTypes.Dropdown,
      options: {
        type: DropdownOptions.Options,
        dataGetter: renderFieldOfStudies(),
      },
      onChange: (val: string) => setFieldOfStudy(val),
      value: fieldOfStudy,
      label: props.showLabels ? t(LanguageResource.Input + ':fieldOfStudy') : '',
      required: true,
      condition: typeOfStudy == StudiesTypes.Default && !props.disableFieldOfStudy,
      setLoading: () => props.setIsLoading
    },
    {
      name: "city",
      type: InputTypes.Dropdown,
      options: {
        type: DropdownOptions.Options,
        dataGetter: renderCities(),
      },
      onChange: (val: string) => setCity(val),
      value: city,
      label: props.showLabels ? t(LanguageResource.Input + ':city') : '',
      required: true,
      condition: typeOfStudy == StudiesTypes.Default && !props.disableCity,
      setLoading: () => props.setIsLoading
    },
    {
      name: "yearOfStudy",
      type: InputTypes.Dropdown,
      options: {
        type: DropdownOptions.Options,
        dataGetter: studiesYears,
      },
      onChange: (val: string) => setYearOfStudy(val),
      value: yearOfStudy,
      label: props.showLabels ? t(LanguageResource.Input + ':yearOfStudy') : '',
      required: true,
      condition: typeOfStudy == StudiesTypes.Default && !props.disableYearOfStudy,
      setLoading: () => props.setIsLoading
    },
  ];

  return (
    <Form 
      forwardedRef={props.forwardedRef}
      inputs={inputs}
      onSubmit={() => props.onSubmit(
        inputs,
        {
          school,
          faculty,
          city,
          fieldOfStudy,
          yearOfStudy,
          typeOfStudy
        }
      )}
    />
  );
};

export default EducationForm;
