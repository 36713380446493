// Context
import { useTranslationStore } from '../../../context/Translation';

// Enums
import { DealDiscountTypes } from '../../../enums/Deals';
import { LanguageResource } from "../../../enums/Languages";

// Interfaces
import { DealInterface } from '../../../interfaces/Deal';

interface PropsInterface {
  deal: DealInterface;
};

const DealType = (props: PropsInterface) => {
  const [t] = useTranslationStore();
  const renderDealType = () => {
    switch (props.deal.type.name) {
      case DealDiscountTypes.BuyGetFree:
        return (
          <>
            <p className='deal-coupon-text-get text-weight-bold'>{t(LanguageResource.Deals + ':coupon_buy')} {props.deal.type.price}</p>
            <p className='deal-coupon-text'>{t(LanguageResource.Deals + ':coupon_get')} {props.deal.type.price} {t(LanguageResource.Deals + ':coupon_free')}</p>
          </>
        );
      case DealDiscountTypes.Free:
        return (
          <>
            <p className='deal-coupon-text'>{t(LanguageResource.Deals + ':coupon_free')}</p>
          </>
        );
        case DealDiscountTypes.Price:
          return (
            <>
              <p className='deal-coupon-text-get text-weight-bold'>{t(LanguageResource.Deals + ':coupon_get')}</p>
              <p className='deal-coupon-text'>€{props.deal.type.price} {t(LanguageResource.Deals + ':coupon_off')}</p>
            </>
          );
      case DealDiscountTypes.Procent:
        return (
          <>
            <p className='deal-coupon-text-get text-weight-bold'>{t(LanguageResource.Deals + ':coupon_get')}</p>
            <p className='deal-coupon-text'>{props.deal.type.percent}% {t(LanguageResource.Deals + ':coupon_off')}</p>
          </>
        );
      case DealDiscountTypes.SpendFree:
        return (
          <>
            <p className='deal-coupon-text-get text-weight-bold'>{t(LanguageResource.Deals + ':coupon_spend')} €{props.deal.type.price}</p>
            <p className='deal-coupon-text'>{t(LanguageResource.Deals + ':coupon_free_item')}</p>
          </>
        );
      case DealDiscountTypes.SpendPrice:
        return (
          <>
            <p className='deal-coupon-text-get text-weight-bold'>{t(LanguageResource.Deals + ':coupon_spend')} €{props.deal.type.price}</p>
            <p className='deal-coupon-text'>{t(LanguageResource.Deals + ':coupon_get')} {props.deal.type.percent}% {t(LanguageResource.Deals + ':coupon_off')}</p>
          </>
        );
      case DealDiscountTypes.SpendProcent:
        return (
          <>
            <p className='deal-coupon-text-get text-weight-bold'>{t(LanguageResource.Deals + ':coupon_spend')} {props.deal.type.percent}%</p>
            <p className='deal-coupon-text'>{t(LanguageResource.Deals + ':coupon_get')} {props.deal.type.price} {t(LanguageResource.Deals + ':coupon_off')}</p>
          </>
        );
    }
  }

  return (
    <div className='deal-coupon-wrapper row-wrapper'>
      {renderDealType()}
    </div>
  );
};

export default DealType;