// Context
import { useTranslationStore } from '../../../../context/Translation';

// Enums
import { LanguageResource } from "../../../../enums/Languages";

interface PropsInterface {
  title: string,
  step: number,
  amountOfSteps: number,
}

const StepsHeader = (props: PropsInterface) => {
  const [t] = useTranslationStore();
  
  return (
    <div className="row-spread-wrapper">
      <p className="text-weight-bold text-size-large">{t(props.title)}</p>
      <p className="text-size-large">
        <span className="text-color-black text-weight-bold">{props.step + 1} </span> 
        <span className="text-color-gray-dark">{t(LanguageResource.Common + ':of')} </span> 
        <span className="text-color-gray-dark text-weight-bold">{props.amountOfSteps}</span>
      </p> 
    </div>
  );
}

export default StepsHeader;
