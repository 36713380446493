import { useLocation, NavigateFunction } from 'react-router-dom';

// Context
import {logoutUser, useAuthStore} from '../../../../context/Auth';
import { useTranslationStore } from '../../../../context/Translation';

// Enums
import { ImageSources } from '../../../../enums/Images';
import { RoutesLinks } from '../../../../enums/Routes';
import { LanguageResource } from '../../../../enums/Languages';

interface PropsInterface {
  navigate: NavigateFunction
}

const Navigation = (props: PropsInterface) => {
  const [authState, authDispatch] = useAuthStore(),
    [t] = useTranslationStore(),
    currentPath = useLocation().pathname;

  const onPressLogoutButton = () => {
    logoutUser(authDispatch);
  };

  const onPressLoginButton = () => {
    props.navigate(RoutesLinks.Login.Link);
  }

  return (
    <div className="navigation-wrapper">
      <div className="row-spread-wrapper-center">
        <div className="row-wrapper">
          <img src={require('../../../../' + ImageSources.LogoBlue.url)} alt={ImageSources.LogoBlue.name} width='25px'/>
          <img src={require('../../../../' + ImageSources.NameBlue.url)} alt={ImageSources.NameBlue.name} width='75px' height='40px'/>
        </div>
        {
          authState.loggedIn
          ?
          <button className='underline-link text-color-secondary text-size-default text-weight-bold' onClick={() => onPressLogoutButton()}>
            {t(LanguageResource.Common + ':logout')}
          </button>
          :
          <button className='underline-link text-color-secondary text-size-default text-weight-bold' onClick={() => onPressLoginButton()}>
            {t(LanguageResource.Common + ':login')}
          </button>
        }
      </div>
      <div className="column-wrapper">
        <p
          className={`no-underline-link text-color-mainColor text-size-large text-weight-bold ${[RoutesLinks.Jobs.Link].includes(currentPath) || currentPath.includes('/jobs') ? 'active-link' : ''}`}
          onClick={() => props.navigate(RoutesLinks.Jobs.Link)}
        >
          {
            [RoutesLinks.Jobs.Link].includes(currentPath) || currentPath.includes('/jobs')
            ? <img src={require('../../../../' + ImageSources.JobsActive.url)} alt={ImageSources.JobsActive.name} width='25px' height='25px'/>
            : <img src={require('../../../../' + ImageSources.JobsInactive.url)} alt={ImageSources.JobsInactive.name} width='25px' height='25px'/>
          }
          {t(LanguageResource.Common + ':' + RoutesLinks.Jobs.Title)}
        </p>
        <p
          className={`no-underline-link text-color-mainColor text-size-large text-weight-bold ${currentPath.includes(RoutesLinks.Deals.Link) ? 'active-link' : ''}`}
          onClick={() => props.navigate(RoutesLinks.Deals.Link)}
        >
          {
            currentPath.includes(RoutesLinks.Deals.Link)
            ? <img src={require('../../../../' + ImageSources.DealsActive.url)} alt={ImageSources.DealsActive.name} width='25px' height='25px'/>
            : <img src={require('../../../../' + ImageSources.DealsInactive.url)} alt={ImageSources.DealsInactive.name} width='25px' height='25px'/>
          }
          {t(LanguageResource.Common + ':' + RoutesLinks.Deals.Title)}
        </p>
        <p
          className={`no-underline-link text-color-mainColor text-size-large text-weight-bold ${currentPath.includes(RoutesLinks.Events.Link) ? 'active-link' : ''}`}
          onClick={() => props.navigate(RoutesLinks.Events.Link)}
        >
          {
            currentPath.includes(RoutesLinks.Events.Link)
            ? <img src={require('../../../../' + ImageSources.EventsActive.url)} alt={ImageSources.EventsActive.name} width='25px' height='25px'/>
            : <img src={require('../../../../' + ImageSources.EventsInactive.url)} alt={ImageSources.EventsInactive.name} width='25px' height='25px'/>
          }
          {t(LanguageResource.Common + ':' + RoutesLinks.Events.Title)}
        </p>
        <p
          className={`no-underline-link text-color-mainColor text-size-large text-weight-bold ${currentPath.includes(RoutesLinks.News.Link) ? 'active-link' : ''}`}
          onClick={() => props.navigate(RoutesLinks.News.Link)}
        >
          {
            currentPath.includes(RoutesLinks.News.Link)
            ? <img src={require('../../../../' + ImageSources.NewsActive.url)} alt={ImageSources.NewsActive.name} width='25px' height='25px'/>
            : <img src={require('../../../../' + ImageSources.NewsInactive.url)} alt={ImageSources.NewsInactive.name} width='25px' height='25px'/>
          }
          {t(LanguageResource.Common + ':' + RoutesLinks.News.Title)}
        </p>
        {
          authState.loggedIn
          ?
          <p
            className={`no-underline-link text-color-mainColor text-size-large text-weight-bold ${[
              RoutesLinks.ProfileAccount.Link,
              RoutesLinks.ProfileCV.Link,
              RoutesLinks.ProfileJobs.Link,
              RoutesLinks.ProfileJobsDetail.Link,
              RoutesLinks.ProfileDeals.Link,
              RoutesLinks.ProfileDealsDetail.Link,
              RoutesLinks.ProfileEvents.Link,
              RoutesLinks.ProfileEventsDetail.Link,
              RoutesLinks.ProfileNews.Link,
              RoutesLinks.ProfileNewsDetail.Link
            ].includes(currentPath) || 
            currentPath.includes(RoutesLinks.ProfileJobs.Link) ||
            currentPath.includes(RoutesLinks.ProfileDeals.Link) ||
            currentPath.includes(RoutesLinks.ProfileEvents.Link) ||
            currentPath.includes(RoutesLinks.ProfileNews.Link) ? 'active-link' : ''}`}
            onClick={() => props.navigate(RoutesLinks.ProfileAccount.Link)}
          >
            {
              [
                RoutesLinks.ProfileAccount.Link,
                RoutesLinks.ProfileCV.Link,
                RoutesLinks.ProfileJobs.Link,
                RoutesLinks.ProfileJobsDetail.Link,
                RoutesLinks.ProfileDeals.Link,
                RoutesLinks.ProfileDealsDetail.Link,
                RoutesLinks.ProfileEvents.Link,
                RoutesLinks.ProfileEventsDetail.Link,
                RoutesLinks.ProfileNews.Link,
                RoutesLinks.ProfileNewsDetail.Link
              ].includes(currentPath) || 
              currentPath.includes(RoutesLinks.ProfileJobs.Link) ||
              currentPath.includes(RoutesLinks.ProfileDeals.Link) ||
              currentPath.includes(RoutesLinks.ProfileEvents.Link) ||
              currentPath.includes(RoutesLinks.ProfileNews.Link)
              ? <img src={require('../../../../' + ImageSources.ProfileActive.url)} alt={ImageSources.ProfileActive.name} width='25px' height='25px'/>
              : <img src={require('../../../../' + ImageSources.ProfileInactive.url)} alt={ImageSources.ProfileInactive.name} width='25px' height='25px'/>
            }
            {t(LanguageResource.Common + ':' + RoutesLinks.ProfileAccount.Title)}
          </p>
          : null
        }
        {
          [
            RoutesLinks.ProfileAccount.Link,
            RoutesLinks.ProfileCV.Link,
            RoutesLinks.ProfileJobs.Link,
            RoutesLinks.ProfileJobsDetail.Link,
            RoutesLinks.ProfileDeals.Link,
            RoutesLinks.ProfileDealsDetail.Link,
            RoutesLinks.ProfileEvents.Link,
            RoutesLinks.ProfileEventsDetail.Link,
            RoutesLinks.ProfileNews.Link,
            RoutesLinks.ProfileNewsDetail.Link
          ].includes(currentPath) || 
          currentPath.includes(RoutesLinks.ProfileJobs.Link) ||
          currentPath.includes(RoutesLinks.ProfileDeals.Link) ||
          currentPath.includes(RoutesLinks.ProfileEvents.Link) ||
          currentPath.includes(RoutesLinks.ProfileNews.Link)
          ? <div className='column-wrapper submenu-wrapper'>
            <p
              className={`no-underline-link text-color-mainColor text-size-large text-weight-bold ${[RoutesLinks.ProfileAccount.Link].includes(currentPath) ? 'active-submenu-link' : ''}`}
              onClick={() => props.navigate(RoutesLinks.ProfileAccount.Link)}
            >
              {t(LanguageResource.Profile + ':' + RoutesLinks.ProfileAccount.Params.State)}
            </p>
            <p
              className={`no-underline-link text-color-mainColor text-size-large text-weight-bold ${[RoutesLinks.ProfileCV.Link].includes(currentPath) ? 'active-submenu-link' : ''}`}
              onClick={() => props.navigate(RoutesLinks.ProfileCV.Link)}
            >
              {t(LanguageResource.Profile + ':' + RoutesLinks.ProfileCV.Params.State)}
            </p>
            <p
              className={`no-underline-link text-color-mainColor text-size-large text-weight-bold ${[RoutesLinks.ProfileJobs.Link, RoutesLinks.ProfileJobsDetail.Link].includes(currentPath) || currentPath.includes(RoutesLinks.ProfileJobs.Link) ? 'active-submenu-link' : ''}`}
              onClick={() => props.navigate(RoutesLinks.ProfileJobs.Link)}
            >
              {t(LanguageResource.Profile + ':' + RoutesLinks.ProfileJobs.Params.State)}
            </p>
            <p
              className={`no-underline-link text-color-mainColor text-size-large text-weight-bold ${[RoutesLinks.ProfileDeals.Link, RoutesLinks.ProfileDealsDetail.Link].includes(currentPath) || currentPath.includes(RoutesLinks.ProfileDeals.Link) ? 'active-submenu-link' : ''}`}
              onClick={() => props.navigate(RoutesLinks.ProfileDeals.Link)}
            >
              {t(LanguageResource.Profile + ':' + RoutesLinks.ProfileDeals.Params.State)}
            </p>
            <p
              className={`no-underline-link text-color-mainColor text-size-large text-weight-bold ${[RoutesLinks.ProfileEvents.Link, RoutesLinks.ProfileEventsDetail.Link].includes(currentPath) || currentPath.includes(RoutesLinks.ProfileEvents.Link) ? 'active-submenu-link' : ''}`}
              onClick={() => props.navigate(RoutesLinks.ProfileEvents.Link)}
            >
              {t(LanguageResource.Profile + ':' + RoutesLinks.ProfileEvents.Params.State)}
            </p>
            <p
              className={`no-underline-link text-color-mainColor text-size-large text-weight-bold ${[RoutesLinks.ProfileNews.Link, RoutesLinks.ProfileNewsDetail.Link].includes(currentPath) || currentPath.includes(RoutesLinks.ProfileNews.Link) ? 'active-submenu-link' : ''}`}
              onClick={() => props.navigate(RoutesLinks.ProfileNews.Link)}
            >
              {t(LanguageResource.Profile + ':' + RoutesLinks.ProfileNews.Params.State)}
            </p>
          </div>
          : null
        }
      </div>
    </div>
  );
}

export default Navigation;