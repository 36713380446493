import { useState } from 'react';

// Components
import SearchFilter from '../../Common/Search/Filter/';
import SearchFilterCategory from '../../Common/Search/Filter/Category';
import SearchFilterDate from '../../Common/Search/Filter/Date';
import SearchFilterDistance from '../../Common/Search/Filter/Distance';
import SearchFilterLocation from '../../Common/Search/Filter/Location';
import SearchFilterOptionButton from '../../Common/Search/Filter/Options/Button';
import SearchFilterOptionWrapper from '../../Common/Search/Filter/Options/Wrapper';
import SearchFilterSortBy from '../../Common/Search/Filter/SortBy';
import SearchFilterTitle from '../../Common/Search/Filter/Title';

// Context
import { useTranslationStore } from '../../../context/Translation';

// Enums
import { SortBy } from '../../../enums/Filter';
import { LanguageResource } from '../../../enums/Languages';
import { DealTypes } from '../../../enums/Deals';

// Interfaces
import { CategoryInterface } from '../../../interfaces/Category';
import { LocationInterface } from '../../../interfaces/Location';

interface PropsInterface {
  cleanSearch: () => void;
  location: LocationInterface;
  setLocation: (arg0: LocationInterface) => void;
  locations: LocationInterface[];
  distance: number;
  setDistance: (arg0: number) => void;
  categories: CategoryInterface[];
  filteredCategories: string[];
  onPressCategory: (arg0: string) => void;
  dealType: string;
  setDealType: (arg0: string) => void;
  setFromDate: (arg0: string) => void;
  setDateFiltered: (arg0: boolean) => void;
  setToDate: (arg0: string) => void;
  dateFiltered: boolean;
  fromDate: string;
  toDate: string;
  sortBy: string;
  setSortBy: (arg0: string) => any;
  filterActive: boolean;
  amount: number;
}

const FilterSideBar = (props: PropsInterface) => {
  const [t] = useTranslationStore(),
   [showLocation, setShowLocation] = useState(true),
   [showDistance, setShowDistance] = useState(true),
   [showCategory, setShowCategory] = useState(true),
   [showDealType, setShowDealType] = useState(true),
   [showDate, setShowDate] = useState(true),
   [showSortBy, setShowSortBy] = useState(true);

  const onPressClean = () => {
    props.cleanSearch();
  };

  const onPressDealType = (type: string) => {
    if (props.dealType === type) {
      props.setDealType('');
    } else {
      props.setDealType(type);
    }
  };

  const renderDealsType = (type: string) => {
    return (
      <SearchFilterOptionButton
        key={type}
        data={type}
        value={props.dealType}
        onPressFilter={() => onPressDealType(type)}
        label={t(LanguageResource.Deals + ':type_' + type)}
      />
    );
  };

  const setFromDate = (date: string) => {
    props.setFromDate(date);
    props.setDateFiltered(true);
  };

  const setToDate = (date: string) => {
    props.setToDate(date);
    props.setDateFiltered(true);
  };

  return (
    <SearchFilter
      filterActive={props.filterActive}
      amount={props.amount}
      onPressClean={onPressClean}
    >
      <div>
        <SearchFilterTitle
          title={t(LanguageResource.Common + ':location')}
          show={showLocation}
          onPressShow={() => setShowLocation(!showLocation)}
        />
        {
          showLocation
          ?
          <SearchFilterLocation
            location={props.location}
            setLocation={props.setLocation}
            locations={props.locations}
          />
          : null
        }
      </div>
      <SearchFilterTitle
        title={t(LanguageResource.Common + ':distance')}
        show={showDistance}
        onPressShow={() => setShowDistance(!showDistance)}
      />
      {
        showDistance
        ?
        <SearchFilterDistance
          distance={props.distance}
          setDistance={props.setDistance}
        />
        : null
      }
      <SearchFilterTitle 
        title={t(LanguageResource.Common + ':categories')}
        show={showCategory}
        onPressShow={() => setShowCategory(!showCategory)}
      />
      {
        showCategory
        ?
        <SearchFilterCategory
          categories={props.categories}
          filteredCategories={props.filteredCategories}
          onPressCategory={props.onPressCategory}
        />
        : null
      }
      <SearchFilterTitle 
        title={t(LanguageResource.Deals + ':types')}
        show={showDealType}
        onPressShow={() => setShowDealType(!showDealType)}
      />
      {
        showDealType
        ?
        <SearchFilterOptionWrapper>
          {renderDealsType(DealTypes.Online)}
          {renderDealsType(DealTypes.Offline)}
        </SearchFilterOptionWrapper>
        : null
      }
      <SearchFilterTitle 
        title={t(LanguageResource.Common + ':date')} 
        show={showDate}
        onPressShow={() => setShowDate(!showDate)}
      />
      {
        showDate
        ?
        <SearchFilterDate
          fromDate={props.fromDate}
          setFromDate={setFromDate}
          toDate={props.toDate}
          setToDate={setToDate}
        />
        : null
      }
      <SearchFilterTitle
        title={t(LanguageResource.Common + ':sort_by')}
        show={showSortBy}
        onPressShow={() => setShowSortBy(!showSortBy)}
      />
      {
        showSortBy
        ?
        <SearchFilterSortBy
          types={[SortBy.Distance, SortBy.New]}
          sortBy={props.sortBy}
          setSortBy={props.setSortBy}
        />
        : null
      }
    </SearchFilter>
  );
};

export default FilterSideBar;
