import { useState, useEffect } from 'react';
import { NavigateFunction, useParams, useLocation } from 'react-router-dom';
import Swal, {SweetAlertIcon} from 'sweetalert2';
import { browserName, browserVersion, osName } from "react-device-detect";

// Apis
import { consultationApi } from '../../../api/consultations';
import { getNewsApi } from '../../../api/news';

// Components
import UserLayout from '../../Common/Layout/User';
import NewsInfoCategory from '../../Common/Info/Categories';
import NewsInfoLogo from '../../Common/Info/Logo';
import NewsInfoSave from '../Info/Save';
import ShareButton from '../../Common/Buttons/Share';

// Config
import ENV from '../../../config/env.json';

// Context
import { useAuthStore } from '../../../context/Auth';
import { useTranslationStore } from '../../../context/Translation';

// Enums
import { CategoryTypes } from '../../../enums/Categories';
import { LanguageResource } from '../../../enums/Languages';
import { RoutesLinks } from '../../../enums/Routes';
import { SwalIcons, SwalTitle } from '../../../enums/Swal';
import { StorageParams } from '../../../enums/Storages';

// Interfaces
import { NewsInterface } from '../../../interfaces/News';

// Styles
import './index.scss';

// Utils
import JSUtil from '../../../utils/JSUtil';
import DetailTabsDescription from '../../Common/Detail/Tabs/Description';

interface PropsInterface {
  navigate: NavigateFunction,
}

const NewsDetailPage = (props: PropsInterface) => {
  const [authState, authDispatch] = useAuthStore(),
    navigationParams = useParams(),
    newsKey = navigationParams[RoutesLinks.NewsDetail.Params.NewsKey],
    [news, setNews] = useState<NewsInterface>(),
    [isLoading, setIsLoading] = useState<boolean>(true),
    [t] = useTranslationStore(),
    location = useLocation();

  useEffect(() => {
    loadNews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.loggedIn]);

  const loadNews = () => {
    getNewsApi(
      {
        token: authState.token,
        key: newsKey
      },
      (err: any, res: {amount: number; result: NewsInterface[]}) => {
        setIsLoading(false);
        if (err) {
          Swal.fire({
            icon: SwalIcons.Error as SweetAlertIcon,
            title: t(SwalTitle.Error),
            text: err,
          }).then(() => {
            props.navigate(RoutesLinks.News.Link);
          });
        } else if (!JSUtil.isEmpty(res.result[0])) {
          setNews(res.result[0]);
          addConsultation(res.result[0]);
        } else {
          Swal.fire({
            icon: SwalIcons.Error as SweetAlertIcon,
            title: t(SwalTitle.Error),
            text: t(LanguageResource.Common + ':information_not_available'),
          }).then(() => {
            props.navigate(RoutesLinks.News.Link);
          });
        }
      },
      authDispatch
    );
  };

  const addConsultation = (news: NewsInterface) => {
    consultationApi(
      {
        token: authState.token,
        body: {
          type: CategoryTypes.News,
          id: news._id,
          companyId: news.company.by,
          userId: authState.id || localStorage.getItem(StorageParams.UserId),
          track: {
            //@ts-ignore
            origin: location?.state?.origin || '',
            //@ts-ignore
            info: location?.state?.info || '',
            platform: 'web',
            model: browserName,
            brand: osName,
            systemVersion: browserVersion,
            appVersion: ENV.WEB_VERSION
          },
        },
      },
      (err: any) => {
        if (err) {
          Swal.fire({
            icon: SwalIcons.Error as SweetAlertIcon,
            title: t(SwalTitle.Error),
            text: err,
          });
        }
      },
    );
  }

  return (
    <UserLayout 
      navigate={props.navigate} 
      isLoading={isLoading}
      goBack={
        () => {
          props.navigate(-1);
        }
      }
    >
      {
        !JSUtil.isEmpty(news) && news && !isLoading
        ?
        <>
          <div className='detail-header-wrapper row-wrapper'>
            <div className='image-header-wrapper'>
              <img 
                src={news.image?.includes('http') ? news.image : ENV.BASE_IMAGE_URL + news.image}
                alt='header news'
              />
            </div>
            <div className='news-info-wrapper'>
              <div className='row-wrapper'>
                <NewsInfoLogo company={news.company} />
                <div className='column-wrapper'>
                <div className='row-wrapper'>
                  <div className='column-wrapper'>
                    <div className='row-wrapper news-title-wrapper'>
                      <p className='text-weight-bold text-size-large'>{news.title as string}</p>
                      <p className='text-color-gray-dark text-at'> {t(LanguageResource.Common + ':by')} </p>
                      <p className='text-weight-bold text-size-large'>
                        {
                          !JSUtil.isEmpty(news.company.for)
                          ? news.company.for
                          : news.company.name
                        }
                      </p>
                    </div>
                  </div>
                </div>
                  <div className='row-wrapper news-sub-title-wrapper'>
                    <NewsInfoCategory post={news} />
                  </div>
                </div>
              </div>
              <div className='detail-button-wrapper'>
                <NewsInfoSave news={news} navigate={props.navigate}/>
                <ShareButton shareUrl={news.shareUrl} />
              </div>
            </div>
          </div>
          <div className='detail-content-wrapper'>
            <div className="tab-wrapper">
              <DetailTabsDescription data={news} type={CategoryTypes.News} />
            </div>
          </div>
        </>
        : null
      }
    </UserLayout>
  );
}

export default NewsDetailPage;