import { NavigateFunction } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

// Components
import NewsInfoCategories from '../../Common/Info/Categories';

// Config
import ENV from '../../../config/env.json';

// Context
import { useTranslationStore } from '../../../context/Translation';

// Enums
import { LanguageResource } from '../../../enums/Languages';
import { OriginTracking, RoutesLinks } from '../../../enums/Routes';

// Interfaces
import { NewsInterface } from '../../../interfaces/News';

// Styles
import './Preview.scss';

interface PropsInterface {
  news: NewsInterface,
  navigate: NavigateFunction,
}

const NewsPreview = (props: PropsInterface) => {
  const currentPath = useLocation().pathname,
    [t] = useTranslationStore();
  return (
    <div 
      className='news-preview-wrapper column-wrapper' 
      onClick={() => props.navigate(
        [RoutesLinks.ProfileNews.Link].includes(currentPath) 
        ? RoutesLinks.ProfileNewsDetail.Link.replace(':' + RoutesLinks.NewsDetail.Params.NewsKey, props.news.key)
        : RoutesLinks.NewsDetail.Link.replace(':' + RoutesLinks.NewsDetail.Params.NewsKey, props.news.key),
        {
          state: {
            origin: OriginTracking.List
          }
        }
      )}>
      <img 
        src={props.news.image?.includes('http') ? props.news.image : ENV.BASE_IMAGE_URL + props.news.image}
        alt='header news'
      />
      <div className="column-wrapper news-preview-info-wrapper">
        <div className='row-spread-wrapper'>
          <div className='column-wrapper'>
            <p className='text-weight-bold text-size-large'>{props.news.title as string}</p>          
          </div>
          <div className='column-wrapper'>
            <NewsInfoCategories post={props.news}/>
          </div>
        </div>
        <div className='button-wrapper'>
          <div className='primary-button-wrapper'>
            <p className='primary-button-text'>{t(LanguageResource.News + ':read_full_article')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsPreview;