import {useEffect, useState} from 'react';
import { NavigateFunction, useParams } from 'react-router-dom';
import Swal, {SweetAlertIcon} from 'sweetalert2';
import sha256 from 'sha256';
// @ts-ignore
import LoadingOverlay from 'react-loading-overlay';

// Apis
import {resetPasswordApi, validateResetTokenApi} from '../../api/auth';

// Components
import Form from '../Common/Form';
import {validateInputs} from '../Common/Form/Inputs';

// Context
import { useTranslationStore } from '../../context/Translation';

// Enums
import { ImageSources } from '../../enums/Images';
import { InputTypes } from '../../enums/Inputs';
import { SwalIcons, SwalTitle } from '../../enums/Swal';
import { RoutesLinks } from '../../enums/Routes';
import { LanguageResource } from '../../enums/Languages';

// Interfaces
import { InputInterface } from '../../interfaces/Input';

interface PropsInterface {
  navigate: NavigateFunction
}

const ForgotPasswordPage = (props: PropsInterface) => {
  const [t] = useTranslationStore(),
    navigationParams = useParams(),
    token = navigationParams[RoutesLinks.ResetPassword.Params.Token],
    [password, setPassword] = useState(''),
    [passwordConfirm, setPasswordConfirm] = useState(''),
    [isLoading, setIsLoading] = useState(true),
    inputs: InputInterface[] = [
      {
        name: "password",
        type: InputTypes.Password,
        placeholder: true,
        onChange: (val: string) => setPassword(val),
        value: password,
        required: true
      },
      {
        name: "passwordConfirm",
        type: InputTypes.PasswordConfirm,
        placeholder: true,
        onChange: (val: string) => setPasswordConfirm(val),
        value: passwordConfirm,
        required: true
      }
    ];

  useEffect(() => {
    validateResetTokenApi({token}, (err: any) => {
      if (err) {
        Swal.fire({
          icon: SwalIcons.Error as SweetAlertIcon,
          title: t(SwalTitle.Error),
          text: err,
        }).then(() => {
          props.navigate(RoutesLinks.Login.Link, { replace: true });
        });
      } else {
        setIsLoading(false);
      }
    });
  }, [token, t, props]);

  const handleSubmit = () => {
    setIsLoading(true);
    const valid = validateInputs(inputs, t);
    if (valid) {
      resetPasswordApi({
        password: sha256(password), 
        token
      }, (err: any) => {
        setIsLoading(false);
        if (err) {
          Swal.fire({
            icon: SwalIcons.Error as SweetAlertIcon,
            title: t(SwalTitle.Error),
            text: err,
          });
        } else {
          Swal.fire({
            icon: SwalIcons.Success as SweetAlertIcon,
            title: t(LanguageResource.Auth + ':reset_password_success'),
            text: err,
          }).then(() => {
            props.navigate(RoutesLinks.Login.Link, { replace: true });
          });
        }
      });
    } else {
      setIsLoading(false);
    }
  }

  return (
    <div className='login-wrapper'>
      <LoadingOverlay
        active={isLoading}
        spinner
      >
        <div className='login-form-wrapper' onSubmit={() => handleSubmit()}>
          <img src={require('../../' + ImageSources.LogoBlue.url)} alt={ImageSources.LogoBlue.name} width='50px'/>
          <img src={require('../../' + ImageSources.NameBlue.url)} alt={ImageSources.NameBlue.name} width='100px'/>
          <p className='text-weight-bold text-size-default'>{t(LanguageResource.Auth + ':reset_password_sub_title')}</p>
          <Form 
            inputs={inputs}
            onSubmit={handleSubmit}
          />
          <div className="row-wrapper">
            <p className="text-color-main text-weight-bold text-size-small" onClick={() => props.navigate(RoutesLinks.Login.Link)}>
              {t(LanguageResource.Common + ':cancel')}
            </p>
            <p onClick={() => handleSubmit()} className="submit-button">&#8594;</p>
          </div>
        </div>
      </LoadingOverlay>
    </div>
  );
};

export default ForgotPasswordPage;