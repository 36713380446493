import ApiUtil from '../utils/ApiUtil';
import {getRequest} from './api';

export const getCategoriesApi = (args, callback) => {
  const endpoint = '/v1/categories/get',
    token = args.token,
    headers = {},
    qs = `type=${args.type}`;

  getRequest({endpoint, token, qs}, headers, (err, res) => {
    ApiUtil.handleReponse(err, res, callback);
  });
};
