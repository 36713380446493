import ApiUtil from '../utils/ApiUtil';
import {postRequest} from './api';

export const consultationApi = (args, callback) => {
  const endpoint = '/v1/consultations',
    token = args.token,
    headers = {};
  postRequest({endpoint, token}, headers, args.body, (err, res) => {
    ApiUtil.handleReponse(err, res, callback);
  });
};
