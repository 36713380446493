import {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";

// Apis
import {loadAmountNotificationsApi} from '../../../../api/notifications';

// Components
import useWindowDimensions from './WindowDismensions';

// Context
import {useAuthStore} from '../../../../context/Auth';

// Enums
import { Styles } from '../../../../enums/App';
import { RoutesLinks } from '../../../../enums/Routes';

// Svgs
// @ts-ignore
import { ReactComponent as ProfileNotificationsOrange } from '../../../../assets/svg/profile_notifications_orange.svg';
// @ts-ignore
import { ReactComponent as ProfileNotificationsWhite } from '../../../../assets/svg/profile_notifications_white.svg';

const NotificationBell = () => {
  const [authState, authDispatch] = useAuthStore(),
    navigate = useNavigate(),
    {height, width} = useWindowDimensions(),
    [amountNotifications, setAmountNotifications] = useState(0);

  useEffect(() => {
    loadAmountNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.tracking.seenNotifications]);

  const loadAmountNotifications = () => {
    loadAmountNotificationsApi(
      {
        token: authState.token,
      },
      authDispatch,
      (err: any, res: any) => {
        if (err) {

        } else if (res) {
          
        }
        if (res && !err) {
          setAmountNotifications(res.amountNotifications);
        }
      },
    );
  };
  
  return (
    <div
      onClick={() => {
        setAmountNotifications(0);
        navigate(RoutesLinks.Notifications.Link);
      }}
      className="notification-bell-wrapper">
      <>
        {amountNotifications > 0 ? (
          <p>{amountNotifications}</p>
        ) : null}
        <div className='svg-wrapper'>
        {
          width < Styles.MobileWidth
          ? <ProfileNotificationsWhite />
          : <ProfileNotificationsOrange />
        }
        </div>
      </>
    </div>
  );
};

export default NotificationBell;
