import {useEffect, useState} from 'react';
import { NavigateFunction } from 'react-router-dom';
import Swal, {SweetAlertIcon} from 'sweetalert2';
// @ts-ignore
import LoadingOverlay from 'react-loading-overlay';

// Apis
import {getClaimsApi} from '../../api/claims';
import {getSavedApi} from '../../api/saves';

// Components
import DealsList from '../Deals/List';

// Context
import  {useAuthStore } from '../../context/Auth';
import { useTranslationStore } from '../../context/Translation';

// Enums
import { CategoryTypes } from '../../enums/Categories';
import { LanguageResource } from '../../enums/Languages';
import { SwalIcons, SwalTitle } from '../../enums/Swal';

// Interfaces
import { DealInterface } from '../../interfaces/Deal';

// Styles
import './index.scss';

interface PropsInterface {
  navigate: NavigateFunction,
}

const ProfileDeals = (props: PropsInterface) => {
  const [loadingDealsSaved, setLoadingDealsSaved] = useState<boolean>(true),
    [loadingDealsClaimed, setLoadingDealsClaimed] = useState<boolean>(true),
    [savedDeals, setSavedDeals] = useState<DealInterface[]>([]),
    [claimedDeals, setClaimedDeals] = useState<DealInterface[]>([]),
    [authState] = useAuthStore(),
    [t] = useTranslationStore();

  useEffect(() => {
    loadDeals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.token]);

  const loadDeals = () => {
    getSavedApi(
      {
        token: authState.token,
        type: CategoryTypes.Deals,
      },
      (err: any, res: DealInterface[]) => {
        if (err) {
          Swal.fire({
            icon: SwalIcons.Error as SweetAlertIcon,
            title: t(SwalTitle.Error),
            text: err,
          });
        } else {
          setSavedDeals(res);
        }
        setLoadingDealsSaved(false);
      },
    );

    getClaimsApi(
      {
        token: authState.token,
        type: CategoryTypes.Deals,
      },
      (err: any, res: DealInterface[]) => {
        if (err) {
          Swal.fire({
            icon: SwalIcons.Error as SweetAlertIcon,
            title: t(SwalTitle.Error),
            text: err,
          });
        } else {
          setClaimedDeals(res);
        }
        setLoadingDealsClaimed(false);
      },
    );
  };
  return (
    <LoadingOverlay
      active={loadingDealsSaved || loadingDealsClaimed}
      spinner
    >   
      <div className='my-list-wrapper'>
        <p className='text-weight-bold text-size-large my-list-sub-title-wrapper'>{t(LanguageResource.Profile + ':deals_saved')}</p>
        <DealsList 
          deals={savedDeals}
          amount={0}
          limit={0}
          setLimit={() => {}}
          navigate={props.navigate}
        />
        <p className='text-weight-bold text-size-large my-list-sub-title-wrapper'>{t(LanguageResource.Profile + ':deals_claimed')}</p>
        <DealsList 
          deals={claimedDeals}
          amount={0}
          limit={0}
          setLimit={() => {}}
          navigate={props.navigate} 
        />
      </div>
    </LoadingOverlay>
  );
};

export default ProfileDeals;
