// Enums
import { StorageParams } from '../../enums/Storages';

// Interfaces
import {UserInterface} from '../../interfaces/User';

// Utils
import JSUtil from '../../utils/JSUtil';

export const initialState = {
  loggedIn: false,
  id: '',
  uuid: '',
  token: '',
  profile: {},
  education: {},
  tracking: {},
  useNotifications: true,
  errorMessage: '',
  isLoading: true,
  topics: [],
};

export const AuthReducer = (
  state: UserInterface,
  action: {
    type: any;
    data: UserInterface;
    error: any;
  },
) => {
  let modifier = {...state};
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      modifier.loggedIn = true;
      modifier.id = action.data.id;
      modifier.uuid = action.data.uuid;
      if (!JSUtil.isEmpty(action.data.profile)) {
        delete action.data.profile!.studentCardFront;
        delete action.data.profile!.studentCardBack;
        modifier.profile = action.data.profile;
      } 
      modifier.education = action.data.education;
      modifier.cv = action.data.cv;
      modifier.token = action.data.token;
      modifier.errorMessage = '';
      break;
    case 'LOGOUT':
      modifier = initialState;
      modifier.isLoading = false;
      break;
    case 'LOGIN_ERROR':
      modifier.errorMessage = action.error;
      break;
    case 'SET_USER_DATA':
      modifier.loggedIn = !JSUtil.isEmpty(action.data.loggedIn)
        ? action.data.loggedIn
        : modifier.loggedIn;
      modifier.id = !JSUtil.isEmpty(action.data.id)
        ? action.data.id
        : modifier.id;
      modifier.uuid = !JSUtil.isEmpty(action.data.uuid)
        ? action.data.uuid
        : modifier.uuid;
      if (!JSUtil.isEmpty(action.data.profile)) {
        delete action.data.profile!.studentCardFront;
        delete action.data.profile!.studentCardBack;
        modifier.profile = action.data.profile;
      } else {
        delete modifier.profile!.studentCardFront;
        delete modifier.profile!.studentCardBack;
        modifier.profile = modifier.profile;
      }
      modifier.education = !JSUtil.isEmpty(action.data.education)
        ? action.data.education
        : modifier.education;
      modifier.cv = !JSUtil.isEmpty(action.data.cv)
        ? action.data.cv
        : modifier.cv;
      modifier.token = !JSUtil.isEmpty(action.data.token)
        ? action.data.token
        : modifier.token;
      modifier.errorMessage = '';
      modifier.isLoading = !JSUtil.isEmpty(action.data.isLoading)
        ? action.data.isLoading
        : modifier.isLoading;
      break;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
  localStorage.setItem(StorageParams.AuthReducer, JSON.stringify(modifier));
  return modifier;
};
