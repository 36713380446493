import { useEffect, useState } from 'react';
import Swal, {SweetAlertIcon} from 'sweetalert2';
import { NavigateFunction, useLocation } from 'react-router-dom';
import { browserName, browserVersion, osName } from "react-device-detect";

// Apis
import { saveApi } from '../../../api/saves';

// Config
import ENV from '../../../config/env.json';

// Context
import { useAuthStore } from '../../../context/Auth';
import { useTranslationStore } from '../../../context/Translation';

// Enums
import { CategoryTypes } from '../../../enums/Categories';
import { LanguageResource } from '../../../enums/Languages';
import { RoutesLinks } from '../../../enums/Routes';
import { SwalIcons, SwalTitle } from '../../../enums/Swal';

// Interfaces
import { NewsInterface } from '../../../interfaces/News';

// Utils
import JSUtil from '../../../utils/JSUtil';

interface PropsInterface {
  news:  NewsInterface;
  navigate: NavigateFunction;
};

const NewsInfoSave = (props: PropsInterface) => {
  const [saved, setSaved] = useState(props.news.saved),
    [authState] = useAuthStore(),
    [t] = useTranslationStore(),
    location = useLocation();
  let isSaving = false;

  useEffect(() => {
    setSaved(props.news.saved);
  }, [props.news.saved]);

  const saveNews = (e: any, saved: boolean) => {
    e.stopPropagation();
    if (authState.loggedIn) {
      if (!JSUtil.isEmpty(props.news) && props.news) {
        if (!isSaving) {
          isSaving = true;
          saveApi(
            {
              token: authState.token,
              body: {
                type: CategoryTypes.News,
                id: props.news._id,
                companyId: props.news.company.by,
                saved,
                track: {
                  //@ts-ignore
                  origin: location?.state?.origin || '',
                  //@ts-ignore
                  info: location?.state?.info || '',
                  platform: 'web',
                  model: browserName,
                  brand: osName,
                  systemVersion: browserVersion,
                  appVersion: ENV.WEB_VERSION
                },
              },
            },
            (err: any) => {
              if (err) {
                isSaving = false;
                Swal.fire({
                  icon: SwalIcons.Error as SweetAlertIcon,
                  title: t(SwalTitle.Error),
                  text: err,
                });
              } else {
                isSaving = false;
                setSaved(saved);
                if (props.news.claim?.url && saved) {
                  window.open(props.news.claim?.url, "_blank");
                }
              }
            },
          );
        }
      }
    } else {
      Swal.fire({
        title: t(LanguageResource.Common + ':login_needed_title'),
        text: t(LanguageResource.Common + ':login_needed_text'),
        showCancelButton: true
      }).then(result => {
        if (result.isConfirmed) {
          props.navigate(RoutesLinks.Login.Link);
        }
      });
    }

  };
  return (
    <div className='button-wrapper'>
      {
        saved
        ?
        <div className='disabled-button-wrapper' onClick={(e) => saveNews(e, !saved)}>
          <p className='disabled-button-text'>{
            props.news.claim?.text
            ? props.news.claim.text
            : t(LanguageResource.News + ':interested')}
          </p>
        </div>
        : 
        <div className='primary-button-wrapper' onClick={(e) => saveNews(e, !saved)}>
          <p className='primary-button-text'>{
            props.news.claim?.text
            ? props.news.claim.text
            : t(LanguageResource.News + ':interested')}
          </p>
        </div>
      }
    </div>
  );
};

export default NewsInfoSave;