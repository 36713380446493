import { useEffect, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import Swal, {SweetAlertIcon} from 'sweetalert2';
import moment from 'moment';

// Apis
import {getEventsApi} from '../../api/events';
import {getCategoriesApi} from '../../api/categories';
import {getCitiesApi} from '../../api/cities';

// Components
import EventsList from './List';
import FilterSideBar from './List/FilterSideBar';
import SearchBar from '../Common/Search/Bar';
import UserLayout from '../Common/Layout/User';
import {saveFilterOptions} from '../Common/Search/Filter/Save';

// Context
import { useAuthStore } from '../../context/Auth';
import { useTranslationStore } from '../../context/Translation';

// Enums
import { CategoryTypes } from '../../enums/Categories';
import { ListLimit } from '../../enums/Events';
import { Locations } from '../../enums/Filter';
import { LanguageResource } from '../../enums/Languages';
import { SwalIcons, SwalTitle } from '../../enums/Swal';
import {YesNo} from '../../enums/YesNo';

// Interfaces
import { CategoryInterface } from '../../interfaces/Category';
import { EventInterface } from '../../interfaces/Event';
import { LocationInterface } from '../../interfaces/Location';

// Utils
import DateUtil from '../../utils/DateUtil';
import JSUtil from '../../utils/JSUtil';

interface PropsInterface {
  navigate: NavigateFunction,
  title: string
}

interface GetEventsApiCallInterface {
  latitude: number | null;
  longitude: number | null;
  currentLocation?: string | null;
  highlighted?: string | null;
  limit?: number;
}

const EventsPage = (props: PropsInterface) => {
  const [isLoading, setIsLoading] = useState(true),
    [loadingLocations, setLoadingLocations] = useState<boolean>(true),
    [loadingCategories, setLoadingCategories] = useState<boolean>(true),
    [events, setEvents] = useState<EventInterface[]>([]),
    [categories, setCategories] = useState<CategoryInterface[]>([]),
    [filteredCategories, setFilteredCategories] = useState<string[]>([]),
    [locations, setLocations] = useState<LocationInterface[]>([]),
    [amount, setAmount] = useState<number>(0),
    [search, setSearch] = useState<string>(''),
    [location, setLocation] = useState<LocationInterface>(
      {} as LocationInterface,
    ),
    [fromDate, setFromDate] = useState<string>(DateUtil.formatInputDate(DateUtil.now())),
    [toDate, setToDate] = useState<string>(DateUtil.formatInputDate(DateUtil.nowMonthsFurther(2))),
    [dateFiltered, setDateFiltered] = useState<boolean>(false),
    [distance, setDistance] = useState<number>(15),
    [sortBy, setSortBy] = useState<string>(''),
    [limit, setLimit] = useState<number>(ListLimit),
    [authState, authDispatch] = useAuthStore(),
    [t] = useTranslationStore();

  let isFiltering =
    !JSUtil.isEmpty(search) ||
    !JSUtil.isEmpty(location) ||
    dateFiltered;
    
  useEffect(() => {
    if (!JSUtil.isEmpty(search) || location._id === '99999') {
      const delaySearch = setTimeout(() => {
        loadEvents();
      }, 1000);
  
      return () => clearTimeout(delaySearch);
    } else {
      loadEvents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, filteredCategories, location, distance, dateFiltered, limit, sortBy, authState.loggedIn, authState.token]);

  useEffect(() => {
    loadLocations();
    loadCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadEvents = async() => {
    setIsLoading(true);
    await navigator.geolocation.getCurrentPosition(
      position => {
        if (location.name === t('location_' + Locations.Current)) {
          getEventsApiCall({
            latitude: position?.coords?.latitude,
            longitude: position?.coords?.longitude,
            currentLocation:
              position?.coords?.latitude + ',' + position?.coords?.longitude,
            highlighted: null,
            limit,
          })
        } else {
          getEventsApiCall({
            latitude: null,
            longitude: null,
            currentLocation:
              position?.coords?.latitude + ',' + position?.coords?.longitude,
            highlighted: null,
            limit,
          });
        }
      },
      err => {
        getEventsApiCall({
        latitude: null,
        longitude: null,
        highlighted: null,
        limit,
      })
    }
    );
  };

  const getEventsApiCall = ({
    latitude,
    longitude,
    currentLocation,
    highlighted,
    limit,
  }: GetEventsApiCallInterface) => {
    let locationQuery: string | LocationInterface = location;
    if (!JSUtil.isEmpty(latitude) && !JSUtil.isEmpty(longitude)) {
      locationQuery = latitude + ',' + longitude;
    } else if (
      !JSUtil.isEmpty(location.latitude) &&
      !JSUtil.isEmpty(location.longitude)
    ) {
      locationQuery = location.latitude + ',' + location.longitude;
    } else if (!JSUtil.isEmpty(location.name)) {
      locationQuery = location.name as string;
    }

    getEventsApi(
      {
        token: authState.token,
        search,
        location: locationQuery,
        currentLocation,
        distance,
        category: filteredCategories.join(','),
        fromDate: fromDate ? moment(fromDate).unix() * 1000 : undefined,
        toDate: toDate ? moment(toDate).unix() * 1000 : undefined,
        highlighted,
        sortBy,
        limit,
      },
      (err: any, res: {amount: number; result: EventInterface[]}) => {
        setIsLoading(false);
        if (err) {
          Swal.fire({
            icon: SwalIcons.Error as SweetAlertIcon,
            title: t(SwalTitle.Error),
            text: err,
          });
        } else {
          setEvents(res.result);
          setAmount(res.amount);
          saveFilterOptions(
            authState.token,
            {
              search,
              categories: filteredCategories,
              location: location.name,
              distance,
              fromDate,
              toDate
            },
            isFiltering,
            res.amount,
            CategoryTypes.Deals,
            err => {
              if (err) {
                Swal.fire({
                  icon: SwalIcons.Error as SweetAlertIcon,
                  title: t(SwalTitle.Error),
                  text: err,
                });
              }
            },
          );
        }
      },
      authDispatch,
    );
  }

  const loadLocations = () => {
    getCitiesApi(
      {
        token: authState.token,
        filter: YesNo.Yes,
      },
      (err: any, res: LocationInterface[]) => {
        if (err) {
          Swal.fire({
            icon: SwalIcons.Error as SweetAlertIcon,
            title: t(SwalTitle.Error),
            text: err,
          });
        } else {
          let result: LocationInterface[] = [
            {_id: '99998', name: t(LanguageResource.Common + ':location_' + Locations.Current)},
          ];
          res.map(location => result.push(location));
          setLocations(result);
        }
        setLoadingLocations(false);
      },
    );
  };

  const loadCategories = () => {
    getCategoriesApi(
      {
        token: authState.token,
        type: CategoryTypes.Events,
      },
      (err: any, res: CategoryInterface[]) => {
        if (err) {
          Swal.fire({
            icon: SwalIcons.Error as SweetAlertIcon,
            title: t(SwalTitle.Error),
            text: err,
          });
        } else {
          setCategories(res);
        }
        setLoadingCategories(false);
      },
    );
  };

  const onPressCategory = (id: string) => {
    let newFilteredCategories = [...filteredCategories];
    if (newFilteredCategories.includes(id)) {
      const index = newFilteredCategories.indexOf(id);
      if (index > -1) {
        // only splice array when item is found
        newFilteredCategories.splice(index, 1); // 2nd parameter means remove one item only
      }
    } else {
      newFilteredCategories.push(id);
    }
    setFilteredCategories(newFilteredCategories);
  };

  const cleanSearch = () => {
    setSortBy('');
    setLimit(ListLimit);
    setAmount(0);
    setLocation({} as LocationInterface);
    setDistance(15);
    setFilteredCategories([]);
    setFromDate(DateUtil.formatInputDate(DateUtil.now()));
    setToDate(DateUtil.formatInputDate(DateUtil.nowMonthsFurther(2)));
    setDateFiltered(false);
  };

  return (
    <UserLayout 
      navigate={props.navigate} 
      title={t(LanguageResource.Common + ':' + props.title)}
      isLoading={isLoading || loadingCategories || loadingLocations}
      sideBarComponent={
        <FilterSideBar
          location={location}
          setLocation={setLocation}
          distance={distance}
          setDistance={setDistance}
          categories={categories}
          filteredCategories={filteredCategories}
          onPressCategory={onPressCategory}
          locations={locations}
          setFromDate={setFromDate}
          fromDate={fromDate}
          setDateFiltered={setDateFiltered}
          dateFiltered={dateFiltered}
          setToDate={setToDate}
          toDate={toDate}
          sortBy={sortBy}
          setSortBy={setSortBy}
          cleanSearch={cleanSearch}
          filterActive={
            location ||
            distance ||
            search
            ? true : false
          }
          amount={amount}
        />
      }
      >
      <SearchBar
        search={search}
        setSearch={setSearch}
      />
      <EventsList 
        events={events}
        amount={amount}
        limit={limit}
        setLimit={setLimit}
        navigate={props.navigate} 
      />
    </UserLayout>
  );
}

export default EventsPage;