// Interfaces
import { InputInterface } from '../../../../../interfaces/Input';

const RangeInput = (props: InputInterface) => {
  return <div className='row-wrapper range-slider-wrapper'>
      <input 
        name={props.name}
        type={'range'}
        className='range-slider'
        onChange={input => props.onChange(input.target.value)}
        value={props.value}
      />
      {
        props.label
        ? <p className='range-slider-text'>{props.value + ' ' + props.label}</p>
        : null
      }
    </div>;
}

export default RangeInput