import React from 'react';

// Context
import { useTranslationStore } from '../../../../context/Translation'; 

// Enums
import { LanguageResource } from '../../../../enums/Languages';

// Styles
import './index.scss';

interface PropsInterface {
  onPressClean: () => void;
  filterActive: boolean;
  amount: number;
  children:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
}

const SearchFilter = (props: PropsInterface) => {
  const [t] = useTranslationStore();

  return (
    <div className='search-filter-wrapper'>
      {
        props.filterActive
        ? 
        <div className='search-filter-result-wrapper'>
          <p className='text-weight-bold'>{t(LanguageResource.Common + ':searched_result') + ' ' + props.amount}</p>
          <a href="javascript:void(0)" onClick={() => props.onPressClean()}>{t(LanguageResource.Common + ':clear_filter')}</a>
        </div>
        : null
      }
      {props.children}
    </div>
  );
};

export default SearchFilter;
