// Components
import Input from '../../Form/Inputs/Input';

// Context
import { useTranslationStore } from '../../../../context/Translation'; 

// Enums
import { InputTypes } from '../../../../enums/Inputs';
import { LanguageResource } from '../../../../enums/Languages';

// Styles
import './SortBy.scss'

interface PropsInterface {
  types: string[];
  sortBy: string;
  setSortBy: (arg0: string) => void;
}

const SearchFilterSortBy = (props: PropsInterface) => {
  const [t] = useTranslationStore();

  const onPressSortBy = (sortBy: string) => {
    props.setSortBy(props.sortBy === sortBy ? '' : sortBy);
  };

  return (
    <div className='search-filter-sortBy-wrapper'>
      {props.types.map(sort => {
        return (
          <Input
            key={sort}
            input={{
              name: sort,
              type: InputTypes.Radio,
              value: props.sortBy,
              onChange: () => onPressSortBy(sort),
              label: t(LanguageResource.Common + ':sort_' + sort)
            }}
          />
        );
      })}
    </div>
  );
};

export default SearchFilterSortBy;
