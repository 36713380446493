// Interfaces
import { DealInterface } from "../../../interfaces/Deal";
import { NewsInterface } from "../../../interfaces/News";

// Svgs
import { ReactComponent as Categories } from '../../../assets/svg/categories.svg';

interface PropsInterface {
  post: DealInterface | NewsInterface;
};

const InfoCategories = (props: PropsInterface) => {
  return (
    <div className='row-wrapper'>
      <div className='svg-wrapper'>
        <Categories fill='#FF462F' stroke='#FF462F' />
      </div>
      <p>
        {props.post.category}
      </p>
    </div>
  );
};

export default InfoCategories;