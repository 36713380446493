// Components
import SearchFilterOptionButton from './Options/Button';
import SearchFilterOptionWrapper from './Options/Wrapper';

// Interfaces
import { CategoryInterface } from '../../../../interfaces/Category';

interface PropsInterface {
  categories: CategoryInterface[];
  filteredCategories: string[];
  onPressCategory: (arg0: string) => void;
}

const SearchFilterCategory = (props: PropsInterface) => {

  const renderCategoryFilters = () => {
    return props.categories.map(category => {
      return (
        <SearchFilterOptionButton
          key={category._id}
          data={category._id}
          value={
            props.filteredCategories.includes(category._id)
              ? category._id
              : null
          }
          onPressFilter={() => props.onPressCategory(category._id)}
          label={category.name as string}
        />
      );
    });
  };
  return (
    <SearchFilterOptionWrapper>
      {renderCategoryFilters()}
    </SearchFilterOptionWrapper>
  );
};

export default SearchFilterCategory;
