export const ClaimStates = {
  CompleteProfile: 'completeProfile',
  TypeApply: 'typeApply',
  SuccessUrl: 'successUrl',
};

export const ClaimTypes = {
  InApp: 'inapp',
  Url: 'url',
};

export const ResumeTypes = {
  Upload: 'upload',
  Create: 'create'
}

export const ExperienceTypes = {
  Education: 'education',
  WorkExperience: 'workExperience',
  RelevantExperience: 'relevantExperience',
};

export const JobTypes = {
  Startersjob_Bachelor: 'startersjob_bachelor',
  Startersjob_Master: 'startersjob_master',
  Studentenjob: 'studentenjob',
  Stage: 'stage',
  Tastejob: 'tastejob',
  VolunteerJob: 'volunteerjob'
};

export const LocationTypes = {
  Location: 'location',
  Belgium: 'belgium',
};

export const ListLimit = 18;