import React from 'react';

// Styles
import './Wrapper.scss';

interface PropsInterface {
  children:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
}

const SearchFilterOptionWrapper = (props: PropsInterface) => {
  return <div className='search-filter-option-button-wrapper row-wrapper'>{props.children}</div>;
};

export default SearchFilterOptionWrapper;
