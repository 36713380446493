import { useEffect, useState } from 'react';
import Swal, {SweetAlertIcon} from 'sweetalert2';
import { NavigateFunction, useLocation } from 'react-router-dom';
import { browserName, browserVersion, osName } from "react-device-detect";

// Apis
import { saveApi } from '../../../api/saves';

// Config
import ENV from '../../../config/env.json';

// Context
import { useAuthStore } from '../../../context/Auth';
import { useTranslationStore } from '../../../context/Translation';

// Enums
import { LanguageResource } from '../../../enums/Languages';
import { RoutesLinks } from '../../../enums/Routes';
import { SwalIcons, SwalTitle } from '../../../enums/Swal';

// Interfaces
import { DealInterface } from '../../../interfaces/Deal';
import { EventInterface } from '../../../interfaces/Event';
import { JobInterface } from "../../../interfaces/Job";

// Svgs
import { ReactComponent as SaveActive } from '../../../assets/svg/save_active.svg';
import { ReactComponent as SaveInActive } from '../../../assets/svg/save_inactive.svg';

// Utils
import JSUtil from '../../../utils/JSUtil';

interface PropsInterface {
  post: JobInterface | DealInterface | EventInterface;
  type: string;
  navigate: NavigateFunction;
};

const InfoSave = (props: PropsInterface) => {
  const [saved, setSaved] = useState(props.post.saved),
    [authState] = useAuthStore(),
    [t] = useTranslationStore(),
    location = useLocation();

  useEffect(() => {
    setSaved(props.post.saved);
  }, [props.post.saved]);

  const savePost = (saved: boolean) => {
    if (authState.loggedIn) {
      if (!JSUtil.isEmpty(props.post) && props.post) {
        saveApi(
          {
            token: authState.token,
            body: {
              type: props.type,
              id: props.post._id,
              companyId: props.post.company.by,
              saved,
              track: {
                //@ts-ignore
                origin: location?.state?.origin || '',
                //@ts-ignore
                info: location?.state?.info || '',
                platform: 'web',
                model: browserName,
                brand: osName,
                systemVersion: browserVersion,
                appVersion: ENV.WEB_VERSION
              },
            },
          },
          (err: any) => {
            if (err) {
              Swal.fire({
                icon: SwalIcons.Error as SweetAlertIcon,
                title: t(SwalTitle.Error),
                text: err,
              });
            } else {
              setSaved(saved);
            }
          },
        );
      }
    } else {
      Swal.fire({
        title: t(LanguageResource.Common + ':login_needed_title'),
        text: t(LanguageResource.Common + ':login_needed_text'),
        showCancelButton: true
      }).then(result => {
        if (result.isConfirmed) {
          props.navigate(RoutesLinks.Login.Link);
        }
      });
    }

  };
  return (
    <div className='row-wrapper'>
      <div 
        className='svg-wrapper' 
        onClick={(e) => {
          e.stopPropagation();
          savePost(!saved)
        }}
      >
        {
          saved
          ? <SaveActive fill='#FF462F' stroke='#FF462F'/>
          : <SaveInActive />
        }
      </div>
      <p 
        onClick={(e) => {
          e.stopPropagation();
          savePost(!saved)
        }}
      >{JSUtil.showShortNumber(props.post?.consultationAmount)}</p>
    </div>
  );
};

export default InfoSave;