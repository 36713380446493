import { NavigateFunction } from 'react-router-dom';

// Components
import Account from './Account';
import CV from './CV';
import ProfileDeals from './Deals';
import ProfileEvents from './Events';
import ProfileJobs from './Jobs';
import ProfileNews from './News';
import UserLayout from '../Common/Layout/User';

// Context
import { useAuthStore } from '../../context/Auth';
import { useTranslationStore } from '../../context/Translation';
import { RoutesLinks } from '../../enums/Routes';

interface PropsInterface {
  navigate: NavigateFunction,
  title: string,
  state: string
}

const ProfilePage = (props: PropsInterface) => {
  const [authState] = useAuthStore(),
    [t] = useTranslationStore();

  return (
    <UserLayout 
      navigate={props.navigate} 
      title={t('hello') + ', ' + authState.profile.firstname + '!'}
      isLoading={false}
    >
      {
        props.state === RoutesLinks.ProfileAccount.Params.State
        ? <Account 
          navigate={props.navigate}
        />
        : props.state === RoutesLinks.ProfileCV.Params.State
        ? <CV 
          navigate={props.navigate}
        />
        : props.state === RoutesLinks.ProfileJobs.Params.State
        ? <ProfileJobs 
          navigate={props.navigate}
        />
        : props.state === RoutesLinks.ProfileDeals.Params.State
        ? <ProfileDeals
          navigate={props.navigate}
        />
        : props.state === RoutesLinks.ProfileEvents.Params.State
        ? <ProfileEvents
          navigate={props.navigate}
        />
        : props.state === RoutesLinks.ProfileNews.Params.State
        ? <ProfileNews
          navigate={props.navigate}
        />
        : null
      }
    </UserLayout>
  );
};

export default ProfilePage;
