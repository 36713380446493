import {useEffect, useRef, useState} from 'react';
import { NavigateFunction } from 'react-router-dom';
import { browserName, browserVersion, osName } from "react-device-detect";
import Swal, {SweetAlertIcon} from 'sweetalert2';
// @ts-ignore
import LoadingOverlay from 'react-loading-overlay';

// Apis
import { deActivateUserApi } from '../../../api/users';

// Components
import AccountForm from './Form';
import EducationForm from '../Education/Form';
import { validateInputs } from '../../Common/Form/Inputs';

// Config
import ENV from '../../../config/env.json';

// Context
import { useAuthStore, updateUser, logoutUser } from '../../../context/Auth';
import { useTranslationStore } from '../../../context/Translation';

// Enums
import { LanguageResource } from '../../../enums/Languages';
import { SwalIcons, SwalTitle } from '../../../enums/Swal';

// Interfaces
import { InputInterface } from '../../../interfaces/Input';

// Styles
import './index.scss';

// Utils
import JSUtil from '../../../utils/JSUtil';

interface PropsInterface {
  navigate: NavigateFunction,
}

const Account = (props: PropsInterface) => {
  const [authState, authDispatch] = useAuthStore(),
    [t] = useTranslationStore(),
    accountFormRef = useRef<HTMLFormElement>(null),
    educationFormRef = useRef<HTMLFormElement>(null),
    [isLoading, setIsLoading] = useState(false),
    [loadingCities, setLoadingCities] = useState(true),
    [loadingSchools, setLoadingSchools] = useState(true),
    [submittedAccount, setSubmittedAccount] = useState(false);

  useEffect(() => {
    if (submittedAccount) {
      if (educationFormRef.current) {
        const form = educationFormRef.current;
        if (form) {
          if (typeof form.requestSubmit === 'function') {
            form.requestSubmit();
          } else {
            const event = new Event('submit', {
              'bubbles'    : true,
              'cancelable' : true 
            });
            form.dispatchEvent(event);
          }
        }
      }
    }
  }, [submittedAccount]);
 
  const onSubmitAccount = async(inputs: (InputInterface|InputInterface[])[], data: any) => {
    setIsLoading(true);
    if (validateInputs(inputs, t)) {
      updateUser(
        authDispatch,
        {
          token: authState.token,
          body: {
            _id: authState.id,
            email: data.email,
            firstname: data.firstname,
            lastname: data.lastname,
            phone: data.phone,
            birthday: data.birthday,
            street: data.street,
            housenumber: data.housenumber,
            box: data.box,
            postalCode: data.postalCode,
            city: data.cityId ? parseInt(data.cityId) : '',
            studentCardFront: await JSUtil.getBase64(data.studentCardFront) as string,
            studentCardBack: await JSUtil.getBase64(data.studentCardBack) as string
          },
        }, (err: any, res: any) => {
          if (err) {
            setIsLoading(false);
            Swal.fire({
              icon: SwalIcons.Error as SweetAlertIcon,
              title: t(SwalTitle.Error),
              text: err,
            });
          } else {
            setSubmittedAccount(true);
          }
        }
      );
    } else {
      setIsLoading(false);
    }
  };

  const onSubmitEducation = (inputs: (InputInterface|InputInterface[])[], data: any) => {
    if (validateInputs(inputs, t)) {
      updateUser(
        authDispatch,
        {
          token: authState.token,
          body: {
            _id: authState.id,
            education: {
              school: parseInt(data.school, 10),
              faculty: parseInt(data.faculty, 10),
              fieldOfStudy: parseInt(data.fieldOfStudy, 10),
              city: parseInt(data.city, 10),
              yearOfStudy: parseInt(data.yearOfStudy, 10),
              typeOfStudy: parseInt(data.typeOfStudy, 10),
            },
            tracking: {
              platform: 'web',
              model: browserName,
              brand: osName,
              systemVersion: browserVersion,
              appVersion: ENV.WEB_VERSION
            },
          },
        },
        (err: any) => {
          setIsLoading(false);
          setSubmittedAccount(false);
          if (err) {
            Swal.fire({
              icon: SwalIcons.Error as SweetAlertIcon,
              title: t(SwalTitle.Error),
              text: err,
            });
          } else {
            Swal.fire({
              icon: SwalIcons.Success as SweetAlertIcon,
              title: t(SwalTitle.Success),
              text: t(LanguageResource.Common + ':profile_updated'),
            });
          }
        },
      );
    }
  };

  const handleSubmit = () => {
    if (accountFormRef.current) {
      const form = accountFormRef.current;
      if (form) {
        if (typeof form.requestSubmit === 'function') {
          form.requestSubmit();
        } else {
          const event = new Event('submit', {
            'bubbles'    : true,
            'cancelable' : true 
          });
          form.dispatchEvent(event);
        }
      }
    }
  };

  const onPressDeactivate = () => {
    Swal.fire({
      title: t(LanguageResource.Profile + ':deactivate_account'),
      text: t(LanguageResource.Profile + ':deactivate_text'),
      showCancelButton: true
    }).then(result => {
      if (result.isConfirmed) {
        const data = {
          token: authState.token,
          body: {
            email: authState.profile.email,
          },
        };
        deActivateUserApi(data, (err: any, res: any) => {
          if (res) {
            logoutUser(authDispatch);
          } else if (err) {
            Swal.fire({
              icon: SwalIcons.Error as SweetAlertIcon,
              title: t(SwalTitle.Error),
              text: err,
            });
          }
        });
      }
    })
  }

  return (
    <LoadingOverlay
      active={loadingSchools || loadingCities || isLoading}
      spinner
    >   
      <div className='create-wrapper'>
        <p className='text-weight-bold text-size-large'>{t(LanguageResource.Profile + ':edit_profile_title')}</p>
        <AccountForm
          forwardedRef={accountFormRef}
          onSubmit={(inputs, data) => onSubmitAccount(inputs, data)}
          loadingCities={loadingCities}
          setLoadingCities={setLoadingCities}
        />
        <EducationForm
          forwardedRef={educationFormRef}
          onSubmit={(inputs, data) => onSubmitEducation(inputs, data)}
          setIsLoading={setIsLoading}
          loadingSchools={loadingSchools}
          setLoadingSchools={setLoadingSchools}
          showLabels={true}
        />
        <div className='primary-button-wrapper' onClick={() => handleSubmit()}>
          <p className='primary-button-text'>{t(LanguageResource.Common + ':save')}</p>
        </div>
        <div className='deactivate-account-wrapper'>
          <a href="javascript:void(0);" onClick={() => onPressDeactivate()} className='underline-link text-color-secondary text-size-default text-weight-bold text-align-center'>
            {t(LanguageResource.Profile + ':deactivate_account')}
          </a>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default Account;
