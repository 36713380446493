// Config
import ENV from '../../../config/env.json';

// Interfaces
import { CompanyInterface } from "../../../interfaces/Company";

interface PropsInterface {
  company: CompanyInterface
}

const DealInfoLogo = (props: PropsInterface) => {
  return (
    <div>
      <img 
        src={props.company.image?.includes('http') ? props.company.image : ENV.BASE_IMAGE_URL + props.company.image}
        alt='company logo'
      />
    </div>
  );
}

export default DealInfoLogo;