import {useContext, createContext} from 'react';

const TranslationStore = createContext();
TranslationStore.displayName = 'Translation';

export const useTranslationStore = () => useContext(TranslationStore);

export const TranslationStoreProvider = props => {
  return (
    <TranslationStore.Provider value={[props.translation.t]}>
      {props.children}
    </TranslationStore.Provider>
  );
};
