import { useEffect, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import Swal, {SweetAlertIcon} from 'sweetalert2';

// Apis
import {getNewsApi} from '../../api/news';
import {getCategoriesApi} from '../../api/categories';

// Components
import NewsList from './List/';
import FilterSideBar from './List/FilterSideBar';
import SearchBar from '../Common/Search/Bar';
import UserLayout from '../Common/Layout/User';
import {saveFilterOptions} from '../Common/Search/Filter/Save';

// Context
import { useAuthStore } from '../../context/Auth';
import { useTranslationStore } from '../../context/Translation';

// Enums
import { CategoryTypes } from '../../enums/Categories';
import { ListLimit } from '../../enums/News';
import { LanguageResource } from '../../enums/Languages';
import { SwalIcons, SwalTitle } from '../../enums/Swal';

// Interfaces
import { CategoryInterface } from '../../interfaces/Category';
import { NewsInterface } from '../../interfaces/News';

// Utils
import JSUtil from '../../utils/JSUtil';

interface PropsInterface {
  navigate: NavigateFunction,
  title: string
}

interface GetNewsApiCallInterface {
  highlighted?: string | null;
  limit?: number;
}

const NewsPage = (props: PropsInterface) => {
  const [isLoading, setIsLoading] = useState(true),
    [loadingCategories, setLoadingCategories] = useState<boolean>(true),
    [news, setNews] = useState<NewsInterface[]>([]),
    [categories, setCategories] = useState<CategoryInterface[]>([]),
    [filteredCategories, setFilteredCategories] = useState<string[]>([]),
    [amount, setAmount] = useState<number>(0),
    [search, setSearch] = useState<string>(''),
    [limit, setLimit] = useState<number>(ListLimit),
    [authState, authDispatch] = useAuthStore(),
    [t] = useTranslationStore();
  let isFiltering = !JSUtil.isEmpty(search);
    
  useEffect(() => {
    if (!JSUtil.isEmpty(search)) {
      const delaySearch = setTimeout(() => {
        loadNews();
      }, 1000);
  
      return () => clearTimeout(delaySearch);
    } else {
      loadNews();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, filteredCategories, limit, authState.loggedIn, authState.token]);

  useEffect(() => {
    loadCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadNews = async() => {
    setIsLoading(true);
    getNewsApiCall({
      highlighted: null,
      limit,
    });
  };

  const getNewsApiCall = ({
    highlighted,
    limit,
  }: GetNewsApiCallInterface) => {
    getNewsApi(
      {
        token: authState.token,
        search,
        category: filteredCategories.join(','),
        highlighted,
        limit,
      },
      (err: any, res: {amount: number; result: NewsInterface[]}) => {
        setIsLoading(false);
        if (err) {
          Swal.fire({
            icon: SwalIcons.Error as SweetAlertIcon,
            title: t(SwalTitle.Error),
            text: err,
          });
        } else {
          setNews(res.result);
          setAmount(res.amount);
          saveFilterOptions(
            authState.token,
            {
              search,
              categories: filteredCategories,
            },
            isFiltering,
            res.amount,
            CategoryTypes.Events,
            err => {
              if (err) {
                Swal.fire({
                  icon: SwalIcons.Error as SweetAlertIcon,
                  title: t(SwalTitle.Error),
                  text: err,
                });
              }
            },
          );
        }
      },
      authDispatch,
    );
  }

  const loadCategories = () => {
    getCategoriesApi(
      {
        token: authState.token,
        type: CategoryTypes.News,
      },
      (err: any, res: CategoryInterface[]) => {
        if (err) {
          Swal.fire({
            icon: SwalIcons.Error as SweetAlertIcon,
            title: t(SwalTitle.Error),
            text: err,
          });
        } else {
          setCategories(res);
        }
        setLoadingCategories(false);
      },
    );
  };

  const onPressCategory = (id: string) => {
    let newFilteredCategories = [...filteredCategories];
    if (newFilteredCategories.includes(id)) {
      const index = newFilteredCategories.indexOf(id);
      if (index > -1) {
        // only splice array when item is found
        newFilteredCategories.splice(index, 1); // 2nd parameter means remove one item only
      }
    } else {
      newFilteredCategories.push(id);
    }
    setFilteredCategories(newFilteredCategories);
  };

  const cleanSearch = () => {
    setLimit(ListLimit);
    setAmount(0);
    setFilteredCategories([]);
  };

  return (
    <UserLayout 
      navigate={props.navigate} 
      title={t(LanguageResource.Common + ':' + props.title)}
      isLoading={isLoading || loadingCategories}
      sideBarComponent={
        <FilterSideBar
          categories={categories}
          filteredCategories={filteredCategories}
          onPressCategory={onPressCategory}
          cleanSearch={cleanSearch}
          filterActive={
            search
            ? true : false
          }
          amount={amount}
        />
      }
      >
      <SearchBar
        search={search}
        setSearch={setSearch}
      />
      <NewsList 
        news={news}
        amount={amount}
        limit={limit}
        setLimit={setLimit}
        navigate={props.navigate} 
      />
    </UserLayout>
  );
}

export default NewsPage;