import { useTranslation } from 'react-i18next';

// Components
import CheckboxInput from './Checkbox';
import DropDownInput from './Dropdown';
import FileInput from './File';
import RadioInput from './Radio';
import RangeInput from './Range';
import TextInput from './Text';
import TextAreaInput from './TextArea';

// Enums
import { InputTypes } from '../../../../../enums/Inputs';
import { LanguageResource } from '../../../../../enums/Languages';

// Interfaces
import { InputInterface } from '../../../../../interfaces/Input';

// Styles
import './index.scss';

interface PropsInterface {
  input: InputInterface,
};

const Input = (props: PropsInterface) => {
  const { t } = useTranslation([LanguageResource.Input]);

  const renderInput = () => {
    switch (props.input.type) {
      case InputTypes.Checkbox:
        return <CheckboxInput
          name={props.input.name}
          type={props.input.type}
          label={props.input.label}
          onLabelClick={props.input.onLabelClick}
          placeholder={props.input.placeholder || t(LanguageResource.Input + ':' + props.input.name)}
          onChange={props.input.onChange}
          value={props.input.value}
        />
      case InputTypes.Dropdown:
        return <DropDownInput
          input={props.input}
        />
      case InputTypes.File:
        return <FileInput
          input={props.input}
        />
      case InputTypes.Password:
      case InputTypes.PasswordConfirm:
        return <TextInput 
          name={props.input.name}
          type={InputTypes.Password}
          label={props.input.label}
          placeholder={props.input.placeholder || t(LanguageResource.Input + ':' + props.input.name)}
          onChange={props.input.onChange}
          value={props.input.value}
        />
      case InputTypes.Radio:
        return <RadioInput
          name={props.input.name}
          type={props.input.type}
          label={props.input.label}
          placeholder={props.input.placeholder || t(LanguageResource.Input + ':' + props.input.name)}
          onChange={props.input.onChange}
          value={props.input.value}
        />
      case InputTypes.Range:
        return <RangeInput
          name={props.input.name}
          label={props.input.label}
          onChange={props.input.onChange}
          value={props.input.value}
        />
      case InputTypes.TextArea:
        return <TextAreaInput 
          name={props.input.name}
          type={props.input.type}
          label={props.input.label}
          placeholder={props.input.placeholder || t(LanguageResource.Input + ':' + props.input.name)}
          onChange={props.input.onChange}
          value={props.input.value}
        />
      default:
        return <TextInput 
          name={props.input.name}
          type={props.input.type}
          label={props.input.label}
          placeholder={props.input.placeholder || t(LanguageResource.Input + ':' + props.input.name)}
          onChange={props.input.onChange}
          value={props.input.value}
        />
    }
  }

  return (
      props.input.label && 
      props.input.type && 
      ![InputTypes.Range, InputTypes.Radio, InputTypes.Checkbox].includes(props.input.type)
      ? <div className='input-wrapper'>
          <p className='text-weight-bold text-size-default'>{props.input.label} {
            props.input.required
            ? '*'
            : null
          }</p>
          {renderInput()}
        </div>
      : renderInput()
  );
};

export default Input;