import { useState, useEffect } from 'react';
import { NavigateFunction, useParams, useLocation} from 'react-router-dom';
import { browserName, browserVersion, osName } from "react-device-detect";
import Swal, {SweetAlertIcon} from 'sweetalert2';

// Apis
import { claimApi, updateSwipeAmount } from '../../../api/claims';
import { consultationApi } from '../../../api/consultations';
import { getDealsApi } from '../../../api/deals';
import { saveApi } from '../../../api/saves';

// Components
import ProfilePopupCompleteClaim from '../../Profile/Popup/Complete/Claim';
import DealInfoCategories from '../../Common/Info/Categories';
import DealInfoCity from '../Info/City';
import DealInfoLogo from '../Info/Logo';
import DealInfoType from '../Info/Type';
import DetailTabs from '../../Common/Detail/Tabs/';
import UserLayout from '../../Common/Layout/User';
import ShareButton from '../../Common/Buttons/Share';
import SwipeButton from '../../Common/Buttons/Swipe';
import useWindowDimensions from '../../Common/Layout/User/WindowDismensions';

// Config
import ENV from '../../../config/env.json';

// Context
import { useAuthStore } from '../../../context/Auth';
import { useTranslationStore } from '../../../context/Translation';

// Enums
import { Styles } from '../../../enums/App';
import { CategoryTypes } from '../../../enums/Categories';
import { ClaimStates } from '../../../enums/Deals';
import { LanguageResource } from '../../../enums/Languages';
import { RoutesLinks } from '../../../enums/Routes';
import { SwalIcons, SwalTitle } from '../../../enums/Swal';
import { StorageParams } from '../../../enums/Storages';

// Interfaces
import { DealInterface } from '../../../interfaces/Deal';

// Styles
import './index.scss';

// Svgs
import { ReactComponent as SaveActive } from '../../../assets/svg/save_active.svg';
import { ReactComponent as SaveInActive } from '../../../assets/svg/save_inactive.svg';

// Utils
import JSUtil from '../../../utils/JSUtil';
import DateUtil from '../../../utils/DateUtil';

interface PropsInterface {
  navigate: NavigateFunction,
}

const DealsDetailPage = (props: PropsInterface) => {
  const [authState, authDispatch] = useAuthStore(),
    navigationParams = useParams(),
    dealKey = navigationParams[RoutesLinks.DealsDetail.Params.DealKey],
    [deal, setDeal] = useState<DealInterface>(),
    [isLoading, setIsLoading] = useState<boolean>(true),
    [saved, setSaved] = useState<boolean>(false),
    [claimed, setClaimed] = useState<boolean>(false),
    [popup, setPopup] = useState<string>(''), 
    [t] = useTranslationStore(),
    {width} = useWindowDimensions(),
    location = useLocation();
  let isSaving = false,
    isClaiming = false;
  useEffect(() => {
    loadDeal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.loggedIn]);

  const loadDeal = () => {
    getDealsApi(
      {
        token: authState.token,
        key: dealKey
      },
      (err: any, res: {amount: number; result: DealInterface[]}) => {
        setIsLoading(false);
        if (err) {
          Swal.fire({
            icon: SwalIcons.Error as SweetAlertIcon,
            title: t(SwalTitle.Error),
            text: err,
          }).then(() => {
            props.navigate(RoutesLinks.Deals.Link);
          });
        } else if (!JSUtil.isEmpty(res.result[0])) {
          setDeal(res.result[0]);
          setClaimed(res.result[0]?.claim.claimed)
          setSaved(res.result[0]?.saved)
          addConsultation(res.result[0]);
        } else {
          Swal.fire({
            icon: SwalIcons.Error as SweetAlertIcon,
            title: t(SwalTitle.Error),
            text: t(LanguageResource.Common + ':information_not_available'),
          }).then(() => {
            props.navigate(RoutesLinks.Deals.Link);
          });
        }
      },
      authDispatch
    );
  };

  const addConsultation = (deal: DealInterface) => {
    consultationApi(
      {
        token: authState.token,
        body: {
          type: CategoryTypes.Deals,
          id: deal._id,
          companyId: deal.company.by,
          userId: authState.id || localStorage.getItem(StorageParams.UserId),
          track: {
            //@ts-ignore
            origin: location?.state?.origin || '',
            //@ts-ignore
            info: location?.state?.info || '',
            platform: 'web',
            model: browserName,
            brand: osName,
            systemVersion: browserVersion,
            appVersion: ENV.WEB_VERSION
          },
        },
      },
      (err: any) => {
        if (err) {
          Swal.fire({
            icon: SwalIcons.Error as SweetAlertIcon,
            title: t(SwalTitle.Error),
            text: err,
          });
        }
      },
    );
  }

  const saveDeals = (saved: boolean) => {
    if (authState.loggedIn) {
      if (!JSUtil.isEmpty(deal) && deal) {
        if (!isSaving) {
          isSaving = true;
          saveApi(
            {
              token: authState.token,
              body: {
                type: CategoryTypes.Deals,
                id: deal._id,
                companyId: deal.company.by,
                saved,
                track: {
                  //@ts-ignore
                  origin: location?.state?.origin || '',
                  //@ts-ignore
                  info: location?.state?.info || '',
                  platform: 'web',
                  model: browserName,
                  brand: osName,
                  systemVersion: browserVersion,
                  appVersion: ENV.WEB_VERSION
                },
              },
            },
            (err: any) => {
              if (err) {
                isSaving = false;
                Swal.fire({
                  icon: SwalIcons.Error as SweetAlertIcon,
                  title: t(SwalTitle.Error),
                  text: err,
                });
              } else {
                isSaving = false;
                setSaved(saved);
              }
            },
          );
        }
      }
    } else {
      fireSwalWithLoginMessage();
    }
  };

  const onPressClaimButton = (skipProfile: boolean) => {
    if (authState.loggedIn) {
      if (!JSUtil.isEmpty(deal) && deal) {
        if (
          (JSUtil.isEmpty(authState.education) ||
            JSUtil.isEmpty(authState.education.typeOfStudy) ||
            (authState.education.typeOfStudy._id !== 1 &&
              authState.education.typeOfStudy._id !== 3 &&
              (JSUtil.isEmpty(authState.education.faculty) ||
                JSUtil.isEmpty(authState.education.faculty._id) ||
                JSUtil.isEmpty(authState.education.school) ||
                JSUtil.isEmpty(authState.education.school._id)))) &&
          !skipProfile
        ) {
          setPopup(ClaimStates.CompleteProfile);
        } else {
          setPopup('');
          Swal.fire({
            title: t(LanguageResource.Deals + ':claim_title'),
            text: t(LanguageResource.Deals + ':claim_text'),
            showCancelButton: true
          }).then(result => {
            if (result.isConfirmed) {
              claimDeal();
            }
          });
        }
      }
    } else {
      fireSwalWithLoginMessage();
    }
  };

  const claimDeal = () => {
    if (deal) {
      if (!isClaiming) {
        isClaiming = true;
        claimApi(
          {
            token: authState.token,
            body: {
              type: CategoryTypes.Deals,
              content: {
                _id: deal._id,
                title: deal.title,
                company: deal.company,
                claim: deal.claim,
              },
              track: {
                //@ts-ignore
                origin: location?.state?.origin || '',
                //@ts-ignore
                info: location?.state?.info || '',
                platform: 'web',
                model: browserName,
                brand: osName,
                systemVersion: browserVersion,
                appVersion: ENV.WEB_VERSION
              },
            },
          },
          (err: any) => {
            if (err) {
              isClaiming = false;
              Swal.fire({
                icon: SwalIcons.Error as SweetAlertIcon,
                title: t(SwalTitle.Error),
                text: err,
              });
            } else {
              setClaimed(true);
              isClaiming = false;
              if (deal && deal.claim?.url && deal.claim?.type === ClaimStates.Url) {
                window.open(deal.claim.url, "_blank");
              }
            }
          },
        );
      }
      }
  };

  const renderCouponCode = () => {
    return (Math.random() + 10).toString(36).substring(2);
  };

  const setSwipedAction = () => {
    updateSwipeAmount(
      {
        token: authState.token,
        body: {
          type: CategoryTypes.Deals,
          id: deal?._id,
        },
      },
      (err: any) => {
        if (err) {
          Swal.fire({
            icon: SwalIcons.Error as SweetAlertIcon,
            title: t(SwalTitle.Error),
            text: err,
          });
        }
      },
    );
  };

  const renderClaimButton = () => {
    if (claimed) {
      switch (deal?.claim.type) {
        case ClaimStates.Coupon:
          return (
            <div className='button-wrapper'>
              <p className='button-info-text'>{t(LanguageResource.Deals + ':claimed_coupon')}</p>
              <div className='disabled-button-wrapper'>
                <p className='disabled-button-text'>{t(LanguageResource.Deals + ':claimed_coupon_code') + deal.claim.code}</p>
              </div>
            </div>
          );
        case ClaimStates.Url:
          return (
            <div className='button-wrapper'>
              <p className='button-info-text' style={{marginTop: "-40px"}}>{t(LanguageResource.Deals + ':claimed_url')}</p>
              <div className='primary-button-wrapper' onClick={() => deal && deal.claim?.url && deal.claim?.type === ClaimStates.Url ? window.open(deal.claim.url, "_blank") : null}>
                <p className='primary-button-text'>{t(LanguageResource.Deals + ':claimed_url_button')}</p>
              </div>
            </div>
          );
        case ClaimStates.Swipe:
          if (deal.claim.swiped === deal.claim.swipes || deal.claim.swiped > deal.claim.swipes) {
            return (
              <div className='button-wrapper disabled-button-wrapper'>
                <p className='disabled-button-text'>{t(LanguageResource.Deals + ':claimed')}</p>
              </div>
            );
          } else {
            return (
              <div className='button-wrapper'>
                <p className='button-info-text' style={{marginTop: "-60px", marginBottom: "5px"}}>{t(LanguageResource.Deals + ':claimed_swipe')}</p>
                <SwipeButton 
                  swipeText={t(LanguageResource.Deals + ':claimed_swipe_button') + (deal.claim.swipes - deal.claim.swiped) as string}
                  swipedText={renderCouponCode()}
                  onSwiped={() => setSwipedAction()}
                />
              </div>
            )
          }
        default:
          return (
            <div className='button-wrapper disabled-button-wrapper'>
              <p className='disabled-button-text'>{t(LanguageResource.Deals + ':claimed')}</p>
            </div>
          );
      }

    } else if (
      (deal?.endDate && DateUtil.isTodayBeforeDate(deal?.endDate)) ||
      JSUtil.isEmpty(deal?.endDate)
    ) {      
      return (
          <div className='button-wrapper primary-button-wrapper' onClick={() => onPressClaimButton(false)}>
            <p className='primary-button-text'>{t(LanguageResource.Deals + ':claim')}</p>
          </div>
      );
    }
  };

  const renderSaveButton = () => {
    return (
      <div className='button-wrapper save-button-wrapper' onClick={() => saveDeals(!saved)}>
        {
          saved
          ? <SaveActive  />
          : <SaveInActive />
        }
        <p>{saved ? t(LanguageResource.Common + ':saved') : t(LanguageResource.Common + ':save')} ({JSUtil.showShortNumber(deal?.consultationAmount as number)})</p>
      </div>
    )
  }

  const fireSwalWithLoginMessage = () => {
    Swal.fire({
      title: t(LanguageResource.Common + ':login_needed_title'),
      text: t(LanguageResource.Common + ':login_needed_text'),
      showCancelButton: true
    }).then(result => {
      if (result.isConfirmed) {
        props.navigate(RoutesLinks.Login.Link);
      }
    });
  }

  const closePopup = () => {
    setPopup('');
  };

  const renderPopup = () => {
    switch (popup) {
      case ClaimStates.CompleteProfile:
        return (
          <ProfilePopupCompleteClaim
            onPressClaimButton={() => onPressClaimButton(true)}
            closePopup={() => closePopup()}
            label={t(LanguageResource.Deals + ':claim_confirm')}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        );
      default:
        return null;
    }
  };
  return (
    <UserLayout 
      navigate={props.navigate} 
      isLoading={isLoading}
      goBack={
        () => {
          props.navigate(-1);
        }
      }
    >
      {
        !JSUtil.isEmpty(deal) && deal && !isLoading
        ?
        <>
          <div className='detail-header-wrapper row-wrapper'>
            <div className='image-header-wrapper'>
              <img 
                src={deal.image?.includes('http') ? deal.image : ENV.BASE_IMAGE_URL + deal.image}
                alt='header deal' 
              />
            </div>
            <div className='deal-info-wrapper'>
              <div className='row-wrapper'>
                <DealInfoLogo company={deal.company} />
                <div className='column-wrapper'>
                  <div className='row-wrapper'>
                    <div className='column-wrapper'>
                      <div className='row-wrapper deal-title-wrapper'>
                        <p className='text-weight-bold text-size-large'>{deal.title as string}</p>
                        <p className='text-color-gray-dark text-at'> {t(LanguageResource.Common + ':at')} </p>
                        <p className='text-weight-bold text-size-large'>
                          {
                            !JSUtil.isEmpty(deal.company.for)
                            ? deal.company.for
                            : deal.company.name
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='row-wrapper deal-sub-title-wrapper'>
                    <DealInfoCity deal={deal} showFullAddress={true}/>
                    <DealInfoCategories post={deal} />
                  </div>
                </div>
                {
                  width > Styles.TabletWidth
                  ? <DealInfoType deal={deal} />
                  : null

                }
              </div>
              {
                  width < Styles.TabletWidth
                  ? <DealInfoType deal={deal} />
                  : null

                }
              <div className='detail-button-wrapper row-wrapper'>
                {renderClaimButton()}
                {renderSaveButton()}
                <ShareButton shareUrl={deal.shareUrl} />
              </div>
            </div>
          </div>
          <div className='detail-content-wrapper'>
            <DetailTabs
              data={deal as DealInterface}
              useRequirements={false}
              type={CategoryTypes.Deals}
            />
            {renderPopup()}
          </div>
        </>
        : null
      }
    </UserLayout>
  );
}

export default DealsDetailPage;

