// Context
import { useTranslationStore } from "../../../context/Translation";

// Enums
import { LocationTypes } from "../../../enums/Deals";
import { LanguageResource } from "../../../enums/Languages";

// Interfaces
import { DealInterface } from '../../../interfaces/Deal';

// Svgs
import { ReactComponent as Pin } from '../../../assets/svg/pin.svg';

// Utils
import LanguageUtil from '../../../utils/LanguageUtil';

interface PropsInterface {
  deal: DealInterface;
  showFullAddress: boolean;
};

const DealInfoCity = (props: PropsInterface) => {
  const [t] = useTranslationStore();

  const getCity = () => {
    return (
      props.deal.location.city && props.deal.location.city.name
      // @ts-ignore
      ? props.deal.location.city.name[LanguageUtil.getLanguage()]
      : ''
    );
  }

  return (
    <div className='row-wrapper'>
      <div className='svg-wrapper'>
        <Pin fill='#FF462F' stroke='#FF462F' />
      </div>
      <p>{
        props.deal.location.type === LocationTypes.Belgium
        ? t(LanguageResource.Common + ':location_belgium')
        : props.deal.location.type === LocationTypes.Url
          ? // @ts-ignore
            props.deal.location.name.split('/')[2]
          : props.showFullAddress
          ? props.deal.location.name +
            ' ' +
            (props.deal.location.postalCode ? props.deal.location.postalCode : '') +
            ' ' + getCity()
          : (props.deal.location.postalCode ? props.deal.location.postalCode : '') +
            ' ' + getCity()
      }
      </p>
    </div>
  );
};

export default DealInfoCity;