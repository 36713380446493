import { useEffect } from 'react';
import {
  Navigate,
  useLocation,
} from "react-router-dom";
import { useTranslation } from 'react-i18next';
// @ts-ignore
import LoadingOverlay from 'react-loading-overlay';

// Context
import { useAuthStore } from '../../context/Auth';
import { TranslationStoreProvider } from '../../context/Translation';

// Enums
import { RoutesLinks } from '../../enums/Routes';
import { StorageParams } from '../../enums/Storages';

// Utils
import JSUtil from '../../utils/JSUtil';

interface PropsInterface {
  children:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;  
  loadingLanguage: boolean,
  ignoredLoggedIn: boolean;
  link: {
    Link: string,
    Translations: string[]
  }
}

const AppRoute = (props: PropsInterface) => {
  const location = useLocation(),
    [authState] = useAuthStore();

  useEffect(() => {
    if (
      !JSUtil.isEmpty(location.pathname) &&
      ![
        RoutesLinks.Login.Link,
        RoutesLinks.Register.Link,
        RoutesLinks.ForgotPassword.Link,
        RoutesLinks.ResetPassword.Link,
      ].includes(location.pathname)
    ) {
      localStorage.setItem(StorageParams.Path, location.pathname);
    }
  }, [location.pathname]);

  return (
    <TranslationStoreProvider translation={useTranslation(props.link.Translations)}>
      {
        (!JSUtil.isEmpty(authState) && !authState.loggedIn && !props.ignoredLoggedIn && !authState.isLoading) 
        ? <Navigate to={RoutesLinks.Jobs.Link}  replace />
        : props.loadingLanguage
        ?
        <LoadingOverlay
          active={props.loadingLanguage}
          spinner
          styles={{
            wrapper: (base: any) => ({
            ...base,
            flex: 1,
            display: 'flex'
            })
          }}
        ></LoadingOverlay> 
        : props.children
      }
    </TranslationStoreProvider>
  );
}

export default AppRoute;