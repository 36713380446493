// Enums
import { InputTypes } from "../../../enums/Inputs";
import { LanguageResource } from "../../../enums/Languages";

// Interfaces
import { RelevantExperienceInterface } from "../../../interfaces/Job";

export const getRelevantExperienceInputs = (
  t: any,
  title: string,
  setTitle: (arg0: string) => void,
  location: string,
  setLocation: (arg0: string) => void,
  description: string,
  setDescription: (arg0: string) => void,
) => {
  return [
    {
      name: "title",
      type: InputTypes.Text,
      onChange: (val: string) => setTitle(val),
      value: title,
      label: t(LanguageResource.Jobs + ':claim_relevant_experience_title'),
      required: true,
    },
    {
      name: "location",
      type: InputTypes.Text,
      onChange: (val: string) => setLocation(val),
      value: location,
      label: t(LanguageResource.Jobs + ':claim_relevant_experience_location'),
      required: true,
    },
    {
      name: "description",
      type: InputTypes.Text,
      onChange: (val: string) => setDescription(val),
      value: description,
      label: t(LanguageResource.Jobs + ':claim_relevant_experience_description'),
      required: true,
    },
  ];
}

export const formatRelevantExperienceData = (
  relevantExperience: RelevantExperienceInterface | null = null,
  setTitle: (arg0: string) => void,
  setLocation: (arg0: string) => void,
  setDescription: (arg0: string) => void,
) => {
  setTitle(relevantExperience?.title || '');
  setLocation(relevantExperience?.location || '');
  setDescription(relevantExperience?.description || '');
}