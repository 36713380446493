// Enums
import { LocationTypes } from "../../../enums/Deals";

// Interfaces
import { DealInterface } from "../../../interfaces/Deal";
import { EventInterface } from "../../../interfaces/Event";

// Svgs
import { ReactComponent as Pin } from '../../../assets/svg/pin.svg';

// Utils
import LanguageUtil from '../../../utils/LanguageUtil';

interface PropsInterface {
  post: DealInterface | EventInterface;
  showFullAddress: boolean;
};

const InfoCity = (props: PropsInterface) => {

  const getCity = () => {
    return (
      props.post.location.city && props.post.location.city.name
      // @ts-ignore
      ? props.post.location.city.name[LanguageUtil.getLanguage()]
      : ''
    );
  }

  return (
    <div className='row-wrapper'>
      <div className='svg-wrapper'>
        <Pin fill='#FF462F' stroke='#FF462F' />
      </div>
      <p>{
        props.post.location.type === LocationTypes.Url
        // @ts-ignore
        ? props.post.location.name.split('/')[2]
        : props.showFullAddress
        ? props.post.location.name +
          ' ' +
          (props.post.location.postalCode ? props.post.location.postalCode : '') +
          ' ' + getCity()
        : (props.post.location.postalCode ? props.post.location.postalCode : '') +
          ' ' + getCity()
      }
      </p>
    </div>
  );
};

export default InfoCity;