import ApiUtil from '../utils/ApiUtil';
import { getRequest, postRequest } from './api';

export const claimApi = (args, callback) => {
  const endpoint = '/v1/claims',
    token = args.token,
    headers = {};
  postRequest({endpoint, token}, headers, args.body, (err, res) => {
    ApiUtil.handleReponse(err, res, callback);
  });
};

export const getClaimsApi = (args, callback) => {
  const endpoint = '/v1/claims',
    token = args.token,
    qs = `type=${args.type}`,
    headers = {'Cache-Control': 'no-cache, no-store, must-revalidate'};

  getRequest({endpoint, token, qs}, headers, (err, res) => {
    ApiUtil.handleReponse(err, res, callback);
  });
};

export const updateSwipeAmount = (args, callback) => {
  const endpoint = '/v1/swipe',
    token = args.token,
    headers = {};
  postRequest({endpoint, token}, headers, args.body, (err, res) => {
    ApiUtil.handleReponse(err, res, callback);
  });
};