// Styles
import './Button.scss';

interface PropsInterface {
  onPressFilter: (arg0: any) => void;
  data: any;
  value: any;
  label: string;
}

const SearchFilterOptionButton = (props: PropsInterface) => {
  return (
    <div className={props.value === props.data ? 'search-filter-option-button-active' : 'search-filter-option-button-inactive'} key={props.data} onClick={props.onPressFilter}>
      <p>{props.label}</p>
    </div>
  );
};

export default SearchFilterOptionButton;
