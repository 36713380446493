// Components
import Input from '../../Form/Inputs/Input';

// Context
import { useTranslationStore } from '../../../../context/Translation';

// Enums
import { InputTypes } from '../../../../enums/Inputs';
import { LanguageResource } from '../../../../enums/Languages';

// Styles
import './Date.scss';

interface PropsInterface {
  fromDate: string;
  setFromDate: (arg0: string) => void;
  toDate: string;
  setToDate: (arg0: string) => void;
}

const SearchFilterDate = (props: PropsInterface) => {
  const [t] = useTranslationStore();
  return (
    <div className='search-filter-date-wrapper'>
      <Input
        input={{
          name: 'fromDate',
          type: InputTypes.Date,
          value: props.fromDate,
          onChange: (val: string) => props.setFromDate(val),
          maxDate: false,
          label: t(LanguageResource.Common + ':date_from')
        }}
      />
      <Input
        input={{
          name: 'toDate',
          type: InputTypes.Date,
          value: props.toDate,
          onChange: (val: string) => props.setToDate(val),
          maxDate: false,
          label: t(LanguageResource.Common + ':date_to')
        }}
      />
    </div>
  );
};

export default SearchFilterDate;
