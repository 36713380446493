import {useEffect, useState} from 'react';
import Swal, {SweetAlertIcon} from 'sweetalert2';

// Context
import { useTranslationStore } from '../../../../../context/Translation';

// Enums
import { DropdownOptions } from '../../../../../enums/Inputs'
import { SwalIcons, SwalTitle } from '../../../../../enums/Swal';

// Interfaces
import { InputInterface, OptionInterface } from '../../../../../interfaces/Input';
import JSUtil from '../../../../../utils/JSUtil';

interface PropsInterface {
  input: InputInterface,
};

const DropdownInput = (props: PropsInterface) => {
  const [options, setOptions] = useState<OptionInterface[]>([]),
    [t] = useTranslationStore();

  useEffect(() => {
    if (props.input.setLoading) props.input.setLoading(true);

    switch (props.input.options?.type) {
      case DropdownOptions.Api:
        props.input.options.dataGetter((err: any, res: any) => {
          if (err) {
            Swal.fire({
              icon: SwalIcons.Error as SweetAlertIcon,
              title: t(SwalTitle.Error),
              text: err,
            });
          } else {
            let result = res;
            if (props.input.options?.result) {
              const filterArray = props.input.options?.result.split('.');
              filterArray.forEach((filter: string) => result = result[filter]);
            }
            setOptions(result);
            setValue(props.input.value, result);
          }
          if (props.input.setLoading) props.input.setLoading(false);
        });
      break;
      case DropdownOptions.Options:
        const result = props.input.options.dataGetter;
        setOptions(result);
        setValue(props.input.value, result);

      break;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.input?.options?.dataGetter]);

  const setValue = (value:any, result:any) => {
    const filteredId = result.find((r: OptionInterface) => r._id == value);
    if ((!value || JSUtil.isEmpty(filteredId)) && !JSUtil.isEmpty(result)) {
      props.input.onChange(result[0]._id);
    }
  }

  const renderOptions = () => {
    return options.map(option => {
      return <option key={option._id} value={option._id}>
          {option.name}
        </option>
    });
  }

  return (
    <div>
      <select onChange={event => props.input.onChange(event.target.value)} value={props.input.value}>
        {renderOptions()}
      </select>    
    </div>
  );
};

export default DropdownInput;
