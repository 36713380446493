import { useState, useEffect } from 'react';
import {
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import branch from 'branch-sdk';
import Swal, { SweetAlertIcon } from 'sweetalert2';

// Components
import ForgotPasswordPage from '../ForgotPassword';
import DealsPage from '../Deals/';
import DealsDetailPage from '../Deals/Detail';
import EventsPage from '../Events/';
import EventsDetailPage from '../Events/Detail';
import JobsDetailClaimsCreatePage from '../Jobs/Detail/Claims/Create';
import JobsDetailPage from '../Jobs/Detail';
import JobsPage from '../Jobs/';
import LoginPage from '../Login/';
import NewsDetailPage from '../News/Detail';
import NewsPage from '../News/';
import NotificationsPage from '../Notifications/';
import ProfilePage from '../Profile/';
import RegisterPage from '../Register/';
import ResetPasswordPage from '../ResetPassword';
import useWindowDimensions from '../Common/Layout/User/WindowDismensions';
import AppRoute from './AppRoute';

// Config
import ENV from '../../config/env.json';

// Context
import { AuthStoreProvider } from '../../context/Auth';
import { TranslationStoreProvider } from '../../context/Translation';

// Enums
import { AppInfo, Styles } from '../../enums/App';
import { Languages, LanguageResource } from '../../enums/Languages';
import { OriginTracking, RoutesLinks } from '../../enums/Routes';
import { StorageParams } from '../../enums/Storages';
import { SwalIcons } from '../../enums/Swal';

// Utils
import JSUtil from '../../utils/JSUtil';
import LanguageUtil from '../../utils/LanguageUtil';

function App() {
  const navigate = useNavigate();
  const {width} = useWindowDimensions(),
    [loadingLanguage, setLoadingLanguage] = useState(true),
    {t} = useTranslation(LanguageResource.Common);

  useEffect(() => {
    const localStorageLanguage = localStorage.getItem(StorageParams.Language);
    if (localStorageLanguage) {
      LanguageUtil.setLanguage(localStorageLanguage);
      setLoadingLanguage(false);
    } else {
      LanguageUtil.setLanguage(Languages.EN);
      setLoadingLanguage(false);
    }

    const localStorageUserId = localStorage.getItem(StorageParams.UserId);
    if (!localStorageUserId) {
      const randLetter = String.fromCharCode(65 + Math.floor(Math.random() * 26)),
         id = randLetter + Date.now();      

      localStorage.setItem(StorageParams.UserId, id);
    }

    if (width < Styles.MobileWidth) {
      Swal.fire({
        title: t(LanguageResource.Common + ':mobile_app_popup_title'),
        text: t(LanguageResource.Common + ':mobile_app_popup_text'),
        icon: SwalIcons.Warning as SweetAlertIcon,
        showCancelButton: true,
        cancelButtonText: t(LanguageResource.Common + ':mobile_app_popup_cancel_button'),
        confirmButtonText: t(LanguageResource.Common + ':mobile_app_popup_confirm_button')
      }).then(result => {
        if (result.isConfirmed) {
          window.open(AppInfo.BranchIoLink, '_blank');
        }
      });
    }

    initializeBranch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initializeBranch = () => {
    branch.init(ENV.BRANCH_IO, {}, (err, res) => {
      if (!JSUtil.isEmpty(res?.data_parsed)) {
        const data: any = res?.data_parsed;
        if (!JSUtil.isEmpty(data?.jobKey)) {
          navigate(
            RoutesLinks.JobsDetail.Link.replace(':' + RoutesLinks.JobsDetail.Params.JobKey, data.jobKey as string),
            {
              state: {
                origin: OriginTracking.Url,
                info: data?.$marketing_title,
              }
            }
          );
        } else if (!JSUtil.isEmpty(data?.dealKey)) {
          navigate(
            RoutesLinks.DealsDetail.Link.replace(':' + RoutesLinks.DealsDetail.Params.DealKey, data.dealKey as string),
            {
              state: {
                origin: OriginTracking.Url,
                info: data?.$marketing_title,
              }
            }
          );
        } else if (!JSUtil.isEmpty(data?.eventKey)) {
          navigate(
            RoutesLinks.EventsDetail.Link.replace(':' + RoutesLinks.EventsDetail.Params.EventKey, data.eventKey as string),
            {
              state: {
                origin: OriginTracking.Url,
                info: data?.$marketing_title,
              }
            }
          );
        } else if (!JSUtil.isEmpty(data?.newsKey)) {
          navigate(
            RoutesLinks.NewsDetail.Link.replace(':' + RoutesLinks.NewsDetail.Params.NewsKey, data.newsKey as string),
            {
              state: {
                origin: OriginTracking.Url,
                info: data?.$marketing_title,
              }
            }
          );
        } else if (!JSUtil.isEmpty(data?.token)) {
          navigate(
            RoutesLinks.ResetPassword.Link.replace(':' + RoutesLinks.ResetPassword.Params.Token, data.token as string),
            {
              state: {
                origin: OriginTracking.Url,
                info: data?.$marketing_title,
              }
            }
          );
        } else if (!JSUtil.isEmpty(data?.deals)) {
          navigate(RoutesLinks.Deals.Link);
        } else if (!JSUtil.isEmpty(data?.jobs)) {
          navigate(RoutesLinks.Jobs.Link);
        } else if (!JSUtil.isEmpty(data?.events)) {
          navigate(RoutesLinks.Events.Link);
        } else if (!JSUtil.isEmpty(data?.news)) {
          navigate(RoutesLinks.News.Link);
        }

        if (!JSUtil.isEmpty(data?.$marketing_title) && data?.$marketing_title !== localStorage.getItem(StorageParams.TrackingCompany)) {
          localStorage.setItem(StorageParams.TrackingCompany, data.$marketing_title);
        }
      }
    });
  }

  return (
    <div className="App">
        <AuthStoreProvider>
          <Routes>
            <Route 
              path={RoutesLinks.Login.Link} 
              element={
                <TranslationStoreProvider translation={useTranslation(RoutesLinks.Login.Translations)}>  
                  <LoginPage navigate={navigate} />
                </TranslationStoreProvider>      
              } 
            />
            <Route 
              path={RoutesLinks.Register.Link} 
              element={
                <TranslationStoreProvider translation={useTranslation(RoutesLinks.Register.Translations)}>  
                  <RegisterPage navigate={navigate} />
                </TranslationStoreProvider>      
              } 
            />
            <Route 
              path={RoutesLinks.ForgotPassword.Link} 
              element={
                <TranslationStoreProvider translation={useTranslation(RoutesLinks.ForgotPassword.Translations)}>  
                  <ForgotPasswordPage navigate={navigate} />
                </TranslationStoreProvider>      
              } 
            />
            <Route 
              path={RoutesLinks.ResetPassword.Link} 
              element={
                <TranslationStoreProvider translation={useTranslation(RoutesLinks.ResetPassword.Translations)}>  
                  <ResetPasswordPage navigate={navigate} />
                </TranslationStoreProvider>      
              } 
            />
            <Route
              path={RoutesLinks.Notifications.Link}
              element={
                <AppRoute 
                  ignoredLoggedIn={false}
                  link={RoutesLinks.Notifications}
                  loadingLanguage={loadingLanguage}
                >
                  <NotificationsPage navigate={navigate} title={RoutesLinks.Notifications.Title} />
                </AppRoute>
              }
            />
            <Route
              path={RoutesLinks.Jobs.Link}
              element={
                <AppRoute 
                  ignoredLoggedIn={true}
                  link={RoutesLinks.Jobs}
                  loadingLanguage={loadingLanguage}
                >
                  <JobsPage navigate={navigate} title={RoutesLinks.Jobs.Title} />
                </AppRoute>
              }
            />
            <Route
              path={RoutesLinks.JobsDetail.Link}
              element={
                <AppRoute 
                  ignoredLoggedIn={true}
                  link={RoutesLinks.JobsDetail}
                  loadingLanguage={loadingLanguage}
                >
                  <JobsDetailPage navigate={navigate} />
                </AppRoute>
              }
            />
            <Route
              path={RoutesLinks.Resume.Link}
              element={
                <AppRoute 
                  ignoredLoggedIn={false}
                  link={RoutesLinks.Resume}
                  loadingLanguage={loadingLanguage}
                >
                  <JobsDetailClaimsCreatePage navigate={navigate} title={RoutesLinks.Resume.Title} />
                </AppRoute>
              }
            />
            <Route
              path={RoutesLinks.Deals.Link}
              element={
                <AppRoute 
                  ignoredLoggedIn={true}
                  link={RoutesLinks.Deals}
                  loadingLanguage={loadingLanguage}
                >
                  <DealsPage navigate={navigate} title={RoutesLinks.Deals.Title} />
                </AppRoute>
              }
            />
            <Route
              path={RoutesLinks.DealsDetail.Link}
              element={
                <AppRoute 
                  ignoredLoggedIn={true}
                  link={RoutesLinks.DealsDetail}
                  loadingLanguage={loadingLanguage}
                >
                  <DealsDetailPage navigate={navigate} />
                </AppRoute>
              }
            />
            <Route
              path={RoutesLinks.Events.Link}
              element={
                <AppRoute 
                  ignoredLoggedIn={true}
                  link={RoutesLinks.Events}
                  loadingLanguage={loadingLanguage}
                >
                    <EventsPage navigate={navigate} title={RoutesLinks.Events.Title} />
                </AppRoute>
              }
            />
            <Route
              path={RoutesLinks.EventsDetail.Link}
              element={
                <AppRoute 
                  ignoredLoggedIn={true}
                  link={RoutesLinks.EventsDetail}
                  loadingLanguage={loadingLanguage}
                >
                  <EventsDetailPage navigate={navigate} />
                </AppRoute>
              }
            />
            <Route
              path={RoutesLinks.News.Link}
              element={
                <AppRoute 
                  ignoredLoggedIn={true}
                  link={RoutesLinks.News}
                  loadingLanguage={loadingLanguage}
                >
                  <NewsPage navigate={navigate} title={RoutesLinks.News.Title} />
                </AppRoute>
              }
            />
            <Route
              path={RoutesLinks.NewsDetail.Link}
              element={
                <AppRoute 
                  ignoredLoggedIn={true}
                  link={RoutesLinks.NewsDetail}
                  loadingLanguage={loadingLanguage}
                >
                  <NewsDetailPage navigate={navigate} />
                </AppRoute>
              }
            />
            <Route
              path={RoutesLinks.ProfileAccount.Link}
              element={
                <AppRoute 
                  ignoredLoggedIn={false}
                  link={RoutesLinks.ProfileAccount}
                  loadingLanguage={loadingLanguage}
                >
                    <ProfilePage navigate={navigate} title={RoutesLinks.ProfileAccount.Title} state={RoutesLinks.ProfileAccount.Params.State}/>
                </AppRoute>

              }
            />
            <Route
              path={RoutesLinks.ProfileCV.Link}
              element={
                <AppRoute 
                  ignoredLoggedIn={false}
                  link={RoutesLinks.ProfileCV}
                  loadingLanguage={loadingLanguage}
                >
                  <ProfilePage navigate={navigate} title={RoutesLinks.ProfileCV.Title} state={RoutesLinks.ProfileCV.Params.State}/>
                </AppRoute>

              }
            />
            <Route
              path={RoutesLinks.ProfileJobs.Link}
              element={
                <AppRoute 
                  ignoredLoggedIn={false}
                  link={RoutesLinks.ProfileJobs}
                  loadingLanguage={loadingLanguage}
                >
                  <ProfilePage navigate={navigate} title={RoutesLinks.ProfileJobs.Title} state={RoutesLinks.ProfileJobs.Params.State}/>
                </AppRoute>
              }
            />
            <Route
              path={RoutesLinks.ProfileJobsDetail.Link}
              element={
                <AppRoute 
                  ignoredLoggedIn={false}
                  link={RoutesLinks.ProfileJobsDetail}
                  loadingLanguage={loadingLanguage}
                >
                    <JobsDetailPage navigate={navigate} />
                </AppRoute>

              }
            />
            <Route
              path={RoutesLinks.ProfileDeals.Link}
              element={
                <AppRoute 
                  ignoredLoggedIn={false}
                  link={RoutesLinks.ProfileDeals}
                  loadingLanguage={loadingLanguage}
                >
                    <ProfilePage navigate={navigate} title={RoutesLinks.ProfileDeals.Title} state={RoutesLinks.ProfileDeals.Params.State}/>
                </AppRoute>

              }
            />
            <Route
              path={RoutesLinks.ProfileDealsDetail.Link}
              element={
                <AppRoute 
                  ignoredLoggedIn={false}
                  link={RoutesLinks.ProfileDealsDetail}
                  loadingLanguage={loadingLanguage}
                >
                    <DealsDetailPage navigate={navigate} />
                </AppRoute>

              }
            />
            <Route
              path={RoutesLinks.ProfileEvents.Link}
              element={
                <AppRoute 
                  ignoredLoggedIn={false}
                  link={RoutesLinks.ProfileEvents}
                  loadingLanguage={loadingLanguage}
                >
                    <ProfilePage navigate={navigate} title={RoutesLinks.ProfileEvents.Title} state={RoutesLinks.ProfileEvents.Params.State}/>
                </AppRoute>

              }
            />
            <Route
              path={RoutesLinks.ProfileEventsDetail.Link}
              element={
                <AppRoute 
                  ignoredLoggedIn={false}
                  link={RoutesLinks.ProfileEventsDetail}
                  loadingLanguage={loadingLanguage}
                >
                    <EventsDetailPage navigate={navigate} />
                </AppRoute>

              }
            />
            <Route
              path={RoutesLinks.ProfileNews.Link}
              element={
                <AppRoute 
                  ignoredLoggedIn={false}
                  link={RoutesLinks.ProfileNews}
                  loadingLanguage={loadingLanguage}
                >
                    <ProfilePage navigate={navigate} title={RoutesLinks.ProfileNews.Title} state={RoutesLinks.ProfileNews.Params.State}/>
                </AppRoute>

              }
            />
            <Route
              path={RoutesLinks.ProfileNewsDetail.Link}
              element={
                <AppRoute 
                  ignoredLoggedIn={false}
                  link={RoutesLinks.ProfileNewsDetail}
                  loadingLanguage={loadingLanguage}
                >
                    <NewsDetailPage navigate={navigate} />
                </AppRoute>

              }
            />
          </Routes>
        </AuthStoreProvider>
    </div>
  );
}

export default App;