export const ClaimStates = {
  CompleteProfile: 'completeProfile',
  InApp: 'inApp',
  Email: 'email',
  Coupon: 'coupon',
  Url: 'url',
  Swipe: 'swipe',
  Success: 'success',
};

export const DealTypes = {
  Offline: 'offline',
  Online: 'online',
};

export const DealDiscountTypes = {
  BuyGetFree: 'buyGetFree',
  Free: 'free',
  Price: 'price',
  Procent: 'procent',
  SpendFree: 'spendFree',
  SpendPrice: 'spendPrice',
  SpendProcent: 'spendProcent',
};

export const LocationTypes = {
  Belgium: 'belgium',
  Location: 'location',
  Url: 'url',
}

export const ListLimit = 18;