import {useState} from 'react';
import { NavigateFunction } from 'react-router-dom';
// @ts-ignore
import LoadingOverlay from 'react-loading-overlay';
import Swal, { SweetAlertIcon } from 'sweetalert2';

// Components
import CVOverview from './Overview';
import Form from '../../Common/Form';
import { formatEducationData, getEducationInputs } from './Education';
import { formatRelevantExperienceData, getRelevantExperienceInputs } from './RelevantExperience';
import { formatWorkExperienceData, getWorkExperienceInputs } from './WorkExperience';

// Context
import { updateUser, useAuthStore } from '../../../context/Auth';
import { useTranslationStore } from '../../../context/Translation';

// Enums
import { ExperienceTypes } from '../../../enums/Jobs';
import { LanguageResource } from '../../../enums/Languages';
import { SwalIcons, SwalTitle } from '../../../enums/Swal';

// Interfaces
import { EducationInterface, RelevantExperienceInterface, WorkExperienceInterface } from '../../../interfaces/Job';

// Styles
import './index.scss';

// Utils
import DateUtil from '../../../utils/DateUtil';

interface PropsInterface {
  navigate: NavigateFunction,
}

const Account = (props: PropsInterface) => {
  const [authState, authDispatch] = useAuthStore(),
    [t] = useTranslationStore(),
    [addType, setAddType] = useState<string>(''),
    [typeIndex, setTypeIndex] = useState<number | null>(null),
    [levelEducation, setLevelEducation] = useState(''),
    [fieldOfStudy, setFieldOfStudy] = useState(''),
    [collegeOrUniversity, setCollegeOrUniversity] = useState(''),
    [schoolLocation, setSchoolLocation] = useState(''),
    [timePeriod, setTimePeriod] = useState(false),
    [title, setTitle] = useState(''),
    [company, setCompany] = useState(''),
    [city, setCity] = useState(''),
    [location, setLocation] = useState(''),
    [description, setDescription] = useState(''),
    [fromDate, setFromDate] = useState<string>(DateUtil.formatInputDate(DateUtil.nowYearsBack(3))),
    [toDate, setToDate] = useState<string>(DateUtil.formatInputDate(DateUtil.now())),
    [educations, setEducations] = useState<EducationInterface[]>(
      authState.cv?.educations || [],
    ),
    [workExperiences, setWorkExperiences] = useState<WorkExperienceInterface[]>(
      authState.cv?.workExperiences || [],
    ),
    [relevantExperiences, setRelevantExperiences] = useState<
      RelevantExperienceInterface[]
    >(authState.cv?.relevantExperiences || []),
    [isLoading, setIsLoading] = useState<boolean>(false);

  const renderCV = () => {
    switch (addType) {
      case ExperienceTypes.Education:
        return (<>
          <Form 
            inputs={getEducationInputs(
              t,
              levelEducation,
              setLevelEducation,
              fieldOfStudy,
              setFieldOfStudy,
              collegeOrUniversity,
              setCollegeOrUniversity,
              schoolLocation,
              setSchoolLocation,
              timePeriod,
              setTimePeriod,
              fromDate,
              setFromDate,
              toDate,
              setToDate
            )}
            onSubmit={() => addEducation()}
          />
          <div className="primary-button-wrapper" onClick={() => addEducation()}>
            <p className="primary-button-text">{t(LanguageResource.Common + ':save')}</p>
          </div>
        </>)
      case ExperienceTypes.WorkExperience:
        return (<>
          <Form 
            inputs={getWorkExperienceInputs(
              t,
              title,
              setTitle,
              company,
              setCompany,
              city,
              setCity,
              timePeriod,
              setTimePeriod,
              fromDate,
              setFromDate,
              toDate,
              setToDate,
              description,
              setDescription
            )}
            onSubmit={() => addWorkExperience()}
          />
          <div className="primary-button-wrapper" onClick={() => addWorkExperience()}>
            <p className="primary-button-text">{t(LanguageResource.Common + ':save')}</p>
          </div>
        </>)
      case ExperienceTypes.RelevantExperience:
        return (<>
          <Form 
            inputs={getRelevantExperienceInputs(
              t,
              title,
              setTitle,
              location,
              setLocation,
              description,
              setDescription
            )}
            onSubmit={() => addRelevantExperience()}
          />
          <div className="primary-button-wrapper" onClick={() => addRelevantExperience()}>
            <p className="primary-button-text">{t(LanguageResource.Common + ':save')}</p>
          </div>
        </>)
      default:
        return (
          <>
            <CVOverview
              educations={educations}
              setEducations={setEducations}
              workExperiences={workExperiences}
              setWorkExperiences={setWorkExperiences}
              relevantExperiences={relevantExperiences}
              setRelevantExperiences={setRelevantExperiences}
              onPress={onPress}
            />
            <div className="primary-button-wrapper" onClick={() => handleSubmit()}>
              <p className="primary-button-text">{t(LanguageResource.Common + ':save')}</p>
            </div>
          </>
        );
    }
  };

  const handleSubmit = () => {
    setIsLoading(true);
    updateUser(
      authDispatch,
      {
        token: authState.token,
        body: {
          _id: authState.id,
          cv: {
            educations,
            workExperiences,
            relevantExperiences,
          },
        },
      },
      (err: any) => {
        setIsLoading(false);
        if (err) {
          Swal.fire({
            icon: SwalIcons.Error as SweetAlertIcon,
            title: t(SwalTitle.Error),
            text: err,
          });
        } else {
          Swal.fire({
            icon: SwalIcons.Success as SweetAlertIcon,
            title: t(SwalTitle.Success),
            text: t(LanguageResource.Common + ':profile_updated'),
          });
        }
      },
    )
  }

  const onPress = (type: string, index: number | null) => {
    setAddType(type);
    setTypeIndex(index);
    switch(type) {
      case ExperienceTypes.Education:
        setEducationData(educations[index as number]);
      break;
      case ExperienceTypes.RelevantExperience:
        setRelevantExperienceData(relevantExperiences[index as number]);
      break;
      case ExperienceTypes.WorkExperience:
        setWorkExperienceData(workExperiences[index as number]);
      break;
    }
  };

  const addEducation = () => {
    const newEducation = {
      levelEducation,
      fieldOfStudy,
      collegeOrUniversity,
      schoolLocation,
      timePeriod,
      fromDate,
      toDate,
    };
    if (typeIndex !== null) {
      educations[typeIndex] = newEducation;
    } else {
      educations.push(newEducation);
    }
    setEducations(educations);
    setEducationData();
    setAddType('');
  }

  const addRelevantExperience = () => {
    const newRelevantExperience = {
      title,
      location,
      description
    };
    if (typeIndex !== null) {
      relevantExperiences[typeIndex] = newRelevantExperience;
    } else {
      relevantExperiences.push(newRelevantExperience);
    }
    setRelevantExperiences(relevantExperiences);
    setRelevantExperienceData();
    setAddType('');
  }

  const addWorkExperience = () => {
    const newWorkExperience = {
      title,
      company,
      city,
      timePeriod,
      fromDate,
      toDate,
      description
    };
    if (typeIndex !== null) {
      workExperiences[typeIndex] = newWorkExperience;
    } else {
      workExperiences.push(newWorkExperience);
    }
    setWorkExperiences(workExperiences);
    setWorkExperienceData();
    setAddType('');
  }

  const setEducationData = (education: EducationInterface | null = null) => {
    formatEducationData(
      education,
      setLevelEducation,
      setFieldOfStudy,
      setCollegeOrUniversity,
      setSchoolLocation,
      setTimePeriod,
      setFromDate,
      setToDate
    );
  } 

  const setRelevantExperienceData = (relevantExperience: RelevantExperienceInterface | null = null) => {
    formatRelevantExperienceData(
      relevantExperience,
      setTitle,
      setLocation,
      setDescription
    );
  }

  const setWorkExperienceData = (workExperience: WorkExperienceInterface | null = null) => {
    formatWorkExperienceData(
      workExperience,
      setTitle,
      setCompany,
      setCity,
      setTimePeriod,
      setFromDate,
      setToDate,
      setDescription
    );
  }

  return ( 
    <LoadingOverlay
      active={isLoading}
      spinner
    >  
    <div className='create-wrapper'>
      <p className='text-weight-bold text-size-large'>{t(LanguageResource.Profile + ':edit_cv_title')}</p>
      {renderCV()}
    </div>
    </LoadingOverlay>
  );
};

export default Account;
