import {trackFilterApi} from '../../../../api/tracking';

export const saveFilterOptions = (
  token: string,
  filters: any,
  isFiltering: boolean,
  results: number,
  type: string,
  callback?: (err: any) => void,
) => {
  trackFilterApi(
    {
      token: token,
      body: {
        filters,
        isFiltering,
        results,
        type,
      },
    },
    (err: any) => {
      if (callback) {
        return callback(err);
      }
    },
  );
};
