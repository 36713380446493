// Context
import { useTranslationStore } from '../../../context/Translation';

// Enums
import { LanguageResource } from "../../../enums/Languages";

const Empty = () => {
  const [t] = useTranslationStore();
  return (
    <div>
      <p className="text-align-center text-weight-bold">{t(LanguageResource.Common + ':content_soon')}</p>
    </div>
  );
};

export default Empty;
