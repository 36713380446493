import {RefObject} from 'react';

// Components
import { renderInputs } from './Inputs';

// Interfaces
import { InputInterface } from '../../../interfaces/Input';

interface PropsInterface {
  forwardedRef?: RefObject<HTMLFormElement>;
  class?: string;
  onSubmit: () => void;
  inputs: (InputInterface|InputInterface[])[]
}

const Form = (props: PropsInterface) => {
  return (
    <form
      className={props.class}
      onSubmit={(e) => {
        e.preventDefault();
        props.onSubmit();
      }}
      ref={props.forwardedRef ? props.forwardedRef : null}
    >
      {renderInputs(props.inputs)}
    </form>
  );
};

export default Form;