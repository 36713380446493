// Config
import ENV from '../../../config/env.json';

// Enums
import { YesNo } from '../../../enums/YesNo';

// Interfaces
import { CompanyInterface } from "../../../interfaces/Company";

// Utils
import JSUtil from '../../../utils/JSUtil';

interface PropsInterface {
  company: CompanyInterface
}

const JobInfoLogo = (props: PropsInterface) => {
  return (
    <div>
      {
        props.company.settings?.showCompanyLogoOnJobDetail !== YesNo.No &&
          (
            (
              props.company.settings?.useFromCompanyLogoOnJobDetail === YesNo.Yes &&
              !JSUtil.isEmpty(props.company.imageFor)
            ) ||
            (
              props.company.settings?.useFromCompanyLogoOnJobDetail !== YesNo.Yes &&
              !JSUtil.isEmpty(props.company.image)
            )
          ) 
          ? <img 
              src={
                ENV.BASE_IMAGE_URL + (props.company.settings?.useFromCompanyLogoOnJobDetail === YesNo.Yes
                ? props.company.imageFor
                : props.company.image)}
              alt='company logo'
            />
        : null
      }
    </div>
  );
}

export default JobInfoLogo;