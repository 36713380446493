import { NavigateFunction } from 'react-router-dom';

// Components
import Empty from '../../Common/List/Empty';
import ShowMore from '../../Common/List/ShowMore';
import EventsPreview from './Preview';

// Enums
import { ListLimit } from '../../../enums/Deals';

// Interfaces
import { EventInterface } from '../../../interfaces/Event';

// Styles
import './index.scss';

// Utils
import JSUtil from '../../../utils/JSUtil';

interface PropsInterface {
  events: EventInterface[],
  amount: number,
  limit: number,
  setLimit: (arg0: number) => void;
  navigate: NavigateFunction,
}

const EventsList = (props: PropsInterface) => {
  return (
    <div className="events-wrapper">
      {
        !JSUtil.isEmpty(props.events)
        ?
        <>
          {
            props.events.map((event, index) => 
            <EventsPreview 
              key={index}
              event={event}
              navigate={props.navigate} 
            />
            )
          }
          <div className='clear-float'/>
          <ShowMore
            limit={props.limit}
            setLimit={props.setLimit}
            plusLimit={ListLimit}
            amount={props.amount}
          />
        </>
        : <Empty />
      }

    </div>
  );
}

export default EventsList;