import { useState } from 'react';

// Components
import SearchFilter from '../../Common/Search/Filter/';
import SearchFilterCategory from '../../Common/Search/Filter/Category';
import SearchFilterDistance from '../../Common/Search/Filter/Distance';
import SearchFilterLocation from '../../Common/Search/Filter/Location';
import SearchFilterOptionButton from '../../Common/Search/Filter/Options/Button';
import SearchFilterOptionWrapper from '../../Common/Search/Filter/Options/Wrapper';
import SearchFilterSortBy from '../../Common/Search/Filter/SortBy';
import SearchFilterTitle from '../../Common/Search/Filter/Title';

// Context
import { useTranslationStore } from '../../../context/Translation';

// Enums
import { SortBy } from '../../../enums/Filter';
import { LanguageResource } from '../../../enums/Languages';
import { JobTypes } from '../../../enums/Jobs';

// Interfaces
import { CategoryInterface } from '../../../interfaces/Category';
import { LocationInterface } from '../../../interfaces/Location';

interface PropsInterface {
  cleanSearch: () => void;
  location: LocationInterface;
  setLocation: (arg0: LocationInterface) => void;
  locations: LocationInterface[];
  distance: number;
  setDistance: (arg0: number) => void;
  categories: CategoryInterface[];
  filteredCategories: string[];
  onPressCategory: (arg0: string) => void;
  jobType: string;
  setJobType: (arg0: string) => void;
  sortBy: string;
  setSortBy: (arg0: string) => any;
  filterActive: boolean;
  amount: number;
}

const FilterSideBar = (props: PropsInterface) => {
  const [t] = useTranslationStore(),
   [showLocation, setShowLocation] = useState(true),
   [showDistance, setShowDistance] = useState(true),
   [showCategory, setShowCategory] = useState(true),
   [showJobType, setShowJobType] = useState(true),
   [showSortBy, setShowSortBy] = useState(true);

  const onPressClean = () => {
    props.cleanSearch();
  };

  const onPressJobType = (type: string) => {
    if (props.jobType === type) {
      props.setJobType('');
    } else {
      props.setJobType(type);
    }
  };

  const renderJobsType = (type: string) => {
    return (
      <SearchFilterOptionButton
        key={type}
        data={type}
        value={props.jobType}
        onPressFilter={() => onPressJobType(type)}
        label={t(LanguageResource.Jobs + ':type_' + type)}
      />
    );
  };

  return (
    <SearchFilter
      filterActive={props.filterActive}
      amount={props.amount}
      onPressClean={onPressClean}
    >
      <div>
        <SearchFilterTitle
          title={t(LanguageResource.Common + ':location')}
          show={showLocation}
          onPressShow={() => setShowLocation(!showLocation)}
        />
        {
          showLocation
          ?
          <SearchFilterLocation
            location={props.location}
            setLocation={props.setLocation}
            locations={props.locations}
          />
          : null
        }
      </div>
      <SearchFilterTitle
        title={t(LanguageResource.Common + ':distance')}
        show={showDistance}
        onPressShow={() => setShowDistance(!showDistance)}
      />
      {
        showDistance
        ?
        <SearchFilterDistance
          distance={props.distance}
          setDistance={props.setDistance}
        />
        : null
      }
      <SearchFilterTitle 
        title={t(LanguageResource.Common + ':categories')}
        show={showCategory}
        onPressShow={() => setShowCategory(!showCategory)}
      />
      {
        showCategory
        ?
        <SearchFilterCategory
          categories={props.categories}
          filteredCategories={props.filteredCategories}
          onPressCategory={props.onPressCategory}
        />
        : null
      }
      <SearchFilterTitle 
        title={t(LanguageResource.Jobs + ':types')}
        show={showJobType}
        onPressShow={() => setShowJobType(!showJobType)}
      />
      {
        showJobType
        ?
        <SearchFilterOptionWrapper>
          {renderJobsType(JobTypes.Stage)}
          {renderJobsType(JobTypes.Studentenjob)}
          {renderJobsType(JobTypes.Tastejob)}
          {renderJobsType(JobTypes.Startersjob_Bachelor)}
          {renderJobsType(JobTypes.Startersjob_Master)}
          {renderJobsType(JobTypes.VolunteerJob)}
        </SearchFilterOptionWrapper>
        : null
      }
      <SearchFilterTitle
        title={t(LanguageResource.Common + ':sort_by')}
        show={showSortBy}
        onPressShow={() => setShowSortBy(!showSortBy)}
      />
      {
        showSortBy
        ?
        <SearchFilterSortBy
          types={[SortBy.Distance, SortBy.New]}
          sortBy={props.sortBy}
          setSortBy={props.setSortBy}
        />
        : null
      }
    </SearchFilter>
  );
};

export default FilterSideBar;
