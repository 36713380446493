import ApiUtil from '../utils/ApiUtil';
import {getRequest, postRequest} from './api';

export const getUserStudentCardApi = (args, callback) => {
  const endpoint = '/v1/users/studentcard',
    token = args.token,
    headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  getRequest({endpoint, token}, headers, (err, res) => {
    ApiUtil.handleReponse(err, res, callback);
  });
};

export const updateUserApi = (args, callback) => {
  const endpoint = '/v1/users/update',
    token = args.token,
    headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  postRequest({endpoint, token}, headers, args.body, (err, res) => {
    ApiUtil.handleReponse(err, res, callback);
  });
};

export const deActivateUserApi = (args, callback) => {
  const endpoint = '/v1/users/deactivate',
    token = args.token,
    headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  postRequest({endpoint, token}, headers, args.body, (err, res) => {
    ApiUtil.handleReponse(err, res, callback);
  });
}