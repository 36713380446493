import ApiUtil from '../utils/ApiUtil';
import {getRequest} from './api';

export const getNotificationsApi = (args, callback, dispatch) => {
  const endpoint = '/v1/notifications/get',
    token = args.token,
    headers = {'Cache-Control': 'no-cache, no-store, must-revalidate'};

  getRequest({endpoint, token}, headers, (err, res) => {
    ApiUtil.handleReponse(err, res, callback, dispatch);
  });
};

export const loadAmountNotificationsApi = (args, dispatch, callback) => {
  const endpoint = '/v1/notifications/amount',
    token = args.token,
    headers = {'Cache-Control': 'no-cache, no-store, must-revalidate'};

  getRequest({endpoint, token}, headers, (err, res) => {
    ApiUtil.handleReponse(err, res, callback, dispatch);
  });
};