import ApiUtil from '../utils/ApiUtil';
import {getRequest} from './api';

export const getJobsApi = (args, callback, dispatch) => {
  const endpoint = '/v2/jobs/get',
    token = args.token,
    qs = `search=${args.search}&location=${args.location}&distance=${args.distance}&currentLocation=${args.currentLocation}&category=${args.category}&type=${args.type}&highlighted=${args.highlighted}&companyId=${args.companyId}&sortBy=${args.sortBy}&limit=${args.limit}&key=${args.key}`,
    headers = {'Cache-Control': 'no-cache, no-store, must-revalidate'};
  getRequest({endpoint, token, qs}, headers, (err, res) => {
    ApiUtil.handleReponse(err, res, callback, dispatch);
  });
};
