// Context
import { useTranslationStore } from '../../../../../context/Translation';

// Enums
import { LanguageResource } from '../../../../../enums/Languages';

// Interfaces
import { InputInterface } from '../../../../../interfaces/Input';

interface PropsInterface {
  input: InputInterface;
}

const FileInput = (props: PropsInterface) => {
  const [t] = useTranslationStore();

  return (
    <div className="file-upload-wrapper">
      <div className="column-wrapper">
        <label className="text-color-gray-dark text-size-default" htmlFor={props.input.name}>
          <span className="text-weight-bold"> {t(LanguageResource.Common + ':click')} </span>
          {t(LanguageResource.Common + ':here_to_upload')}
        </label>
        <input
          id={props.input.name}
          name={props.input.name}
          type="file"
          accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*"
          onChange={input => props.input.onChange(input?.target?.files ? input.target.files[0] : null)}
        />
      </div>
      {
        props.input.value
        ?
        <div className="image-show-wrapper row-spread-wrapper">
          <p className="text-color-main text-weight-bold">{props.input.value.name ? props.input.value.name : props.input.label}</p>
          <p className="text-color-secondary" onClick={() => props.input.onChange(null)}>X</p>
        </div>
        : null
      }

    </div>
  );
};

export default FileInput;