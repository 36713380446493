import ApiUtil from '../utils/ApiUtil';
import {getRequest} from './api';

export const getCitiesApi = (args, callback) => {
  const endpoint = '/v1/cities/get',
    qs = `filter=${args.filter}`,
    headers = {};

  getRequest({endpoint, qs}, headers, (err, res) => {
    ApiUtil.handleReponse(err, res, callback);
  });
};
