// Interfaces
import {JobInterface} from '../../../../interfaces/Job';


interface PropsInterface {
  data: JobInterface;
}

const DetailTabsRequirements = (props: PropsInterface) => {
  return (
    <div className="description-wrapper">
      {
        Array.isArray(props.data.requirements)
        ? props.data.requirements.map(requirement => <p>- {requirement}</p>)
        :     
        <div 
          dangerouslySetInnerHTML={{__html: props.data.requirements as string}}
        />
      }
    </div>
  );
};

export default DetailTabsRequirements;