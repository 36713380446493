import { useEffect, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import Swal, {SweetAlertIcon} from 'sweetalert2';
// @ts-ignore
import LoadingOverlay from 'react-loading-overlay';

// Components
import Form from '../Common/Form/index';
import { validateInputs } from '../Common/Form/Inputs';

// Context
import {
  loginUser,
  useAuthStore,
  setErrorMessageEmpty
} from '../../context/Auth';
import { 
  useTranslationStore
} from '../../context/Translation';

// Enums
import { ImageSources } from '../../enums/Images';
import { InputTypes } from '../../enums/Inputs';
import { LanguageResource, Languages } from '../../enums/Languages';
import { RoutesLinks } from '../../enums/Routes';
import { StorageParams } from '../../enums/Storages';
import { SwalIcons, SwalTitle } from '../../enums/Swal';

// Interfaces
import { InputInterface } from '../../interfaces/Input';

// Utils
import JSUtil from '../../utils/JSUtil';
import LanguageUtil from '../../utils/LanguageUtil';

// Styles
import './index.scss';

interface PropsInterface {
  navigate: NavigateFunction
}

const Login = (props: PropsInterface) => {
  const [authState, authDispatch] = useAuthStore(),
    [t] = useTranslationStore(),
    [email, setEmail] = useState(''),
    [password, setPassword] = useState(''),
    [isLoading, setIsLoading] = useState(false),
    inputs: InputInterface[] = [
      {
        name: "email",
        type: InputTypes.Email,
        placeholder: true,
        onChange: (val: string) => setEmail(val),
        value: email,
        required: true
      },
      {
        name: "password",
        type: InputTypes.Password,
        placeholder: true,
        onChange: (val: string) => setPassword(val),
        value: password,
        required: true
      }
    ];

  useEffect(() => {
    checkIfUserIsLoggedIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.loggedIn]);

  useEffect(() => {
    if (authState.errorMessage) {
      setIsLoading(false);
      Swal.fire({
        icon: SwalIcons.Error as SweetAlertIcon,
        title: t(SwalTitle.Error),
        text: authState.errorMessage,
      });
      setErrorMessageEmpty(authDispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.errorMessage, authDispatch]);

  const checkIfUserIsLoggedIn = () => {
    if (!JSUtil.isEmpty(authState)) {
      if (authState?.loggedIn) {
        LanguageUtil.setLanguage(authState.profile.language);
        setIsLoading(false);
        const redirectPath = localStorage.getItem(StorageParams.Path);
        if (
          !JSUtil.isEmpty(redirectPath) &&
          ![
            RoutesLinks.Jobs.Link,
            RoutesLinks.Login.Link,
            RoutesLinks.Register.Link,
            RoutesLinks.ForgotPassword.Link,
            RoutesLinks.ResetPassword.Link,
          ].includes(redirectPath as string)
        ) {
          localStorage.removeItem(StorageParams.Path);
          props.navigate(redirectPath as string, { replace: true });
        } else {
          props.navigate(RoutesLinks.Jobs.Link, { replace: true });
        }
      }
    }
  }

  const handleSubmit = () => {
    setIsLoading(true);
    const valid = validateInputs(inputs, t);
    if (valid) {
      loginUser(
        authDispatch, 
        {
          email: email.toLowerCase().trim(),
          password,
          //trackingUserId is a ID to check if we have consultations that aren't connected to the user
          trackingUserId: localStorage.getItem(StorageParams.UserId) as string 
        }
      );
    } else {
      setIsLoading(false);
    }
  }

  const changeLanguage = (language: string) => {
    LanguageUtil.setLanguage(language, true);
  }

  return (
    <div className='login-wrapper'>
      <LoadingOverlay
        active={isLoading}
        spinner
      >
        <div className='login-form-wrapper' onSubmit={() => handleSubmit()}>
          <img src={require('../../' + ImageSources.LogoBlue.url)} alt={ImageSources.LogoBlue.name} width='50px'/>
          <img src={require('../../' + ImageSources.NameBlue.url)} alt={ImageSources.NameBlue.name} width='100px'/>
          <p className='text-weight-bold text-size-default title'>{t(LanguageResource.Auth + ':title')}</p>
          <Form 
            inputs={inputs}
            onSubmit={handleSubmit}
          />
          <div className="row-wrapper">
            <p className="text-color-main text-weight-bold text-size-small" onClick={() => props.navigate(RoutesLinks.ForgotPassword.Link)}>
              {t(LanguageResource.Auth + ':forgot_password')}
            </p>
            <p onClick={() => handleSubmit()} className="submit-button">&#8594;</p>
          </div>
          <div className="row-wrapper">
            <p className="text-color-gray-dark text-weight-bold text-size-default" onClick={() => props.navigate(RoutesLinks.Register.Link)}>
              {t(LanguageResource.Auth + ':register')}
            </p>
          </div>
          <div className="row-wrapper">
            <p className='text-color-secondary text-size-small'>
              <span onClick={() => changeLanguage(Languages.NL)}>NL </span> 
              <span onClick={() => changeLanguage(Languages.FR)}>| FR |</span> 
              <span onClick={() => changeLanguage(Languages.EN)}> EN</span>
            </p>
          </div>
        </div>
      </LoadingOverlay>
    </div>
  );
}

export default Login;