import ApiUtil from '../utils/ApiUtil';
import { postRequest } from './api';

export const trackClickUrlApi = (args, callback) => {
  const endpoint = '/v1/track/url/click',
    token = args.token,
    headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  postRequest({endpoint, token}, headers, args.body, (err, res) => {
    ApiUtil.handleReponse(err, res, callback);
  });
};

export const trackFilterApi = (args, callback) => {
  const endpoint = '/v1/track/filter',
    token = args.token,
    headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  postRequest({endpoint, token}, headers, args.body, (err, res) => {
    ApiUtil.handleReponse(err, res, callback);
  });
};

