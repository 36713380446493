// @ts-ignore
import LoadingOverlay from 'react-loading-overlay';

// Enums
import { ImageSources } from '../../../../enums/Images';

// Styles
import './index.scss';

interface PropsInterface {
  isLoading?: boolean,
  children: any
}

const BasicLayout = (props: PropsInterface) => (
  <div className="wrapper">
    <div className="basic-wrapper column-wrapper">
      <div className="title-wrapper row-spread-wrapper-center">
        <img src={require('../../../../' + ImageSources.LogoNameWhite.url)} alt={ImageSources.LogoNameWhite.name}/>
      </div>
      <div className="content-wrapper">
        <LoadingOverlay
          active={props.isLoading || false}
          spinner
        >
          {props.children}
        </LoadingOverlay>
      </div>
    </div>
  </div>
);

export default BasicLayout;
