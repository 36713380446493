import { NavigateFunction } from 'react-router-dom';

// Components
import DealsPreview from './Preview';
import Empty from '../../Common/List/Empty';
import ShowMore from '../../Common/List/ShowMore';

// Enums
import { ListLimit } from '../../../enums/Deals';

// Interfaces
import { DealInterface } from '../../../interfaces/Deal';

// Styles
import './index.scss';

// Utils
import JSUtil from '../../../utils/JSUtil';

interface PropsInterface {
  deals: DealInterface[],
  amount: number,
  limit: number,
  setLimit: (arg0: number) => void;
  navigate: NavigateFunction,
}

const DealsList = (props: PropsInterface) => {
  return (
    <div className="deals-wrapper">
      {
        !JSUtil.isEmpty(props.deals)
        ?
        <>
          {
            props.deals.map((deal, index) => 
              <DealsPreview 
                key={index}
                deal={deal}
                navigate={props.navigate} 
              />
            )
          }
          <div className='clear-float'/>
          <ShowMore
            limit={props.limit}
            setLimit={props.setLimit}
            plusLimit={ListLimit}
            amount={props.amount}
          />
        </>
        : <Empty />
      }
    </div>
  );
}

export default DealsList;