import { NavigateFunction } from 'react-router-dom';

// Components
import JobsPreview from './Preview';
import Empty from '../../Common/List/Empty';
import ShowMore from '../../Common/List/ShowMore';

// Enums
import { ListLimit } from '../../../enums/Jobs';

// Interfaces
import { JobInterface } from '../../../interfaces/Job';

// Styles
import './index.scss';

// Utils
import JSUtil from '../../../utils/JSUtil';

interface PropsInterface {
  jobs: JobInterface[],
  amount: number,
  limit: number,
  setLimit: (arg0: number) => void;
  navigate: NavigateFunction,
}

const JobsList = (props: PropsInterface) => {
  return (
    <div className="jobs-wrapper">
      {
        !JSUtil.isEmpty(props.jobs)
        ?
        <>
          {
            props.jobs.map((job, index) => 
              <JobsPreview 
                key={index}
                job={job}
                navigate={props.navigate} 
              />
            )
          }
          <div className='clear-float'/>
          <ShowMore
            limit={props.limit}
            setLimit={props.setLimit}
            plusLimit={ListLimit}
            amount={props.amount}
          />
        </>
        : <Empty />
      }

    </div>
  );
}

export default JobsList;