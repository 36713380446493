export const AppInfo = {
  PrivacyPolicyUrl: 'https://www.dibbs.be/privacy-policy',
  SharingPolicyUrl: 'https://www.dibbs.be/privacy-policy',
  TermsServiceUrl: 'https://www.dibbs.be/terms-of-use/',
  BranchIoLink: 'https://dibbsapp.app.link'
};

export const MainStates = {
  CompleteProfile: 'completeProfile',
};

export const Styles = {
  MobileWidth: 930,
  TabletWidth: 1300
};