export const SwalIcons = {
  Error: 'error',
  Success: 'success',
  Warning: 'warning'
};

export const SwalTitle = {
  Error: 'validation:error',
  Success: 'validation:success'
};

export const SwalResults = {
  Dismiss: {
    Cancel: 'buttons_cancel'
  }
}