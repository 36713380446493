import { LanguageResource } from "./Languages";

export const RoutesLinks = {
  Login: {
    Link: "/login",
    Translations: [LanguageResource.Common, LanguageResource.Validation, LanguageResource.Auth]
  },
  ForgotPassword: {
    Link: "/forgotpassword",
    Translations: [LanguageResource.Common, LanguageResource.Validation, LanguageResource.Auth]
  },
  ResetPassword: {
    Link: '/resetpassword/:token',
    Params: {
      Token: 'token'
    },
    Translations: [LanguageResource.Common, LanguageResource.Validation, LanguageResource.Auth]
  },
  Register: {
    Link: "/register",
    Translations: [LanguageResource.Common, LanguageResource.Validation, LanguageResource.Auth]
  },
  Jobs: {
    Link: "/",
    Title: "jobs",
    Translations: [LanguageResource.Common, LanguageResource.Validation, LanguageResource.Jobs]
  },
  JobsDetail: {
    Link: '/jobs/:jobKey',
    Params: {
      JobKey: 'jobKey'
    },
    Translations: [LanguageResource.Common, LanguageResource.Validation, LanguageResource.Jobs]
  },
  Resume: {
    Link: '/resume/:jobKey/:userId',
    Params: {
      JobKey: 'jobKey',
      UserId: 'userId'
    },
    Title: 'claim_job',
    Translations: [LanguageResource.Common, LanguageResource.Validation, LanguageResource.Jobs]
  },
  Deals: {
    Link: "/deals",
    Title: "deals",
    Translations: [LanguageResource.Common, LanguageResource.Validation, LanguageResource.Deals]
  },
  DealsDetail: {
    Link: '/deals/:dealKey',
    Params: {
      DealKey: 'dealKey'
    },
    Translations: [LanguageResource.Common, LanguageResource.Validation, LanguageResource.Deals]
  },
  Events: {
    Link: "/events",
    Title: "events",
    Translations: [LanguageResource.Common, LanguageResource.Validation, LanguageResource.Events]
  },
  EventsDetail: {
    Link: '/events/:eventKey',
    Params: {
      EventKey: 'eventKey'
    },
    Translations: [LanguageResource.Common, LanguageResource.Validation, LanguageResource.Events]
  },
  News: {
    Link: "/news",
    Title: "news",
    Translations: [LanguageResource.Common, LanguageResource.Validation, LanguageResource.News]
  },
  NewsDetail: {
    Link: '/news/:newsKey',
    Params: {
      NewsKey: 'newsKey'
    },
    Translations: [LanguageResource.Common, LanguageResource.Validation, LanguageResource.News]
  },
  ProfileAccount: {
    Link: "/profile-account",
    Title: "profile",
    Params: {
      State: 'account'
    },
    Translations: [LanguageResource.Common, LanguageResource.Validation, LanguageResource.Profile]
  },
  ProfileCV: {
    Link: "/profile-cv",
    Title: "profile",
    Params: {
      State: 'cv'
    },
    Translations: [LanguageResource.Common, LanguageResource.Validation, LanguageResource.Profile]
  },
  ProfileJobs: {
    Link: "/profile-jobs",
    Title: "profile",
    Params: {
      State: 'jobs'
    },
    Translations: [LanguageResource.Common, LanguageResource.Validation, LanguageResource.Profile]
  },
  ProfileJobsDetail: {
    Link: '/profile-jobs/:jobKey',
    Params: {
      JobKey: 'jobKey'
    },
    Translations: [LanguageResource.Common, LanguageResource.Validation, LanguageResource.Jobs]
  },
  ProfileDeals: {
    Link: "/profile-deals",
    Title: "profile",
    Params: {
      State: 'deals'
    },
    Translations: [LanguageResource.Common, LanguageResource.Validation, LanguageResource.Profile]
  },
  ProfileDealsDetail: {
    Link: '/profile-deals/:dealKey',
    Params: {
      DealKey: 'dealKey'
    },
    Translations: [LanguageResource.Common, LanguageResource.Validation, LanguageResource.Deals]
  },
  ProfileEvents: {
    Link: "/profile-events",
    Title: "profile",
    Params: {
      State: 'events'
    },
    Translations: [LanguageResource.Common, LanguageResource.Validation, LanguageResource.Profile]
  },
  ProfileEventsDetail: {
    Link: '/profile-events/:eventKey',
    Params: {
      EventKey: 'eventKey'
    },
    Translations: [LanguageResource.Common, LanguageResource.Validation, LanguageResource.Events]
  },
  ProfileNews: {
    Link: "/profile-news",
    Title: "profile",
    Params: {
      State: 'news'
    },
    Translations: [LanguageResource.Common, LanguageResource.Validation, LanguageResource.Profile]
  },
  ProfileNewsDetail: {
    Link: '/profile-news/:newsKey',
    Params: {
      NewsKey: 'newsKey'
    },
    Translations: [LanguageResource.Common, LanguageResource.Validation, LanguageResource.News]
  },
  Notifications: {
    Link: '/notifications',
    Title: "notifications",
    Translations: [LanguageResource.Common, LanguageResource.Validation, LanguageResource.Notifications]
  }
};

export const OriginTracking = {
  Claim: 'claim',
  Highlight: 'highlight',
  Url: 'url',
  Notification: 'notification',
  List: 'list',
};
