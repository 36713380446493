import { NavigateFunction } from 'react-router-dom';

// Components
import NotificationsPreview from './Preview';
import Empty from '../../Common/List/Empty';

// Interfaces
import { NotificationInterface } from '../../../interfaces/Notification';

// Styles
import './index.scss';

// Utils
import JSUtil from '../../../utils/JSUtil';

interface PropsInterface {
  notifications: NotificationInterface[];
  navigate: NavigateFunction,
}

const NotificationsList = (props: PropsInterface) => {
  return (
    <div className="notifications-wrapper">
      {
        !JSUtil.isEmpty(props.notifications)
        ?
        <>
          {
            props.notifications.map((notification, index) => 
              <NotificationsPreview 
                key={index}
                notification={notification}
                navigate={props.navigate} 
              />
            )
          }
          <div className='clear-float'/>
        </>
        : <Empty />
      }
    </div>
  )
}

export default NotificationsList