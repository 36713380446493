// Components
import EducationInfo from '../../Education';

// Context
import { useTranslationStore } from '../../../../context/Translation';

// Enums
import { LanguageResource } from '../../../../enums/Languages';

interface PropsInterface {
  onPressClaimButton: () => void;
  closePopup: () => void;
  label: string;
  isLoading: boolean;
  setIsLoading: (arg0: boolean) => void;
}

const ProfilePopupCompleteClaim = (props: PropsInterface) => {
  const [t] = useTranslationStore();

  return (
    <div className="overflow-popup-wrapper">
      <div className="popup-wrapper">
        <p>{t(LanguageResource.Common + ':claim_missing_info')}</p>
        <p>{t(LanguageResource.Common + ':fill_in')}</p>
        <EducationInfo
          label={props.label}
          proceedAction={() => props.onPressClaimButton()}
          isLoading={props.isLoading}
          setIsLoading={props.setIsLoading}
          disableFieldOfStudy={true}
          disableCity={true}
          disableYearOfStudy={true}
        />
      </div>
    </div>
  );
};

export default ProfilePopupCompleteClaim;