export const DropdownOptions = {
  Api: 'api',
  Options: 'options'
}

export const InputTypes = {
  Checkbox: 'checkbox',
  Date: 'date',
  Dropdown: 'dropdown',
  Email: 'email',
  File: 'file',
  Password: 'password',
  PasswordConfirm: 'passwordConfirm',
  Phone: 'phone',
  Radio: 'radio',
  Range: 'range',
  Text: 'text',
  TextArea: 'textarea',
  Url: 'url'
};