import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Enums
import { DefaultLanguage, LanguageResource } from "../enums/Languages";

// Languages EN
import AUTH_EN from './en/auth.json';
import COMMON_EN from './en/common.json';
import DEALS_EN from './en/deals.json';
import EVENTS_EN from './en/events.json';
import INPUT_EN from './en/input.json';
import JOBS_EN from './en/jobs.json';
import NEWS_EN from './en/news.json';
import NOTIFICATIONS_EN from './en/notifications.json';
import PROFILE_EN from './en/profile.json';
import VALIDATION_EN from './en/validation.json';

// Languages FR
import AUTH_FR from './fr/auth.json';
import COMMON_FR from './fr/common.json';
import DEALS_FR from './fr/deals.json';
import EVENTS_FR from './fr/events.json';
import INPUT_FR from './fr/input.json';
import JOBS_FR from './fr/jobs.json';
import NEWS_FR from './fr/news.json';
import NOTIFICATIONS_FR from './fr/notifications.json';
import PROFILE_FR from './fr/profile.json';
import VALIDATION_FR from './fr/validation.json';

// Languages NL
import AUTH_NL from './nl/auth.json';
import COMMON_NL from './nl/common.json';
import DEALS_NL from './nl/deals.json';
import EVENTS_NL from './nl/events.json';
import INPUT_NL from './nl/input.json';
import JOBS_NL from './nl/jobs.json';
import NEWS_NL from './nl/news.json';
import NOTIFICATIONS_NL from './nl/notifications.json';
import PROFILE_NL from './nl/profile.json';
import VALIDATION_NL from './nl/validation.json';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: { 
    [LanguageResource.Auth]: AUTH_EN,
    [LanguageResource.Common]: COMMON_EN,
    [LanguageResource.Deals]: DEALS_EN,
    [LanguageResource.Events]: EVENTS_EN,
    [LanguageResource.Input]: INPUT_EN,
    [LanguageResource.Jobs]: JOBS_EN,
    [LanguageResource.News]: NEWS_EN,
    [LanguageResource.Notifications]: NOTIFICATIONS_EN,
    [LanguageResource.Profile]: PROFILE_EN,
    [LanguageResource.Validation]: VALIDATION_EN
  },
  fr: { 
    [LanguageResource.Auth]: AUTH_FR,
    [LanguageResource.Common]: COMMON_FR,
    [LanguageResource.Deals]: DEALS_FR,
    [LanguageResource.Events]: EVENTS_FR,
    [LanguageResource.Input]: INPUT_FR,
    [LanguageResource.Jobs]: JOBS_FR,
    [LanguageResource.News]: NEWS_FR,
    [LanguageResource.Notifications]: NOTIFICATIONS_FR,
    [LanguageResource.Profile]: PROFILE_FR,
    [LanguageResource.Validation]: VALIDATION_FR
  },
  nl: { 
    [LanguageResource.Auth]: AUTH_NL,
    [LanguageResource.Common]: COMMON_NL,
    [LanguageResource.Deals]: DEALS_NL,
    [LanguageResource.Events]: EVENTS_NL,
    [LanguageResource.Input]: INPUT_NL,
    [LanguageResource.Jobs]: JOBS_NL,
    [LanguageResource.News]: NEWS_NL,
    [LanguageResource.Notifications]: NOTIFICATIONS_NL,
    [LanguageResource.Profile]: PROFILE_NL,
    [LanguageResource.Validation]: VALIDATION_NL
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: DefaultLanguage,
    interpolation: {
      escapeValue: false
    }
  });

  export default i18n;