import {RefObject, useEffect, useState} from 'react';
import Swal, {SweetAlertIcon} from 'sweetalert2';

// Apis
import { getCitiesApi } from '../../../api/cities';
import { getUserStudentCardApi } from '../../../api/users';

// Components
import Form from '../../Common/Form';

// Context
import { useAuthStore } from '../../../context/Auth';
import { useTranslationStore } from '../../../context/Translation';

// Enums
import { DropdownOptions, InputTypes } from '../../../enums/Inputs';
import { LanguageResource } from '../../../enums/Languages';
import { SwalIcons, SwalTitle } from '../../../enums/Swal';
import { YesNo } from '../../../enums/YesNo';

// Interfaces
import { InputInterface } from '../../../interfaces/Input';
import { LocationInterface } from '../../../interfaces/Location';

// Utils
import DateUtil from '../../../utils/DateUtil';
import JSUtil from '../../../utils/JSUtil';

interface PropsInterface {
  forwardedRef: RefObject<HTMLFormElement>;
  onSubmit: (arg0: (InputInterface|InputInterface[])[], arg1: any) => void;
  loadingCities: boolean;
  setLoadingCities: (arg0: boolean) => void;
}

const AccountForm = (props: PropsInterface) => {
  const  [authState] = useAuthStore(),
    [t] = useTranslationStore(),
    [cities, setCities] = useState<LocationInterface[]>([]),
    [searchedCities, setSearchedCities] = useState<LocationInterface[]>([]),
    [studentCardFront, setStudentCardFront] = useState(!JSUtil.isEmpty(authState.profile?.studentCardFront) ? authState.profile.studentCardFront : ''),
    [studentCardBack, setStudentCardBack] = useState(!JSUtil.isEmpty(authState.profile?.studentCardBack) ? authState.profile.studentCardBack : ''),
    [email, setEmail] = useState(!JSUtil.isEmpty(authState.profile?.email) ? authState.profile.email : ''),
    [firstname, setFirstname] = useState(!JSUtil.isEmpty(authState.profile?.firstname) ? authState.profile.firstname : ''),
    [lastname, setLastname] = useState(!JSUtil.isEmpty(authState.profile?.lastname) ? authState.profile.lastname : ''),
    [birthday, setBirthday] = useState(!JSUtil.isEmpty(authState.profile?.birthday) ? DateUtil.formatInputDate(authState.profile.birthday) : ''),
    [phone, setPhone] = useState(!JSUtil.isEmpty(authState.profile?.phone) ? authState.profile.phone : ''),
    [street, setStreet] = useState(!JSUtil.isEmpty(authState.profile?.street) ? authState.profile.street : ''),
    [housenumber, setHousenumber] = useState(!JSUtil.isEmpty(authState.profile?.housenumber)? authState.profile.housenumber : ''),
    [box, setBox] = useState(!JSUtil.isEmpty(authState.profile?.box)? authState.profile.box : ''),
    [postalCode, setPostalCode] = useState(!JSUtil.isEmpty(authState.profile?.postalCode)? authState.profile.postalCode : ''),
    [cityId, setCityId] = useState(!JSUtil.isEmpty(authState.profile?.city)? authState.profile.city : ''),
    inputs = [
      [
        {
          name: "studentCardFront",
          type: InputTypes.File,
          placeholder: true,
          onChange: (val: string) => setStudentCardFront(val),
          value: studentCardFront,
          label: t(LanguageResource.Input + ':studentCardFront'),
          required: true,
        },
        {
          name: "studentCardBack",
          type: InputTypes.File,
          placeholder: true,
          onChange: (val: string) => setStudentCardBack(val),
          value: studentCardBack,
          label: t(LanguageResource.Input + ':studentCardBack'),
          required: true,
        },
      ],
      [
        {
          name: "email",
          type: InputTypes.Text,
          placeholder: true,
          onChange: (val: string) => setEmail(val),
          value: email,
          label: t(LanguageResource.Input + ':email'),
          required: true
        },
        {
          name: "firstname",
          type: InputTypes.Text,
          placeholder: true,
          onChange: (val: string) => setFirstname(val),
          value: firstname,
          label: t(LanguageResource.Input + ':firstname'),
          required: true
        },
        {
          name: "lastname",
          type: InputTypes.Text,
          placeholder: true,
          onChange: (val: string) => setLastname(val),
          value: lastname,
          label: t(LanguageResource.Input + ':lastname'),
          required: true
        }
      ],
      [
        {
          name: "birthday",
          type: InputTypes.Date,
          placeholder: true,
          onChange: (val: string) => setBirthday(val),
          value: birthday,
          label: t(LanguageResource.Input + ':birthday'),
          required: true
        },
        {
          name: "phone",
          type: InputTypes.Phone,
          placeholder: true,
          onChange: (val: string) => setPhone(val),
          value: phone,
          label: t(LanguageResource.Input + ':phone'),
          required: false
        }
      ],
      [
        {
          name: "street",
          type: InputTypes.Text,
          placeholder: true,
          onChange: (val: string) => setStreet(val),
          value: street,
          label: t(LanguageResource.Input + ':street'),
          required: false
        },
        {
          name: "housenumber",
          type: InputTypes.Text,
          placeholder: true,
          onChange: (val: string) => setHousenumber(val),
          value: housenumber,
          label: t(LanguageResource.Input + ':housenumber'),
          required: false
        },
        {
          name: "box",
          type: InputTypes.Text,
          placeholder: true,
          onChange: (val: string) => setBox(val),
          value: box,
          label: t(LanguageResource.Input + ':box'),
          required: false
        },
      ],
      [
        {
          name: "postalCode",
          type: InputTypes.Text,
          placeholder: true,
          onChange: (val: string) => searchCities(val, cities),
          value: postalCode,
          label: t(LanguageResource.Input + ':postalCode'),
          required: false
        },
        {
          name: "cityId",
          type: InputTypes.Dropdown,
          options: {
            type: DropdownOptions.Options,
            dataGetter: searchedCities
          },
          placeholder: true,
          onChange: (val: string) => setCityId(val),
          value: cityId,
          label: t(LanguageResource.Input + ':city'),
          required: false,
          condition: !JSUtil.isEmpty(postalCode)
        }
      ]
    ];

  useEffect(() => {
    loadingCitiesInfo();
    loadStudentCard(); 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadingCitiesInfo = () => {
    getCitiesApi(
      {
        token: authState.token,
        filter: YesNo.No,
      },
      (err: any, res: LocationInterface[]) => {
        if (err) {
          Swal.fire({
            icon: SwalIcons.Error as SweetAlertIcon,
            title: t(SwalTitle.Error),
            text: err,
          });
        } else {
          setCities(res);
          searchCities(postalCode, res);
          props.setLoadingCities(false);
        }
      }
    );
  }

  const searchCities = (value: string, options: LocationInterface[]) => {
    setPostalCode(value);
    const filteredCities = options.filter(f => f.postalCode === value);
    setSearchedCities(filteredCities);
    setCityId(filteredCities[0] ? filteredCities[0]._id : '');
  };

  const loadStudentCard = () => {
    if (authState.token) {
      getUserStudentCardApi(
        {
          token: authState.token
        },
        (err: any, res: {studentCardFront: string, studentCardBack: string}) => {
          if (err) {
            Swal.fire({
              icon: SwalIcons.Error as SweetAlertIcon,
              title: t(SwalTitle.Error),
              text: err,
            });
          } else {
            setStudentCardFront(res.studentCardFront);
            setStudentCardBack(res.studentCardBack)
          }
        }
      )
    }
  }
  
  return (
   <Form
      forwardedRef={props.forwardedRef}
      inputs={inputs}
      onSubmit={() => props.onSubmit(
        inputs,
        {
          email,
          firstname,
          lastname,
          birthday,
          phone,
          street,
          housenumber,
          box,
          postalCode,
          cityId,
          studentCardBack,
          studentCardFront
        }
      )}
    />
  );
};

export default AccountForm;
