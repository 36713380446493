import { NavigateFunction } from 'react-router-dom';

// Context
import { useTranslationStore } from '../../../context/Translation';
import { LanguageResource } from '../../../enums/Languages';

// Enums
import { NotificationTypes } from '../../../enums/Notifications';
import { OriginTracking, RoutesLinks } from '../../../enums/Routes';

// Interfaces
import { NotificationInterface } from '../../../interfaces/Notification';

// Styles
import './Preview.scss';

// Utils
import DateUtil from '../../../utils/DateUtil';

interface PropsInterface {
  notification: NotificationInterface,
  navigate: NavigateFunction,
}

const NotificationsPreview = (props: PropsInterface) => {
  const [t] = useTranslationStore();

  const onPressNotifications = () => {
    if (props.notification.type === NotificationTypes.Deal) {
      if (props.notification.key) {
        props.navigate(
          RoutesLinks.DealsDetail.Link.replace(':' + RoutesLinks.DealsDetail.Params.DealKey, props.notification.key as string),
          {
            state: {
              origin: OriginTracking.Notification,
              info: props.notification.title
            }
          }
        );
      } else {
        props.navigate(RoutesLinks.Deals.Link);
      }
    } else if (props.notification.type === NotificationTypes.Event) {
      if (props.notification.key) {
        props.navigate(
          RoutesLinks.EventsDetail.Link.replace(':' + RoutesLinks.EventsDetail.Params.EventKey, props.notification.key as string),
          {
            state: {
              origin: OriginTracking.Notification,
              info: props.notification.title
            }
          }
        );
      } else {
        props.navigate(RoutesLinks.Events.Link);
      }
    } else if (props.notification.type === NotificationTypes.Job) {
      if (props.notification.key) {
        props.navigate(
          RoutesLinks.JobsDetail.Link.replace(':' + RoutesLinks.JobsDetail.Params.JobKey, props.notification.key as string),
          {
            state: {
              origin: OriginTracking.Notification,
              info: props.notification.title
            }
          }
        );
      } else {
        props.navigate(RoutesLinks.Jobs.Link);
      }
    } else if (props.notification.type === NotificationTypes.News) {
      if (props.notification.key) {
        props.navigate(
          RoutesLinks.NewsDetail.Link.replace(':' + RoutesLinks.NewsDetail.Params.NewsKey, props.notification.key as string),
          {
            state: {
              origin: OriginTracking.Notification,
              info: props.notification.title
            }
          }
        );
      } else {
        props.navigate(RoutesLinks.News.Link);
      }
    }
  };

  return (
    <div 
      className='notification-preview-wrapper column-wrapper' 
      onClick={() => onPressNotifications()}>
        <div className='row-wrapper'>
          <p className='notification-blue-text text-weight-bold'>
            {t(LanguageResource.Notifications + ':' + props.notification.type)}
          </p>
          <p className='notification-gray-text'>
            {' ' + t(LanguageResource.Common + ':posted_by') + ' '}
          </p>
          <p className='notification-blue-text text-weight-bold'>
            {props.notification.company.name}
          </p>
          <p className='notification-blue-text'>
            {DateUtil.dateFromNow(props.notification.createdAt)}
          </p>
        </div>
        <p>{props.notification.title}</p>
    </div>
  );
}

export default NotificationsPreview;