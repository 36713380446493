import {useReducer, useEffect, useContext, createContext} from 'react';
import {initialState, AuthReducer} from './reducer';
import {setAuthSyncContext} from './actions';

const AuthStore = createContext();
AuthStore.displayName = 'Auth';

export const useAuthStore = () => useContext(AuthStore);

export const AuthStoreProvider = props => {
  const [globalState, dispatch] = useReducer(AuthReducer, initialState);

  useEffect(() => {
    setAuthSyncContext(dispatch);
  }, []);

  return (
    <AuthStore.Provider value={[globalState, dispatch]}>
      {props.children}
    </AuthStore.Provider>
  );
};
