export const Languages = {
  EN: 'en',
  FR: 'fr',
  NL: 'nl'
};

export const LanguageResource = {
  Auth: 'auth',
  Common: 'common',
  Deals: 'deals',
  Events: 'events',
  Input: 'input',
  Jobs: 'jobs',
  News: 'news',
  Notifications: 'notifications',
  Profile: 'profile',
  Validation: 'validation'
};

export const DefaultLanguage = Languages.EN;