import { useState } from 'react';

// Components
import DetailTabsDescription from './Description';
import DetailTabsLocation from './Location';
import DetailTabsRequirements from './Requirements';

// Context
import { useTranslationStore } from '../../../../context/Translation';

// Enums
import { LanguageResource } from '../../../../enums/Languages';

// Interfaces
import { DealInterface } from '../../../../interfaces/Deal';
import { EventInterface } from '../../../../interfaces/Event';
import { JobInterface } from '../../../../interfaces/Job';

// Styles
import './index.scss';

interface PropsInterface {
  data: JobInterface | DealInterface | EventInterface;
  type: string | undefined;
  useRequirements?: boolean;
}

const DetailTabs = (props: PropsInterface) => {
  const [currentTab, setCurrentTab] = useState(0),
    [t] = useTranslationStore();

  const tabButton = (tab: number, name: string) => {
    return (
      <a onClick={() => setCurrentTab(tab)} className={currentTab === tab ? 'tab-button-wrapper-active' : 'tab-button-wrapper-inactive'}>
        <p>{name}</p>
      </a>
    );
  };

  return (
    <div className='tab-wrapper'>
      <div className='tab-buttons-wrapper row-wrapper'>
        {tabButton(0, t(LanguageResource.Common + ':details'))}
        {props.useRequirements ? (
          <>
            {tabButton(1, t(LanguageResource.Common + ':requirements'))}
            {tabButton(2, t(LanguageResource.Common + ':location'))}
          </>
        ) : (
          tabButton(1, t(LanguageResource.Common + ':location'))
        )}
      </div>

      {currentTab === 0 ? (
        <DetailTabsDescription data={props.data} type={props.type} />
      ) : null}
      {props.useRequirements ? (
        currentTab === 1 ? (
          <DetailTabsRequirements data={props.data as JobInterface} />
        ) : currentTab === 2 ? (
          <DetailTabsLocation data={props.data} />
        ) : null
      ) : currentTab === 1 ? (
        <DetailTabsLocation data={props.data} />
      ) : null}
    </div>
  );
};

export default DetailTabs;
