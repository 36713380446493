import {useEffect, useState} from 'react';
import { NavigateFunction } from 'react-router-dom';
import Swal, {SweetAlertIcon} from 'sweetalert2';
// @ts-ignore
import LoadingOverlay from 'react-loading-overlay';

// Apis
import {getClaimsApi} from '../../api/claims';
import {getSavedApi} from '../../api/saves';

// Components
import JobsList from '../Jobs/List';

// Context
import { useAuthStore } from '../../context/Auth';
import { useTranslationStore } from '../../context/Translation';

// Enums
import {CategoryTypes} from '../../enums/Categories';
import { LanguageResource } from '../../enums/Languages';
import { SwalIcons, SwalTitle } from '../../enums/Swal';

// Interfaces
import {JobInterface} from '../../interfaces/Job';

// Styles
import './index.scss';

interface PropsInterface {
  navigate: NavigateFunction,
}

const ProfileJobs = (props: PropsInterface) => {
  const [loadingJobsSaved, setLoadingJobsSaved] = useState<boolean>(true),
    [loadingJobsClaimed, setLoadingJobsClaimed] = useState<boolean>(true),
    [savedJobs, setSavedJobs] = useState<JobInterface[]>([]),
    [claimedJobs, setClaimedJobs] = useState<JobInterface[]>([]),
    [authState] = useAuthStore(),
    [t] = useTranslationStore();


  useEffect(() => {
    loadJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.token]);

  const loadJobs = () => {
    getSavedApi(
      {
        token: authState.token,
        type: CategoryTypes.Jobs,
      },
      (err: any, res: JobInterface[]) => {
        if (err) {
          Swal.fire({
            icon: SwalIcons.Error as SweetAlertIcon,
            title: t(SwalTitle.Error),
            text: err,
          });
        } else {
          setSavedJobs(res);
        }
        setLoadingJobsSaved(false);
      },
    );

    getClaimsApi(
      {
        token: authState.token,
        type: CategoryTypes.Jobs,
      },
      (err: any, res: JobInterface[]) => {
        if (err) {
          Swal.fire({
            icon: SwalIcons.Error as SweetAlertIcon,
            title: t(SwalTitle.Error),
            text: err,
          });
        } else {
          setClaimedJobs(res);
        }
        setLoadingJobsClaimed(false);
      },
    );
  };
  return (
    <LoadingOverlay
      active={loadingJobsSaved || loadingJobsClaimed}
      spinner
    >   
      <div className='my-list-wrapper'>
        <p className='text-weight-bold text-size-large my-list-sub-title-wrapper'>{t(LanguageResource.Profile + ':jobs_saved')}</p>
        <JobsList 
          jobs={savedJobs}
          amount={0}
          limit={0}
          setLimit={() => {}}
          navigate={props.navigate}
        />
        <p className='text-weight-bold text-size-large my-list-sub-title-wrapper'>{t(LanguageResource.Profile + ':jobs_claimed')}</p>
        <JobsList 
          jobs={claimedJobs}
          amount={0}
          limit={0}
          setLimit={() => {}}
          navigate={props.navigate} 
        />
      </div>
    </LoadingOverlay>
  );
};

export default ProfileJobs;
