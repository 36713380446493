import Swal, {SweetAlertIcon} from 'sweetalert2';

// Context
import { useTranslationStore } from '../../../context/Translation';

// Enums
import { LanguageResource } from '../../../enums/Languages';

// Styles
import './Share.scss';

// Svgs
import { ReactComponent as Share } from '../../../assets/svg/share.svg';
import i18n from '../../../i18n';
import { SwalIcons, SwalTitle } from '../../../enums/Swal';

interface PropsInterface {
  shareUrl: string;
}

const ShareButton = (props: PropsInterface) => {
  const [t] = useTranslationStore();

  const shareContent = () => {
    navigator.clipboard.writeText(props.shareUrl).then(function() {
      Swal.fire({
        icon: SwalIcons.Success as SweetAlertIcon,
        title: t(SwalTitle.Success),
        text: props.shareUrl + i18n.t(LanguageResource.Common + ':share_url_success'),
      });
    }, function() {
      Swal.fire({
        icon: SwalIcons.Error as SweetAlertIcon,
        title: t(SwalTitle.Error),
        text: i18n.t(LanguageResource.Common + ':share_url_error'),
      });
    });
  }

  return(
    <div className='share-button-wrapper' onClick={() => shareContent()}>
      <Share />
    </div>
  );
};

export default ShareButton;