// Interfaces
import { JobInterface } from "../../../interfaces/Job";

// Svgs
import { ReactComponent as Categories } from '../../../assets/svg/categories.svg';

interface PropsInterface {
  job: JobInterface;
};

const JobInfoCategories = (props: PropsInterface) => {
  return (
    <div className='row-wrapper'>
      <div className='svg-wrapper'>
        <Categories fill='#FF462F' stroke='#FF462F' />
      </div>
      <p>
        {props.job.categories.join(', ')}
      </p>
    </div>
  );
};

export default JobInfoCategories;