import { useState } from 'react';

// Components
import SearchFilter from '../../Common/Search/Filter/';
import SearchFilterCategory from '../../Common/Search/Filter/Category';
import SearchFilterTitle from '../../Common/Search/Filter/Title';

// Context
import { useTranslationStore } from '../../../context/Translation';

// Enums
import { LanguageResource } from '../../../enums/Languages';

// Interfaces
import { CategoryInterface } from '../../../interfaces/Category';

interface PropsInterface {
  cleanSearch: () => void;
  categories: CategoryInterface[];
  filteredCategories: string[];
  onPressCategory: (arg0: string) => void;
  filterActive: boolean;
  amount: number;
}

const FilterSideBar = (props: PropsInterface) => {
  const [t] = useTranslationStore(),
   [showCategory, setShowCategory] = useState(true);

  const onPressClean = () => {
    props.cleanSearch();
  };

  return (
    <SearchFilter
      filterActive={props.filterActive}
      amount={props.amount}
      onPressClean={onPressClean}
    >
      <SearchFilterTitle 
        title={t(LanguageResource.Common + ':categories')}
        show={showCategory}
        onPressShow={() => setShowCategory(!showCategory)}
      />
      {
        showCategory
        ?
        <SearchFilterCategory
          categories={props.categories}
          filteredCategories={props.filteredCategories}
          onPressCategory={props.onPressCategory}
        />
        : null
      }
    </SearchFilter>
  );
};

export default FilterSideBar;
