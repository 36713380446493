// Config
import ENV from '../config/env.json';

// Utils
import LanguageUtil from '../utils/LanguageUtil';

export const getRequest = (args = {}, headers = {}, callback) => {
  const options = {
    method: 'GET',
    headers: headers,
  };
  let url = `${ENV.BASE_API_URL + args.endpoint}?clientId=${
    ENV.CLIENT_ID
  }&secretKey=${ENV.SECRET_KEY}`;
  if (args.token) {
    url = url.concat('&token=').concat(args.token);
  }

  const language = LanguageUtil.getLanguage();
  url = url.concat('&language=').concat(language);
  if (args.qs) {
    url = url.concat('&').concat(args.qs);
  }

  fetch(url, options)
    .then(response => response.json())
    .then(responseData => {
      const res = responseData.error ? null : responseData,
        err = responseData.error || null;
      if (callback) {
        callback(err, res);
      }
    })
    .catch(e => {
      callback(e, {});
    });
};

export const postRequest = (
  args = {},
  headers = {},
  body = {},
  callback,
  url = false,
) => {
  const options = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(body),
  };

  if (!url) {
    url = `${ENV.BASE_API_URL + args.endpoint}?clientId=${
      ENV.CLIENT_ID
    }&secretKey=${ENV.SECRET_KEY}`;
    if (args.token) {
      url = url.concat('&token=').concat(args.token);
    }
    const language = LanguageUtil.getLanguage();
    url = url.concat('&language=').concat(language);
    if (args.qs) {
      url = url.concat('&').concat(args.qs);
    }
  }
  fetch(url, options)
    .then(response => {
      if (response.status !== '404') {
        return response.json();
      } else {
        this._postRequest(args, headers, callback, response.url);
      }
    })
    .then(responseData => {
      const res = responseData.error ? null : responseData,
        err = responseData.error || null;
        
      if (callback) {
        callback(err, res);
      }
    })
    .catch(e => {
      if (callback) {
        callback(e);
      }
    });
};
