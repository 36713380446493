import { useEffect, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import Swal, {SweetAlertIcon} from 'sweetalert2';
// @ts-ignore
import LoadingOverlay from 'react-loading-overlay';

// Apis
import {getSavedApi} from '../../api/saves';

// Components
import EventsList from '../Events/List';

// Context
import { useAuthStore } from '../../context/Auth';
import { useTranslationStore } from '../../context/Translation';

// Enums
import { CategoryTypes } from '../../enums/Categories';
import { LanguageResource } from '../../enums/Languages';
import { SwalIcons, SwalTitle } from '../../enums/Swal';

// Interfaces
import { EventInterface } from '../../interfaces/Event';

// Styles
import './index.scss';

interface PropsInterface {
  navigate: NavigateFunction,
}

const ProfileEvents = (props: PropsInterface) => {
  const [loadingEventsSaved, setLoadingEventsSaved] = useState<boolean>(true),
    [savedEvents, setSavedEvents] = useState<EventInterface[]>([]),
    [authState] = useAuthStore(),
    [t] = useTranslationStore();

  useEffect(() => {
    loadEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.token]);

  const loadEvents = () => {
    getSavedApi(
      {
        token: authState.token,
        type: CategoryTypes.Events,
      },
      (err: any, res: EventInterface[]) => {
        if (err) {
          Swal.fire({
            icon: SwalIcons.Error as SweetAlertIcon,
            title: t(SwalTitle.Error),
            text: err,
          });
        } else {
          setSavedEvents(res);
        }
        setLoadingEventsSaved(false);
      },
    );
  };

  return (
    <LoadingOverlay
      active={loadingEventsSaved}
      spinner
    >   
      <div className='my-list-wrapper'>
        <p className='text-weight-bold text-size-large my-list-sub-title-wrapper'>{t(LanguageResource.Profile + ':events_saved')}</p>
        <EventsList 
          events={savedEvents}
          amount={0}
          limit={0}
          setLimit={() => {}}
          navigate={props.navigate}
        />
      </div>
    </LoadingOverlay>
  );
};

export default ProfileEvents;
