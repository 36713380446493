import moment from "moment";

// Enums
import { InputTypes } from "../../../enums/Inputs";
import { LanguageResource } from "../../../enums/Languages";

// Interfaces
import { EducationInterface } from "../../../interfaces/Job";

// Utils
import DateUtil from "../../../utils/DateUtil";

export const getEducationInputs = (
  t: any,
  levelEducation: string,
  setLevelEducation: (arg0: string) => void,
  fieldOfStudy: string,
  setFieldOfStudy: (arg0: string) => void,
  collegeOrUniversity: string,
  setCollegeOrUniversity: (arg0: string) => void,
  schoolLocation: string,
  setSchoolLocation: (arg0: string) => void,
  timePeriod: boolean,
  setTimePeriod: (arg0: boolean) => void,
  fromDate: string,
  setFromDate: (arg0: string) => void,
  toDate: string,
  setToDate: (arg0: string) => void
) => {
  return [
    {
      name: "levelEducation",
      type: InputTypes.Text,
      onChange: (val: string) => setLevelEducation(val),
      value: levelEducation,
      label: t(LanguageResource.Jobs + ':claim_education_levelEducation'),
      required: true,
    },
    {
      name: "fieldOfStudy",
      type: InputTypes.Text,
      onChange: (val: string) => setFieldOfStudy(val),
      value: fieldOfStudy,
      label: t(LanguageResource.Jobs + ':claim_education_fieldOfStudy'),
      required: true,
    },
    {
      name: "collegeOrUniversity",
      type: InputTypes.Text,
      onChange: (val: string) => setCollegeOrUniversity(val),
      value: collegeOrUniversity,
      label: t(LanguageResource.Jobs + ':claim_education_collegeOrUniversity'),
      required: true,
    },
    {
      name: "schoolLocation",
      type: InputTypes.Text,
      onChange: (val: string) => setSchoolLocation(val),
      value: schoolLocation,
      label: t(LanguageResource.Jobs + ':claim_education_schoolLocation'),
      required: true,
    },
    {
      name: "timePeriod",
      type: InputTypes.Checkbox,
      onChange: (val: boolean) => setTimePeriod(val),
      value: timePeriod,
      label: t(LanguageResource.Jobs + ':claim_create_education_currentlyEnrolled'),
    },
    {
      name: "fromDate",
      type: InputTypes.Date,
      onChange: (val: string) => setFromDate(val),
      value: fromDate,
      label: t(LanguageResource.Common + ':fromDate'),
      condition: !timePeriod,
      required: true,
    },
    {
      name: "toDate",
      type: InputTypes.Date,
      onChange: (val: string) => setToDate(val),
      value: toDate,
      label: t(LanguageResource.Common + ':toDate'),
      condition: !timePeriod,
      required: true,
    },
  ];
}

export const formatEducationData = (
  education: EducationInterface | null = null,
  setLevelEducation: (arg0: string) => void,
  setFieldOfStudy: (arg0: string) => void,
  setCollegeOrUniversity: (arg0: string) => void,
  setSchoolLocation: (arg0: string) => void,
  setTimePeriod: (arg0: boolean) => void,
  setFromDate: (arg0: string) => void,
  setToDate: (arg0: string) => void,
) => {
  setLevelEducation(education?.levelEducation || '');
  setFieldOfStudy(education?.fieldOfStudy || '');
  setCollegeOrUniversity(education?.collegeOrUniversity || '');
  setSchoolLocation(education?.schoolLocation || '');
  setTimePeriod(education?.timePeriod || false);
  setFromDate(education?.fromDate ? moment(education?.fromDate).format('YYYY-MM-DD') : DateUtil.formatInputDate(DateUtil.nowYearsBack(3)));
  setToDate(education?.toDate ? moment(education?.toDate).format('YYYY-MM-DD') : DateUtil.formatInputDate(DateUtil.now()));
}