// Interfaces
import { InputInterface } from '../../../../../interfaces/Input';

const CheckboxInput = (props: InputInterface) => {
  return (
    <div className={`radio-button-wrapper ${props.value === props.name ? "radio-button-active-wrapper" : "" }`}>
      <input 
        type="checkbox"
        className="radio-button"
        name={props.name}
        onChange={() => props.onChange(!props.value)}
        value={props.name}
        checked={props.value} 
      />
      <p className="radio-button-text">
        {props.label + ' '}
        {
          props.onLabelClick
          ?
          <a href={props.onLabelClick.url} target="_blank">
            {props.onLabelClick.label}
          </a>
          : null
        }
      </p>
    </div>
  );
};

export default CheckboxInput;
