// Context
import { useTranslationStore } from '../../../../../context/Translation';

// Enums
import { LanguageResource } from '../../../../../enums/Languages';

interface PropsInterface {
  setStep: (arg0: number) => void;
}

const Step2 = (props: PropsInterface) => {
  const [t] = useTranslationStore();

  const uploadResume = async () => {
    props.setStep(2.1);
  };

  const createResume = () => {
    props.setStep(2.2);
  };

  return (
    <div className="claim-create-form-wrapper">
      <div className="row-spread-wrapper resume-button-wrapper">
        <div className="secondary-button-wrapper column-wrapper" onClick={() => uploadResume()}>
          <p className="secondary-button-text">{t(LanguageResource.Jobs + ':claim_create_upload_resume')}</p>
          <p className="text-color-gray text-align-center">{t(LanguageResource.Jobs + ':claim_create_upload_resume_info')}</p>
        </div>
        <p className="text-size-large text-weight-bold">{t(LanguageResource.Common + ':or')}</p>
        <div className="secondary-button-wrapper column-wrapper" onClick={() => createResume()}>
          <p className="secondary-button-text">{t(LanguageResource.Jobs + ':claim_create_create_resume')}</p>
          <p className="text-color-gray text-align-center">{t(LanguageResource.Jobs + ':claim_create_create_info')}</p>
        </div>
      </div>
    </div>
  );
};

export default Step2;