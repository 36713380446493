import { NavigateFunction } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

// Components
import EventInfoSave from '../Info/Save';
import EventInfoCity from '../../Common/Info/City';

// Config
import ENV from '../../../config/env.json';

// Context
import { useTranslationStore } from '../../../context/Translation';

// Enums
import { LanguageResource } from '../../../enums/Languages';
import { OriginTracking, RoutesLinks } from '../../../enums/Routes';

// Interfaces
import { EventInterface } from '../../../interfaces/Event';

// Styles
import './Preview.scss';

// Utils
import DateUtil from '../../../utils/DateUtil';
import JSUtil from '../../../utils/JSUtil';

interface PropsInterface {
  event: EventInterface,
  navigate: NavigateFunction,
}

const EventsPreview = (props: PropsInterface) => {
  const currentPath = useLocation().pathname,
    [t] = useTranslationStore();
  return (
    <div 
      className='event-preview-wrapper column-wrapper' 
      onClick={() => props.navigate(
        [RoutesLinks.ProfileEvents.Link].includes(currentPath) 
        ? RoutesLinks.ProfileEventsDetail.Link.replace(':' + RoutesLinks.EventsDetail.Params.EventKey, props.event.key)
        : RoutesLinks.EventsDetail.Link.replace(':' + RoutesLinks.EventsDetail.Params.EventKey, props.event.key),
        {
          state: {
            origin: OriginTracking.List
          }
        }
      )}>
      {
        !JSUtil.isEmpty(props.event.image)
        ? <img 
          src={props.event.image?.includes('http') ? props.event.image : ENV.BASE_IMAGE_URL + props.event.image}
          alt='header event'
        />
        : <div className='empty-image' />
      }
     
      <div className="column-wrapper event-preview-info-wrapper">
        <div className='row-spread-wrapper'>
          <div className='column-wrapper'>
            <p className='text-weight-bold text-size-large'>{props.event.title as string}</p>          
            <p className='text-color-gray-dark'>{
              DateUtil.isTodayAfterDate(props.event.startDate) && !DateUtil.isTodayAfterDate(props.event.endDate)
              ? t(LanguageResource.Events + ':in_progress') 
              : DateUtil.displayShortDate(props.event.startDate)}
            </p>
          </div>
          <div className='column-wrapper'>
            <EventInfoCity post={props.event} showFullAddress={false}/>
          </div>
        </div>
        <EventInfoSave event={props.event} navigate={props.navigate}/>
      </div>
    </div>
  );
}

export default EventsPreview;