// Interfaces
import { InputInterface } from '../../../../../interfaces/Input';

const TextInput = (props: InputInterface) => {
  return <input 
    name={props.name}
    type={props.type}
    placeholder={props.placeholder as string}
    className='form-input'
    onChange={input => props.onChange(input.target.value)}
    value={props.value}
  />;
}

export default TextInput