// Components
import SearchFilterOptionButton from './Options/Button';
import SearchFilterOptionWrapper from './Options/Wrapper';
import Input from '../../Form/Inputs/Input';

// Context
import { useTranslationStore } from '../../../../context/Translation';

// Enums
import { InputTypes } from '../../../../enums/Inputs';
import { LanguageResource } from '../../../../enums/Languages';

// Interfaces
import { LocationInterface } from '../../../../interfaces/Location';

// Styles
import './Location.scss'

interface PropsInterface {
  location: LocationInterface;
  setLocation: (arg0: LocationInterface) => void;
  locations: LocationInterface[];
}

const SearchFilterLocation = (props: PropsInterface) => {
  const [t] = useTranslationStore();

  const onPressLocation = (location: LocationInterface) => {
    if (props.location._id === location._id) {
      props.setLocation({} as LocationInterface);
    } else {
      props.setLocation(location);
    }
  };

  const renderLocationFilters = () => {
    return props.locations.map(location => {
      return (
        <SearchFilterOptionButton
          key={location._id}
          data={location._id}
          value={props.location._id}
          onPressFilter={() => onPressLocation(location)}
          label={location.name as string}
        />
      );
    });
  };
  return (
    <>
      <SearchFilterOptionWrapper>
        {renderLocationFilters()}
      </SearchFilterOptionWrapper>
      <div className='search-location-wrapper'>
        <Input
          input={{
            name: 'location',
            type: InputTypes.Text,
            value: props.location.name || '',
            placeholder: t(LanguageResource.Common + ':location_other') as string,
            onChange: (val: string) => props.setLocation({
              _id: '99999',
              name: val,
            }),
          }}
        />
      </div>
    </>
  );
};

export default SearchFilterLocation;
