import moment from 'moment';
//@ts-ignore
import localizationEn from 'moment/locale/en-gb';
//@ts-ignore
import localizationFr from 'moment/locale/fr';
//@ts-ignore
import localizationNl from 'moment/locale/nl';
import i18n from 'i18next';

// Enums
import { DefaultLanguage, Languages } from '../enums/Languages';
import { StorageParams } from '../enums/Storages';

export default class LanguageUtil {
  static setLanguage(language: string, setLocalStorage = false) {
    LanguageUtil.seti18nLocale(language);

    switch (language) {
      case Languages.EN:
        moment.locale(Languages.EN, localizationEn);
        break;
      case Languages.FR:
        moment.locale(Languages.FR, localizationFr);
        break;
      case Languages.NL:
        moment.locale(Languages.NL, localizationNl);
        break;
    }

    if (setLocalStorage) {
      localStorage.setItem(StorageParams.Language, language);
    }
  }

  static seti18nLocale(language: string) {
    let locale = DefaultLanguage;
    if (language && [Languages.EN, Languages.FR, Languages.NL].includes(language)) {
      locale = language;
    }
    i18n.changeLanguage(locale);
  }

  static getLanguage() {
    const language = localStorage.getItem(StorageParams.Language);
    return language || DefaultLanguage;
  }
}